import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DateInput from 'javascripts/common/components/date-input/date-input'
import IconSvg from 'javascripts/client/components/icon-svg'


class DateRangeInputs extends React.Component {
	constructor(props) {
		super(props)

        this.state = {
            isFromOpened: false,
            isToOpened: false
        }

        this.dateRangeInputsNode = null
        this.arrowNode = null
	}



	handleFromChange = (date) => {
		const { from, to } = this.props.range
		let range = {   from: date,
					    to: moment(date).isAfter(to) ? null : to }

		this.props.onChange(range)

        if(!range.to) {
            this.dateInputTo.setOpen(true, {force: true})
        }
	}

	handleToChange = (date) => {
		this.props.onChange({from: this.props.range.from, to: date})
	}


    onOpen = (input, params) => {
        if(input === "from") {
            this.setState({isFromOpened: true})
        } else if(input === "to") {
            if(!this.props.range.from && !params.force) {
                this.dateInputTo.setOpen(false)
                this.dateInputFrom.setOpen(true)
            } else {
                this.setState({isToOpened: true})
            }
        }
    }

    onClose = (input) => {
        if(input === "from") {
            this.setState({isFromOpened: false})
        } else if(input === "to") {
            this.setState({isToOpened: false})            
        }
    }

	setFocus = () => {
		this.dateInputFrom.setFocus()
	}

    close = () => {
        this.dateInputFrom.setOpen(false)
        this.dateInputTo.setOpen(false)
    }

	disabledDatesForTo = (date) => {
		const { from } = this.props.range
		if(moment(from).isValid()) {
			return moment(date).isBefore(from)
		} else {
			return moment(date).isBefore(moment(), 'd')
		}
	}

	get initialMonthDateForFrom() {
		const { from } = this.props.range
		return moment(from).isValid() ? from : new Date
	}

	get initialMonthDateForTo() {
		const { from, to } = this.props.range
		if(moment(to).isValid()) {
			return to
		} else if(moment(from).isValid()) {
			return from
		} else {
			return new Date
		}
	}



	render() {
        const { isToOpened, isFromOpened } = this.state
        const openedCss = (isToOpened || isFromOpened) ? "date-range-inputs_opened_true" : ""

		return (
			<div className={"date-range-inputs " + openedCss} ref={(el) => this.dateRangeInputsNode = el}>
				<DateInput
					value={this.props.range.from}
					selectedRange={this.props.range}
					disabledDates={(date) => moment(date).isBefore(moment(), 'd')}
					initialMonthDate={this.initialMonthDateForFrom}
					name={this.props.nameFrom}
					placeholder={this.props.placeholderFrom}
					locale={this.props.locale}
					containerClass="date-range-inputs__input-from"
					inputClass={this.props.inputClassFrom}
					selectionMode="range"
					rangeRole="from"
					viewDateFormat={this.props.viewDateFormat}
                    maxCharsShortMonth={this.props.maxCharsShortMonth}
					valueDateFormat={this.props.valueDateFormat}
					onChange={this.handleFromChange}
                    onOpen={(params) => this.onOpen("from", params)}
                    onClose={() => this.onClose("from")}
                    beforeComponent={this.props.fromBeforeComponent}
					ref={(input) => { this.dateInputFrom = input }} />

					<div className="date-range-inputs__arrow" ref={(el) => this.arrowNode = el}>
						<IconSvg icon='arrow-right' className="icon-svg_1-5x" />
					</div>

				<DateInput
					value={this.props.range.to}
					selectedRange={this.props.range}
					disabledDates={this.disabledDatesForTo}
					initialMonthDate={this.initialMonthDateForTo}
					name={this.props.nameTo}
					placeholder={this.props.placeholderTo}
					locale={this.props.locale}
					containerClass="date-range-inputs__input-to"
					inputClass={this.props.inputClassTo}
					selectionMode="range"
					rangeRole="to"
					viewDateFormat={this.props.viewDateFormat}
                    maxCharsShortMonth={this.props.maxCharsShortMonth}
					valueDateFormat={this.props.valueDateFormat}
					onChange={this.handleToChange} 
                    onOpen={(params) => this.onOpen("to", params)}
                    onClose={() => this.onClose("to")}
                    openLeft={true}
                    beforeComponent={this.props.beforeComponentForTo}
					ref={(input) => { this.dateInputTo = input }} />
			</div>
		)
	}

}

// TODO
DateRangeInputs.propTypes = {
	nameFrom: PropTypes.string.isRequired,
	nameTo: PropTypes.string.isRequired
}
// TODO
DateRangeInputs.defaultProps = {
	locale: "en",
	valueDateFormat: 'YYYY-MM-DD',
	viewDateFormat: 'DD.MM.YYYY'
}


export default DateRangeInputs
