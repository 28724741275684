import React from 'react';
import PropTypes from 'prop-types'
import Swiper from 'swiper/dist/js/swiper.js';
import classNames from 'classnames';
import { breakpointsForSwiper } from '../media-queries';
import IconSvg from "./icon-svg";

export default class ImagesGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: props.initialSlide || 0,
    };
    this.swiper = null;
    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  componentDidMount() {
    this.swiper = new Swiper('.images-gallery__swiper-container', Object.assign({
      keyboard: true,
      initialSlide: this.props.initialSlide,
      navigation: {
        prevEl: '.images-gallery__arrow-button_prev',
        nextEl: '.images-gallery__arrow-button_next',
      },
      on: {
        slideChange: this.handleSlideChange,
      },
    }));
    const thumbsSwiper = new Swiper('.images-gallery-thumbs__swiper-container', Object.assign({
      centeredSlides: true,
      freeMode: true,
      freeModeMomentumRatio: 0.7,
      freeModeMomentumVelocityRatio: 0.7,
      freeModeSticky: true,
      initialSlide: this.props.initialSlide,
      slideToClickedSlide: true,
      controller: {
        control: this.swiper,
      },
    }, breakpointsForSwiper(this.props.slidesPerViewByBreakpoint, true)));
    this.swiper.controller.control = thumbsSwiper;
  }

  handleSlideChange() {
    if (this.swiper) this.setState({ currentSlide: this.swiper.realIndex });
  }

  render() {
    return (
      <div className={classNames('images-gallery', this.props.className)}>
        <div className="images-gallery__line">
          <div className="ga-container">
            <div className="images-gallery__container">
              <div className="swiper-container images-gallery__swiper-container">
                <div className="swiper-wrapper">
                  {this.props.images.map(image => {
                    const largeUrl = image.large_url || image.thumb_url;
                    return (
                      <div
                        key={largeUrl}
                        className="swiper-slide"
                      >
                        <div
                          className="images-gallery__pane"
                          style={{ backgroundImage: `url("${largeUrl}")` }}
                        />
                        {image.caption &&
                        <div className="images-gallery__image-caption">{image.caption}</div>
                        }
                      </div>
                    );
                  })}
                </div>
                <div className="images-gallery__arrow-button images-gallery__arrow-button_prev">
                  <IconSvg icon="chevron-right" absolute size={4} fill="white" />
                </div>
                <div className="images-gallery__arrow-button images-gallery__arrow-button_next">
                  <IconSvg icon="chevron-right" absolute size={4} fill="white" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ga-container">
          <div className="images-gallery__container">
            {this.renderThumbs()}
          </div>
        </div>
      </div>
    );
  }

  renderThumbs() {
    return (
      <div className="images-gallery-thumbs">
        <div className="images-gallery-thumbs__row">
          <div className="images-gallery-thumbs__cell">
            <div className="swiper-container images-gallery-thumbs__swiper-container">
              <div className="swiper-wrapper">
                {this.props.images.map((image, index) =>
                  <div
                    key={image.thumb_url}
                    className={classNames('swiper-slide', { selected: index === this.state.currentSlide })}
                  >
                    <div
                      className="images-gallery-thumbs__pane"
                      style={{ backgroundImage: `url("${image.thumb_url}")` }}
                      title={image.caption}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ImagesGallery.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      thumb_url: PropTypes.string.isRequired,
      large_url: PropTypes.string,
      caption: PropTypes.string,
    }),
  ).isRequired,
  initialSlide: PropTypes.number,
  slidesPerViewByBreakpoint: PropTypes.shape({}),
};

ImagesGallery.defaultProps = {
  slidesPerViewByBreakpoint: {
    small: 4,
    medium: 5,
    //xmedium: 5,
    large: 10,
    xlarge: 8,
    xxlarge: 8,
  },
};
