import React, { Component } from 'react';
import I18n from 'v2/client/javascripts/i18n'
import IconSvg from './icon-svg';
import ModalLayout from './modal-layout';


class BookingCreatedModal extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.modalLayout.openModal(); // FIXME: wrong component (ModalLayout) api. React component should use props instead methods.
  }

  handleClick() {
    this.modalLayout.closeModal();
  }

    render() {
        return (
            <ModalLayout ref={(m) => this.modalLayout = m} hideHeader={true}>
                <div className="text-center">
                    <div className="modal-layout__header-icon modal-layout__header_success"><IconSvg icon='check-circle' /></div>
                    <h1 className='modal-layout__header modal-layout__header_success'>{I18n.t('fe.client.booking_created_modal.title')}</h1>
                    <p className="modal-layout__text-block">                    
                        <strong>{I18n.t('fe.client.booking_created_modal.thanks_text', { name: this.props.name })}</strong>
                        <br />
                        {I18n.t('fe.client.booking_created_modal.booking_info_send_text', { email: this.props.email })}
                    </p>
                    {!this.props.confirmed && (
                        <p className="modal-layout__text-block">                    
                            {I18n.t('fe.client.booking_created_modal.have_to_confirm_text')}
                        </p>
                    )}
                    <div className='modal-layout__actions'>
                        <button className='button' onClick={this.handleClick}>
                            {I18n.t('fe.client.booking_created_modal.ok')}
                        </button>
                    </div>
                </div>
            </ModalLayout>
        )
    }
}

export default BookingCreatedModal