import React from "react"
import I18n from 'v2/client/javascripts/i18n'
import { connect } from 'react-redux';
import queryString from 'query-string'
import IconSvg from 'javascripts/client/components/icon-svg'
import classNames from 'classnames'
import initJivoSite from 'javascripts/client/jivoSite';

// important to import from dist, cause other import breaks in safari.
import Swiper from 'swiper/dist/js/swiper.js';
import tippy from 'tippy.js'




class PropertiesLoader extends React.Component {

    state = {
        searchData: null,
        nextPageData: null,
        pagesData: [],
        isLoading: false,
        page: this.props.page
    }  

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.loadPropertiesFromServer()
    }

    nextPage = () => {
        const {searchData, page} = this.state
        // here handle url and push url with new page, 
        this.setState({searchData: nextPageData, nextPageData: null, page: page+1}, () => {
            //window.scrollTo(0,0)
            this.initPlugins()
        })
    }

    loadPropertiesFromServer = () => {
        this.setState({isLoading: true})

        fetch(this.props.url, {
                credentials: 'same-origin'
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw new Error()
            })
            .then(data => {
                this.setState({isLoading: false, searchData: data['property_list']}, () => {
                    this.initPlugins()
                })
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.log(err)
            })
    }

    componentDidUpdate = () => {
    }

    initPlugins = () => {
        $(window).trigger('load.zf.sticky');
        initJivoSite(I18n.locale);
    }

    render() {
        const { searchData, page } = this.state
        const { propertiesCount, propertiesOnPage } = this.props

        if (this.state.isLoading) {
            return (
                <LoadingContent />
            )
        } else {
            return (
                <div className="proplist js-proplist" ref={(el) => this.proplist = el}>
                    <div className="proplist__search-results">
                        <PageResultData data={searchData} />
                    </div>
                    <Pagination currentPage={page} propertiesCount={propertiesCount} propertiesOnPage={propertiesOnPage} />
                </div>
            );
        }
    }
}


class Pagination extends React.Component {


    urlForPage = (page) => {
        let parsed = queryString.parse(window.location.search);
        let anchor = queryString.parse(window.location.hash);
        parsed.page = page;
        return `${window.location.pathname}?${queryString.stringify(parsed)}#${queryString.stringify(anchor)}`
    }

    renderPages = (pages, currentPage) => {
        let pagesToRender = []

        for (let i = 1; i <= pages.length; i++) {
            if( (i == 1) || (i == pages.length) || 
                (i == currentPage) || (i == currentPage - 1) || (i == currentPage + 1) || 
                (i <= 3 && currentPage <=3) || 
                (i >= pages.length - 2 && currentPage >= pages.length - 2) )
            {
                pagesToRender.push(
                    <a 
                        className={classNames("proplist__page-link", { "proplist__page-link_active": i == currentPage })} 
                        key={i}
                        href={this.urlForPage(i)}
                    >
                        {i}
                    </a>
                )
            }
            if( (i == 2 && currentPage - 2 >= 2) || (i == pages.length - 1 && currentPage + 2 <= pages.length - 1) ) {
                pagesToRender.push(<span className="proplist__page-dots" key="gap">…</span>)
            }
        }
        return pagesToRender
    }

    propertiesRange = () => {
        const { currentPage, propertiesCount, propertiesOnPage } = this.props        
        const start = (currentPage - 1) * propertiesOnPage + 1;
        let end = start + propertiesOnPage - 1
        if(end > propertiesCount) {
            end = propertiesCount
        }
        return `${start} – ${end}`
    }

    render() {
        const { currentPage, propertiesCount, propertiesOnPage } = this.props
        const pages = [...Array(Math.ceil(propertiesCount / propertiesOnPage))]
        
        if(pages.length > 1) {
            return (
                <React.Fragment>
                    <div className="proplist__pagination">
                        {currentPage > 1 &&
                            <a href={this.urlForPage(currentPage - 1)}
                               key="prev"
                               className="proplist__page-prev button button_hollow">
                              <IconSvg icon="arrow-left" />
                            </a>
                        }

                        {this.renderPages(pages, currentPage)}

                        {currentPage < pages.length &&            
                            <a href={this.urlForPage(currentPage + 1)}
                               key="next"
                               className="proplist__page-next button button_hollow">
                              <IconSvg icon="arrow-right" />
                            </a>
                        }
                    </div>
                    <div className="proplist__pagination-info">
                        {I18n.t('fe.client.proplist.properties_range', { range: this.propertiesRange(), total: propertiesCount })}
                    </div>
                </React.Fragment>
            )
        } else {
            return null
        }
    }

}


class PageResultData extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.initPlugins()
    }

    initPlugins = () => {
        this.page.querySelectorAll(".js-has-tip").forEach(el => {
            tippy(el)
        })
        this.page.querySelectorAll(".js-swiper-container").forEach(el => {
            this.initSwiperGallery(el)
        })        
        ReactRailsUJS.mountComponents(this.page)
    }

    initSwiperGallery = (el) => {
        const swiper = new Swiper(el, { 
            loop: true,
            speed: 200,
            spaceBetween: 2,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 1,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        })
    }    

    render() {
        return (
            <div ref={(el) => this.page = el} dangerouslySetInnerHTML={ {__html: this.props.data} }></div>
        )
    }

}


class LoadingContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            secondsElapsed: 0
        }
        this.tick = this.tick.bind(this)
    }

    tick() {
        this.setState({secondsElapsed: this.state.secondsElapsed + 1})
    }

    componentDidMount() {
        this.interval = setInterval(this.tick, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {
        return (
            <div className="text-center">
                {this.state.secondsElapsed}
            </div>
        )
    }
}

function mapStateToProps({ properties: { count } }) {
  return { propertiesCount: count }
}

export default connect(mapStateToProps)(PropertiesLoader)
