import {
  ACTIVATE_OVERVIEW_MAP_MARKER,
  DEACTIVATE_OVERVIEW_MAP_MARKER
} from "../../constants/overviewMapConstants";

export default function(state = null, { type, payload }) {
  switch (type) {
    case ACTIVATE_OVERVIEW_MAP_MARKER:
      return Object.assign({}, payload);
    case DEACTIVATE_OVERVIEW_MAP_MARKER:
      return null;
    default:
      return state;
  }
}