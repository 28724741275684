import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';
import mapValues from 'lodash/mapValues';
import mergeWith from 'lodash/mergeWith';
import { getInitialParamsForAccommodation, getInitialParamsForTransfer } from 'javascripts/client/components/searchbox';

// Reducers DSL-like notation `{ reducerName: initialState | { initialState, defaultReducer } }`,
// used for generation exported reducers below
const reducers = {
  rehydratedStore: false,
  svgsprite: '',
  user_currency: '',
  // current_user: {},
  //searchboxParams: {
  //  accommodation: getInitialParamsForAccommodation(),
  //  transfer: getInitialParamsForTransfer(),
  //},
  room_variants: [],
  room_cart: [],
};

export default mapValues(reducers, (initialState, reducerName) => {
  let defaultReducer = null;
  if (isPlainObject(initialState)) {
    const keys = Object.keys(initialState);
    if (keys.length > 0 && difference(keys, ['initialState', 'defaultReducer']).length === 0) {
      defaultReducer = initialState.defaultReducer;
      initialState = initialState.initialState;
      if (isPlainObject(defaultReducer)) {
        defaultReducer = makeDefaultReducer(defaultReducer);
      }
    }
  }
  if (isPlainObject(initialState) || isArray(initialState)) {
    return defaultReducerFactory(reducerName, initialState, defaultReducer);
  }
  return basicReducerFactory(reducerName, initialState, defaultReducer);
});

function makeDefaultReducer(reducerRules) {
  return (state, action, initialState) => {
    if (action.type in reducerRules) {
      return reducerRules[action.type](state, action, initialState);
    }
    return state;
  }
}

function basicReducerFactory(reducerName, initialState, defaultReducer = null) {
  return (state = initialState, action) => {
    switch (action.type) {
      case `SET_${reducerName}`:
        return isPlainObject(initialState) ? Object.assign({}, initialState, action.data) : action.data;
      case `CLEAR_${reducerName}`:
        return initialState;
      default:
        return defaultReducer ? defaultReducer(state, action, initialState) : state;
    }
  };
}

function defaultReducerFactory(reducerName, initialState, defaultReducer = null) {
  return basicReducerFactory(reducerName, initialState, (state = initialState, action) => {
    switch (action.type) {
      case `APPEND_${reducerName}`:
        return state.concat(action.data);
      case `PATCH_${reducerName}`:
        return patchReducer(state, action.patch);
      default:
        return defaultReducer ? defaultReducer(state, action, initialState) : state;
    }
  });
}

export function patchReducer(state, patch) {
  if (isPlainObject(state)) {
    if (patch.id && state.id && patch.id !== state.id) {
      return state;
    }
    return mergeWith({}, state, patch, (stateValue, patchValue) => isArray(patchValue) ? patchValue : undefined);
  }
  const itemIndex = findIndex(state, { id: patch.id });
  const newState = [...state];
  if (itemIndex >= 0) {
    newState[itemIndex] = Object.assign(
      {},
      newState[itemIndex],
      patch,
    );
  } else {
    newState.unshift(patch);
  }
  return newState;
}

// function actionsFactory(reducerName) {
//   return {
//     setStore: data => ({ type: `SET_${reducerName}`, data }),
//     clearStore: () => ({ type: `CLEAR_${reducerName}` }),
//     appendStore: data => ({ type: `APPEND_${reducerName}`, data }),
//     patch: patch => ({ type: `PATCH_${reducerName}`, patch }),
//   }
// }
//
