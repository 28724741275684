import I18n from 'i18n-js'
import plurals from './plurals' 

const globalLocaleNamespace = "__CURRENT_LOCALE__"

I18n.translations = {}
I18n.availableLocales = ["en","ru","pl"]
I18n.defaultLocale = "en"
I18n.fallbacks = true

// we detect current locale server side and pass through global variable
if(typeof window !== undefined) {
  I18n.locale = window[globalLocaleNamespace]
} else {
  I18n.locale = I18n.defaultLocale
}

// Add pluralization rules
Object.keys(plurals).forEach((locale) => {
  I18n.pluralization[locale] = plurals[locale]
})

// function to load all translations of all locales from specified context.
export const getTranslationsFromContext = (req) => {
  let translations = {}
  req.keys().forEach((locale) => {
    translations = Object.assign(translations, req(locale).default)
  });
  return translations;
}

export default I18n;
