/*
 * Searchbox component. Before we used it with tabs and can switch between form,
 * but now we are using this combonent only with 1 visible form.
*/

import moment from 'moment'
import I18n from 'v2/client/javascripts/i18n'
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import SearchboxPropertiesForm from './searchbox-properties-form'
import SearchboxPropertiesFormOnHero from './searchbox-properties-form-on-hero'
import SearchboxTransferForm from './searchbox-transfer-form-on-hero'
import classNames from 'classnames'
import { classWithModifiers } from 'javascripts/client/helpers';
import { updateSearchboxParams } from 'javascripts/client/actions/searchbox';


class Searchbox extends React.Component {

    static defaultProps = {
        activeTab: "accommodation",
        smallExpanded: false
    }


    constructor(props) {
        super(props)
    
        this.state = {
            activeTab: this.props.activeTab,
            smallExpanded: this.props.smallExpanded,
            //shortVersion: this.props.shortVersion
        }
    }


    handleFormParamsChange = (formParams, formName) => {
        // expecting to recieve full form params (not only date or dest)
        this.props.updateSearchboxParams({ [formName]: formParams })
    }

    handleTabClick = (e, tab) => {
        e.preventDefault()
        this.setState({activeTab: tab})
    }

    handleModifySearchClick = (e) => {
        e.preventDefault()        
        this.setState({smallExpanded: true})
    }

    renderActiveTabContent = () => {
        const { activeTab } = this.state
        const { autocompleteUrl, modifiers } = this.props;

        const   searchUrl = this.props.searchUrls[activeTab],
                params =  this.props.params[activeTab]

        switch(activeTab) {
            case "accommodation": {
                if (modifiers.includes("on-hero")) {
                    return (
                      <SearchboxPropertiesFormOnHero
                        searchUrl={searchUrl}
                        params={params}
                        autocompleteUrl={autocompleteUrl}
                        onParamsChange={(params) => this.handleFormParamsChange(params, activeTab)}
                        modifiers={modifiers}
                      />
                    )
                } else {
                    return (
                      <SearchboxPropertiesForm
                        searchUrl={searchUrl}
                        params={params}
                        autocompleteUrl={autocompleteUrl}
                        onParamsChange={(params) => this.handleFormParamsChange(params, activeTab)}
                        modifiers={modifiers}
                      />
                    )
                }
                break
            }
            case "transfer": {
                return (
                    <SearchboxTransferForm 
                        searchUrl={searchUrl}
                        params={params}
                        autocompleteUrl={autocompleteUrl}
                        onParamsChange={(params) => this.handleFormParamsChange(params, activeTab)} 
                        modifiers={modifiers}
                    />
                )
                break
            }
        }       
    }
    
    render() {
        const { modifiers } = this.props

        return (
            <div className={classNames("searchbox", classWithModifiers("searchbox", modifiers))}>
                <div className="searchbox__forms">          
                    <div className="ga-container">
                        <div className="searchbox__tabs-content">
                            <div className={"searchbox__tabs-content_small-expanded_" + this.state.smallExpanded}>
                                {this.renderActiveTabContent()}
                            </div>
                            <div className={"searchbox__modify-search searchbox__modify-search_hidden_" + this.state.smallExpanded} onClick={this.handleModifySearchClick}>
                                <a href="#" onClick={this.handleModifySearchClick} className="searchbox__modify-search-link">
                                    {I18n.t('fe.client.searchbox.modify_search')}
                                </a>
                            </div>                   
                        </div>
                         
                    </div>
                </div>
            </div>  
        )
    }
}


function mapStateToProps({ searchbox }) {
    return {...searchbox}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateSearchboxParams,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbox)