import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {InfoWindow, Marker} from "react-google-maps";

import {markerShape, markerInfoShape} from "../../constants/overviewMapConstants";
import IconSvg from "../icon-svg";


class OverviewMapMarker extends Component {

  handleMouseOver = () => {
    this.props.onActivate(this.props.marker);
  };

  handleCloseInfoWindow = () => {
    this.props.onDeactivate();
  };

  render() {
    const { marker, info, isActive } = this.props;
    const { lat, lng } = marker;

    return (
      <Marker position={{lat, lng}}
              onMouseOver={this.handleMouseOver}
              onClick={this.handleMouseOver}>
        {
          isActive && info &&
          <InfoWindow onCloseClick={this.handleCloseInfoWindow}>
            <div className="overview-map__balloon">
              <div className="overview-map__balloon-image">
                <img src={info.image} alt={info.title} />
              </div>
              <div>
                <div className="overview-map__balloon-title">
                  <a href={info.url} data-turbolinks="false">{info.title}</a>
                </div>
                <div className="overview-map__balloon-description"
                     dangerouslySetInnerHTML={{__html: info.description}} />
              </div>
            </div>
          </InfoWindow>
        }
        { isActive && !info &&
        <InfoWindow onCloseClick={this.handleCloseInfoWindow}>
          <div className="overview-map__balloon-loading">
            <div className="overview-map__balloon-rotating">
              <IconSvg icon="onair" absolute size={4} fill="primary" />
            </div>
          </div>
        </InfoWindow>
      }
      </Marker>
    )
  }

}

OverviewMapMarker.propTypes = {
  marker: PropTypes.shape(markerShape).isRequired,
  info: PropTypes.shape(markerInfoShape),
  isActive: PropTypes.bool,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

export default OverviewMapMarker