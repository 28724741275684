import React from 'react'
import I18n from 'v2/common/javascripts/i18n'
import PropTypes from 'prop-types'
import CountInput from 'javascripts/common/components/count-input/count-input'
import isEqual from 'lodash/isEqual'
import IconSvg from 'javascripts/client/components/icon-svg'

function decodeInitialGuests(initialGuests) {
  let guests = initialGuests || {},
    adults = guests.adults || 0,
    childAges = {},
    ageSelectCount = 0;

  // Clone object so it would not change init props
  guests = Object.assign({}, guests);
  delete (guests)['adults'];
  const childs = Object.values(guests).reduce((a, b) => a + b, 0)

  Object.keys(guests).map((age) =>
    [...Array(guests[age])].map(function(val) {
      ageSelectCount += 1
      childAges['age-select-' + ageSelectCount] = parseInt(age)
    })
  )

  return { adults: adults, childs: childs, childAges: childAges }
}


class GuestsInput extends React.Component {
  constructor(props) {    
    super(props)

    const initialGuests = this.props.initialGuests || GuestsInput.defaultProps.initialGuests
    const { adults, childs, childAges } = decodeInitialGuests(initialGuests)

    this.state = {
      adults: adults,
      childs: childs,
      childAges: childAges,
      guests: initialGuests,
      isOpened: false
    };

    this.isFocused = false
    this.isMouseDown = false
    this.oldState = {}
  }

  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleOutsideEvent)
    document.addEventListener('focusin', this.handleOutsideEvent)
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideEvent)
    document.removeEventListener('focusin', this.handleOutsideEvent)
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  componentWillReceiveProps = (nextProps) => {
  }

  handleFocus = (e) => {
    this.setOpen(!this.state.isOpened)
  }

  // first event with Fastclick on iOs.
  handleClick = (e) => {
    e.preventDefault()
    if(!this.isMouseDown) {
      this.setFocus()
    }
  }

  handleMouseDown = (e) => {
    e.preventDefault()
    this.isMouseDown = true
    this.refs.GuestsInput.blur()
    this.setFocus()
  }


  handleOutsideEvent = (e) => {
    const domNode = ReactDOM.findDOMNode(this);
    if ((!domNode || !domNode.contains(e.target)) && this.state.isOpened) {
      this.setOpen(false)
    }
  }

  handleKeyDown = (e) => {
    if (this.state.isOpened && (e.key === 'Escape' || e.key === 'Tab')) {
      e.target.blur()
      this.setOpen(false)
    }
  }

  handleCancelClick = (e) => {
    e.preventDefault()
    this.setState(this.oldState)
  }

  setOpen = (value) => {
    if(value === true) {
      this.isFocused = true
      this.oldState = Object.assign({}, this.state)
      this.setState({isOpened: true})
      this.props.onOpen()
    } else {
      this.isFocused = false
      this.refs.GuestsInput.blur()
      this.setState({isOpened: false})
      this.props.onClose()
    }
  }

  setFocus = (e) => {
    this.refs.GuestsInput.focus()
  }


  setAdults = (counter) => {
    this.setGuestsSelected({adults: counter})
  }

  setChilds = (counter) => {
    const newChilds = counter
    let newChildAges = Object.assign({}, this.state.childAges)
    if(newChilds >= this.state.childs) {
      newChildAges['age-select-' + newChilds] = this.props.defaultChildAge
    } else {
      const lastKey = Object.keys(newChildAges)[Object.keys(newChildAges).length - 1]
      delete newChildAges[lastKey]
    }

    this.setGuestsSelected({childs: newChilds, childAges: newChildAges})
  }

  childAge = (select_i) => {
    return this.state.childAges['age-select-' + select_i]
  }

  incrementOrOne = (val) => {
    return val ? parseInt(val) + 1 : 1;
  }

  handleChangeChildAge = (e) => {
    let childAges = Object.assign({}, this.state.childAges);
    childAges[e.target.id] = parseInt(e.target.value);
    this.setGuestsSelected({childAges: childAges});
  }

  setGuestsSelected = (params = {}) => {
    const   childAges   = params.hasOwnProperty("childAges") ? params.childAges   : this.state.childAges,
      adults      = params.hasOwnProperty("adults") ? params.adults      : this.state.adults,
      childs      = params.hasOwnProperty("childs") ? params.childs      : this.state.childs

    let guests = {adults: adults};

    Object.values(childAges).map((age) =>
      guests[age] = this.incrementOrOne(guests[age])
    );

    this.setState({guests: guests, adults: adults, childs: childs, childAges: childAges})
    this.props.onGuestsSelected(guests);
  }

  getInputContainerInlineStyle = () => {
    const windowRightInputLeftDist = window.innerWidth - this.refs.viewInput.getBoundingClientRect().left
    //TODO: better count how to show container
    const right = windowRightInputLeftDist < this.props.guestsInputContainerMaxWidth ? 0 : null
    return { right: right }
  }

  get viewGuests() {
    const { adults, childs } = this.state
    if(adults > 0 || childs > 0) {
      let viewGuests = [`${adults} ${I18n.t('fe.common.components.guests_input.adults', {count: adults})}`]
      viewGuests.push(`${childs} ${I18n.t('fe.common.components.guests_input.childs', {count: childs})}`);
      return viewGuests.join(', ')
    } else {
      return ""
    }
  }

  renderChildAges = () => {
    if (this.state.childs > 0) {
      return (
        <div className="guests-input__child-ages-container">
          <div className="guests-input__child-ages-caption">
            {I18n.t('fe.common.components.guests_input.childs_ages')}
          </div>
          {[...Array(this.state.childs)].map((s_val, s_i) =>
            <div key={'childRow' + s_i} className="guests-input__content-row">
              <div className="guests-input__label-wrapper">
                                <span className="guests-input__child-label">
                                    {I18n.t('fe.common.components.guests_input.childs.one')} {s_i + 1}
                                </span>
              </div>
              <div className="guests-input__controls-wrapper">
                <select id={'age-select-' + (s_i + 1)}
                        key={'select' + s_i}
                        onChange={this.handleChangeChildAge}
                        className="guests-input__child-age-select"
                        defaultValue={this.childAge(s_i + 1)}>

                  {[...Array(this.props.maxChildAge + 1)].map((_, age) =>
                    <option value={age} key={age}>{age} {I18n.t('fe.common.components.guests_input.years', {count: age})}</option>
                  )}
                </select>
              </div>
            </div>
          )}
        </div>
      )
    }
  }


  render() {
    const guestsInputContainerInlineStyle = this.state.isOpened ? this.getInputContainerInlineStyle() : {}

    return (
      <div className={this.props.containerClass} style={ {position: 'relative'} }>
        <div className={"guests-input guests-input_opened_"+this.state.isOpened} ref="GuestsInput" tabIndex="0" onFocus={this.handleFocus} onMouseDown={this.handleMouseDown} onClick={this.handleClick}>
          <div className="input__container">
            <div className="input__icon-prefix input__icon-prefix_no-pointer-events">
              <IconSvg icon='users' className='icon-svg_1-5x' />
            </div>
            <input  value={this.viewGuests}
                    placeholder={this.props.placeholder}
                    className="input_guests input_with-icon-prefix input_with-icon-postfix"
                    autoComplete="off"
                    ref="viewInput"
                    type="text"
                    readOnly
                    tabIndex="-1"
            />
            <div className="guests-input__icon-down input__icon-postfix input__icon-postfix_no-pointer-events">
              <IconSvg icon='chevron-down' size={1} />
            </div>
            <input type="hidden" name={this.props.name} value={JSON.stringify(this.state.guests)} />
          </div>
        </div>


        {
          this.state.isOpened &&
          <div className="guests-input__content-container" style={guestsInputContainerInlineStyle} ref="GuestsInputContentContainer">
            <div className="guests-input__content-row">
              <div className="guests-input__label-wrapper">
                <span className="guests-input__label">{I18n.t('fe.common.components.guests_input.adults_label')}</span>
              </div>
              <div className="guests-input__controls-wrapper">
                <CountInput initialCounterValue={this.state.adults}
                            minCounterValue={this.props.minAdults}
                            maxCounterValue={this.props.maxAdults}
                            onAdd={this.setAdults}
                            onMinus={this.setAdults}
                            cssClass="count-input_tiny"
                />
              </div>
            </div>

            <div className="guests-input__content-row">
              <div className="guests-input__label-wrapper">
                <span className="guests-input__label">{I18n.t('fe.common.components.guests_input.childs_label')}</span>
              </div>
              <div className="guests-input__controls-wrapper">
                <CountInput initialCounterValue={this.state.childs}
                            minCounterValue={this.props.minChilds}
                            maxCounterValue={this.props.maxChilds}
                            onAdd={this.setChilds}
                            onMinus={this.setChilds}
                            cssClass="count-input_tiny"
                />
              </div>
            </div>
            {this.renderChildAges()}


            <div className="guests-input__content-row guests-input__actions">
              <a href="#" onClick={this.handleCancelClick} className="guests-input__cancel-link">
                {I18n.t('fe.common.components.guests_input.cancel_link')}
              </a>
              <a href="javascript:void(0)" onClick={()=>this.setOpen(false)} className="guests-input__apply-link">
                {I18n.t('fe.common.components.guests_input.apply_link')}
              </a>
            </div>


          </div>
        }
      </div>
    )
  }
}

GuestsInput.propTypes = {
  onGuestsSelected: PropTypes.func.isRequired
}

GuestsInput.defaultProps = {
  locale: 'en',
  onBlur: new Function,
  onOpen: new Function,
  onClose: new Function,
  guestsInputContainerMaxWidth: 300,
  minAdults: 1,
  maxAdults: 30,
  maxChilds: 5,
  minChilds: 0,
  maxChildAge: 17,
  defaultChildAge: 8,
  initialGuests: {}
}


export default GuestsInput
