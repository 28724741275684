class BookingTrfForm {

    init() {
        const $address = $("[name*='address']")
        $address.keyup((e) => {
            const $target = $(".js-trf-summary__" + e.target.id)
            const value = e.target.value
            if(value) {
                $target.html(", " + e.target.value)
            } else {
                $target.html("")
            }
        })

        const $flight = $("[name*='flight']")
        $flight.keyup((e) => {
            const $target = $(".js-trf-summary__" + e.target.id)
            const value = e.target.value
            if(value) {
                $target.parent().css('display', 'block')
                $target.html(e.target.value)
            } else {
                $target.parent().css('display', 'none')
            }
        })

        $("[name*='time']").change((e) => {
            $(".js-trf-summary__" + e.target.id).html("" + e.target.value + "")
        })
        $("[name*='boosters']").on("change", (e) => {
            $(".js-trf-summary__boosters").html(e.target.value)
        })
        $("[name*='child_seats']").on("change", (e) => {
            $(".js-trf-summary__child-seats").html(e.target.value)
        })
        $("[name*='additional_info']").keyup((e) => {
            $(".js-trf-summary__additional-info").html(e.target.value)
        })
    }
}

export default BookingTrfForm
