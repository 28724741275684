import React from 'react'
import I18n from 'v2/common/javascripts/i18n'
import PropTypes from 'prop-types'
import moment from 'moment'
import { shortMonthThreeChars } from 'javascripts/common/helpers'

import DatesPicker from 'javascripts/common/components/dates-picker/dates-picker'

class DateInput extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpened: false
		}

    this.isMouseDown = false
    this.ignoreOutsideClick = false
		this.setOpen = this.setOpen.bind(this)
		this.handleFocus = this.handleFocus.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleMouseDown = this.handleMouseDown.bind(this)
		this.handleKeyDown = this.handleKeyDown.bind(this)
		this.handleOutsideEvent = this.handleOutsideEvent.bind(this)
		this.handleDayClick = this.handleDayClick.bind(this)
    this.getInputContainerInlineStyle = this.getInputContainerInlineStyle.bind(this)
        
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleOutsideEvent)	
		document.addEventListener('focusin', this.handleOutsideEvent)	
		document.addEventListener('keydown', this.handleKeyDown)
    }
	
  componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideEvent)	
		document.removeEventListener('focusin', this.handleOutsideEvent)	
    document.removeEventListener('keydown', this.handleKeyDown)
  }

	handleFocus(e) {
        if(moment(this.props.value).isValid()) {
            this.setOpen(!this.state.isOpened)
        } else {
            this.setOpen(true)
        }
	}
    
  // first event with Fastclick on iOs.
	handleClick(e) {
		e.preventDefault()            
        if(!this.isMouseDown) {
            this.setFocus()
        }
        this.isMouseDown = false
	}

    handleMouseDown(e) {
        e.preventDefault()
        this.isMouseDown = true
        this.refs.dateInput.blur()        
        this.setFocus() 
    }

	
	handleOutsideEvent(e) {
        const { rangeRole, selectedRange } = this.props    
	    let domNode = ReactDOM.findDOMNode(this)

        // don't close from (opened from parent) if this is part of rangeInputs and from is empty.
        if(rangeRole == "from" && !selectedRange.from) {
            domNode = domNode.parentNode
        }
		if ((!domNode || !domNode.contains(e.target)) && this.state.isOpened) {
            this.setOpen(false)
		} 
	}

	handleKeyDown(e) {
    if (this.state.isOpened && (e.key === 'Escape' || e.key === 'Tab')) {
			e.target.blur()
			this.setOpen(false)
		}
	}	

	handleDayClick(e, date) {
		this.setOpen(false)
		this.props.onChange(date)
	}



	setOpen(value, params = {}) {
        const { rangeRole, selectedRange } = this.props
        if(value === true && this.state.isOpened != true) {
		    this.setState({isOpened: true})
            //this.props.onOpen(params: params) - new webpacker handle error on this
            this.props.onOpen(params)
        } else if(value === false){
            this.refs.dateInput.blur()
		    this.setState({isOpened: false})
            this.props.onClose()                
        }
	}

	setFocus() {
        this.refs.dateInput.focus()
	}

    getInputContainerInlineStyle() {
			    // change position if there is not enought space on right                
        const   windowRightInputLeftDist = window.innerWidth - this.refs.viewInput.getBoundingClientRect().left,
                { openLeft } = this.props,
                //TODO: better count how to show container
                right = (windowRightInputLeftDist < this.props.guestsInputContainerMaxWidth || openLeft) ? 0 : null
        return { right: right } 
    }    


	get viewDate() {
		const { value, viewDateFormat, locale, maxCharsShortMonth } = this.props
		moment.locale(locale)
        const mDate = moment(value)
        let date = mDate.isValid() ? mDate.format(viewDateFormat) : ''

        if(date && maxCharsShortMonth && /MMM/.test(viewDateFormat) && !/MMMM/.test(viewDateFormat)) {
            date = shortMonthThreeChars(date)
        }
		return date 
	}

	get valueDate() {
		const { value, valueDateFormat } = this.props
		return moment(value).isValid() ? moment(value).format(valueDateFormat) : '' 
	}


	render() {
        const datePickerContainerInlineStyle = this.state.isOpened ? this.getInputContainerInlineStyle() : {}
        
		return (
			<div className={this.props.containerClass}>

				<div className={"date-input date-input_opened_"+this.state.isOpened} ref="dateInput" tabIndex="0" onFocus={this.handleFocus} onMouseDown={this.handleMouseDown} onClick={this.handleClick}>
					<input value={this.viewDate} placeholder={this.props.placeholder} className={"input_date " + this.props.inputClass} autoComplete="off" ref="viewInput" type="text" readOnly tabIndex="-1"  />
					<input type="hidden" name={this.props.name} value={this.valueDate} />
				</div>

                {this.state.isOpened && 
                    <div className="date-input__dates-picker-container" style={datePickerContainerInlineStyle}>
                        {this.props.beforeComponent && this.props.beforeComponent()}
                        <DatesPicker 
                            onDayClick={this.handleDayClick}
                            canDeselectDate={false}
                            locale={this.props.locale}
                            selectedDates={this.props.selectedDates}
                            selectedRange={this.props.selectedRange}
                            disabledDates={this.props.disabledDates}
                            initialMonthDate={this.props.initialMonthDate}
                            initialSelectionMode={this.props.selectionMode}
                            rangeRole={this.props.rangeRole}
                            tabIndex={-1}	/>

                        <div className="date-input__actions">
                            <a href="javascript:void(0);" className="date-input__close-link" onClick={()=>this.setOpen(false)}>{I18n.t('fe.common.components.date_input.close_link')}</a>
                            <a href="javascript:void(0);" className="date-input__apply-link" onClick={()=>this.setOpen(false)}>{I18n.t('fe.common.components.date_input.apply_link')}</a>
                        </div>
                    </div>
                }                
                
			</div>
		)
	}

}




// TODO
DateInput.propTypes = {
}

// TODO
DateInput.defaultProps = {	
	valueDateFormat: 'YYYY-MM-DD',
	viewDateFormat: 'DD.MM.YYYY',
	locale: 'en',
	onOpen: new Function,
  onClose: new Function, 
	datePickerContainerMaxWidth: 300
}


export default DateInput
