import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import IconSvg from "../icon-svg";
import { numberToCurrency } from "../../helpers";

const inputValueToNumber = (value) => {
    return parseInt(value.replace(/\D+/g, ''))
}

class PriceFilter extends React.Component {

    static propTypes = {
        minPrice: PropTypes.number,
        maxPrice: PropTypes.number,
        startPrice: PropTypes.number,
        endPrice: PropTypes.number,
        onChangeFilter: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props)
    }

    handleChangePriceStart = ({ target: { value }}) => {
        this.props.onChangeFilter({ startPrice: inputValueToNumber(value) });
    }

    handleChangePriceEnd = ({ target: { value }}) => {
        this.props.onChangeFilter({ endPrice: inputValueToNumber(value) });
    }

    handleChangePriceRange = ([startPrice, endPrice]) => {
        this.props.onChangeFilter({ startPrice, endPrice });
    }

    renderRangeInputs = () => {
        const { minPrice, maxPrice, startPrice, endPrice } = this.props;        

        if(!minPrice && !maxPrice) { return; }

        return (
            <div className="range-inputs">
                <div className="range-inputs__side range-inputs__side_from">
                    <div className="range-inputs__hint">
                        {/*I18n.t('fe.client.properties_filters.price.from')*/}
                    </div>
                    <div className="range-inputs__input-wrapper">
                        <input
                            type="text"
                            value={numberToCurrency(startPrice) || ''}
                            onChange={this.handleChangePriceStart}
                            ref={c => this.inputStart = c}
                        />
                    </div>
                </div>
                <IconSvg icon="arrow-right" fill="primary" size={1.5} />
                <div className="range-inputs__side range-inputs__side_to">
                    <div className="range-inputs__hint">
                        {/*I18n.t('fe.client.properties_filters.price.to')*/}
                    </div>
                    <div className="range-inputs__input-wrapper">
                        <input
                            type="text"
                            value={numberToCurrency(endPrice) || ''}
                            onChange={this.handleChangePriceEnd}
                            ref={c => this.inputEnd = c}
                        />
                    </div>
                </div>
            </div>            
        )
    }  

    render() {
        const { minPrice, maxPrice, startPrice, endPrice } = this.props;
        return (
            <div className="proplist-controls__price-filter">
                {this.renderRangeInputs()}
                <div className="ga-slider-wrapper">
                    <Range
                        min={minPrice || 0}
                        max={maxPrice || 1}
                        value={[startPrice || 0, endPrice || 1]}
                        onChange={this.handleChangePriceRange}
                        disabled={!minPrice && !maxPrice}
                    />
                </div>
            </div>
        )
    }
}



export default PriceFilter
