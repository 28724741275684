import enquire from 'enquire.js';
import capitalize from 'lodash/capitalize';

// Sizes for calc media-points synced with:
// app/assets/stylesheets/client/base/_foundation-settings.scss

const globalFontSize = 16;

export const breakpoints = {
  small: 0,
  medium: 690,
  //xmedium: 850,  
  large: 1024,
  xlarge: 1260,
  xxlarge: 1740,
  xxxlarge: 1000000,
};

export const gridColumnGutters = {
  small: 0, // 20,
  midmedium: 20, // 20,
  medium: 20, // 20,
  // xmedium: 20,
  large: 20, // 20,
  xlarge: 20,
  xxlarge: 20,
};

// Initialization and broadcast event `changeBreakpoint`
function initializeEnquiereMatching() {
  const bpKeys = Object.keys(breakpoints);
  bpKeys.forEach((bp, index) => {
    if (0 < index) {
      enquire.register(breakpoint(bp), {
        unmatch: changeBreakpoint.bind(null, bpKeys[index - 1]),
        match: changeBreakpoint.bind(null, bp),
      });
    }
  });
}
initializeEnquiereMatching();

global.currentBreakpoint = getCurrentBreakpoint();

function changeBreakpoint(bp) {
  const prevBreakpoint = global.currentBreakpoint;
  // fix async breakpoints matching
  if (getCurrentBreakpoint() !== bp) {
    return;
  }
  global.currentBreakpoint = bp;
  $(document).trigger('changeBreakpoint', { breakpoint: bp, prevBreakpoint });
}

export function matchBreakpoint(bp, width = getCurrentWindowSize('width')) {
  return breakpoints[bp] <= width;
}

function getCurrentBreakpoint() {
  const width = getCurrentWindowSize('width');
  return Object.keys(breakpoints).reverse().find(bp => matchBreakpoint(bp, width));
}

export function getCurrentOrientation() {
  return getCurrentWindowSize('width') < getCurrentWindowSize('height') ? 'portrait' : 'landscape';
}

export function getCurrentWindowSize(size) {
  size = capitalize(size);
  return size === 'Width' ? (
    document.documentElement[`client${size}`] ||
    window[`inner${size}`] ||
    document.getElementsByTagName('body')[0][`client${size}`]
  ) : (
    window[`inner${size}`] ||
    document.documentElement[`client${size}`] ||
    document.getElementsByTagName('body')[0][`client${size}`]
  );
}

export function breakpointsForSwiper(slidesPerViewByBreakpoint, omitPerGroup = false) {
  const spaceBetweenByBreakpoint = Object.assign({}, gridColumnGutters, { small: 10 });
  const bpKeys = Object.keys(breakpoints);
  const bps = {};
  function makeBreakpoint(bpKey) {
    const slidesPerView = slidesPerViewByBreakpoint[bpKey];
    const bp = {
      slidesPerView,
      spaceBetween: spaceBetweenByBreakpoint[bpKey],
    };
    if (!omitPerGroup) bp.slidesPerGroup = Math.round(slidesPerView / 3);
    return bp;
  }
  bpKeys.slice(1, -1).forEach((bp, index) => {
    bps[breakpoints[bp] - 1] = makeBreakpoint(bpKeys[index]);
  });
  return Object.assign({
    breakpoints: bps,
  }, makeBreakpoint('xxlarge'));
}

// Some ported functions from Foundation
/// Generates a media query string matching the input value. Refer to the documentation for the `breakpoint()` mixin to see what the possible inputs are.
///
/// @param {Keyword|Number} bp ['small'] - Breakpoint name, or px, rem, or em value to process.
/// @param {Keyword} dir ['up'] Direction of media query (up, down, or only)
function breakpoint(bp = 'small', dir = 'up') {
  // Value for max-width media queries
  let bpMax = 0;
  // Eventual output
  let str = '';
  // Is it a named media query?
  let named = false;

  // Orientation media queries have a unique syntax
  if (bp === 'landscape' || bp === 'portrait') {
    return '(orientation: #{$bp})';
  } else if (bp === 'retina') {
    return '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
  }

  // Try to pull a named breakpoint out of the $breakpoints map
  if (typeof bp === 'string') {
    if (bp in breakpoints) {
      if (dir === 'only' || dir === 'down') {
        bpMax = nextBreakpoint(bp);
      }

      bp = breakpoints[bp];
      named = true;
    } else {
      bp = 0;
      console.warn(`breakpoint("${bp}"): is not defined in your breakpoints setting.`);
    }
  }

  // Convert any pixel, rem, or unitless value to em
  bp = bpToEm(bp);
  
  if (bpMax) {
    bpMax = bpToEm(bpMax) - (1/16);
  }

  // Conditions to skip media query creation
  // - It's a named breakpoint that resolved to "0 down" or "0 up"
  // - It's a numeric breakpoint that resolved to "0 " + anything
  if (bp > 0 || dir === 'only' || dir === 'down') {
    // `only` ranges use the format `(min-width: n) and (max-width: n)`
    if (dir === 'only') {
      // Only named media queries can have an "only" range
      if (named === true) {
        // Only use "min-width" if the floor is greater than 0
        if (bp > 0) {
          str = `${str}(min-width: ${bp}rem)`;

          // Only add "and" to the media query if there's a ceiling
          if (bpMax !== null) {
            str = `${str} and `;
          }
        }

        // Only use "max-width" if there's a ceiling
        if (bpMax !== null) {
          str = `${str}(max-width: ${bpMax}rem)`;
        }
      } else {
        console.warn('breakpoint(): Only named media queries can have an `only` range.');
      }
    } else if (dir === 'down') {
      // `down` ranges use the format `(max-width: n)`
      const max = named ? bpMax : bp;

    // Skip media query creation if input value is exactly "0 down",
    // unless the function was called as "small down", in which case it's just "small only"
    if (named || $bp > 0) {
      if (max !== null) {
          str = `${str}(max-width: ${max}rem)`;
        }
      }
    } else if (bp > 0) {
    // `up` ranges use the format `(min-width: n)`
      str = `${str}(min-width: ${bp}rem)`;
    }
  }

  return str;
}

function nextBreakpoint(bp) {
  const keys = Object.keys(breakpoints);
  const index = keys.indexOf(bp) + 1;
  return 0 < index && index < keys.length ? breakpoints[keys[index]] : null;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
function bpToEm(value) {
  // Pixel and unitless values are converted to rems
  if (typeof value === 'number' || /\d+px/.test(value)) {
    value = remCalc(value, { base: '16px' });
  }
  // Then the value is converted to ems
  return value; // omitted unit em
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
function remCalc(values, { base = globalFontSize }) {
  const remValues = [];
  // $count: length($values);
  let unit = '';
  if (typeof base === 'string') {
    unit = base.replace(/\d+(\.\d+)?/g);
    base = parseFloat(base);
  }
  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  if (unit === '%') {
    base = base / 100 * 16;
  } else if (unit === 'rem') {
    // Using rem as base allows correct scaling
    base = base * 16;
  }

  if (typeof values !== 'object') {
    values = [values];
  }
  values = values.map((value) => toRem(value, base));
  return values.length === 1 ? values[0] : values;
}


/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
function toRem(value, base = null) {
  let unit = '';
  if (typeof value === 'string') {
    unit = value.replace(/\d+(\.\d+)?/g);
    value = parseFloat(value);
  }

  // Check if the value is a number
  if (typeof value !== 'number') {
    console.warn(`"${value}" was passed to remCalc(), which is not a number.`);
    return value;
  }

  // omitted unit rem
  return unit === 'rem' ? value : value / base;
}
