import React, { Component } from 'react';
import { numberToCurrency } from 'javascripts/client/helpers';
import I18n from 'v2/client/javascripts/i18n';
import ModalLayout from './modal-layout';
import IconSvg from './icon-svg';

export default class BookingPaymentModal extends React.Component {
  constructor(props) {
    super(props);

    const { schedules } = this.props;
    this.state = {
      amountToPay: schedules.length > 0 && this.props.schedules[0].price,
    };
  }

  openModal() {
    this.modalLayout.openModal();
  }

  closeModal = () => {
    this.modalLayout.closeModal();
  }

  handlePaymentCheckboxChange = (e, index) => {
    if (index === 0) {
      // prevent uncheck of first schedule
      e.target.checked = true;
      e.stopPropagation();
      return;
    }
    const koef = e.target.checked ? 1 : -1;
    const amountToPay = this.state.amountToPay + (e.target.value * koef);
    this.setState({ amountToPay });
  }

  render() {
    return (
      <ModalLayout
        ref={(m) => this.modalLayout = m}
        onOpen={this.handleOpen}
        header={I18n.t('fe.client.booking_payment_modal.header')}
      >
        <div className='booking-payment-modal'>
          <form className='booking-payment-modal__form' method='POST' action={this.props.paymentUrl}>
            <input type='hidden' name='bookingPnr' value={this.props.bookingPnr} />
            <input type='hidden' name='id' value={this.props.sellerId} />
            <input type='hidden' name='control' value={this.props.control} />
            <input type='hidden' name='description' value={this.props.description} />
            <input type='hidden' name='URL' value={this.props.returnUrl} />
            <input type='hidden' name='type' value='0' />
            <input type='hidden' name='currency' value={this.props.baseCurrency} />
            <input type='hidden' name='channel' value={this.props.channel} />
            <input type='hidden' name='ch_lock' value='1' />
            {/* <input type='hidden' name='ignore_last_pay' value='1' /> */}
            <input type='hidden' name='amount' value={this.state.amountToPay} />
            <input type='hidden' name='lang' value={this.props.locale} />

            <div className="booking-payment-modal__help-text">
              {
                this.props.schedules.length > 0
                  ? I18n.t('fe.client.booking_payment_modal.help_text')
                  : I18n.t('fe.client.booking_payment_modal.pending_help_text')
              }
            </div>
            <div className="booking-payment-modal__schedules">
              <div className="booking-payment-modal__section-header">
                {I18n.t('fe.client.booking_payment_modal.schedules_section_header')}
              </div>
              {this.props.paid && (
                <div className='modal-layout__row booking-payment-modal__schedule-line'>
                  <div className='booking-payment-modal__schedule-prices'>
                    <span className="booking-payment-modal__paid-icon"><IconSvg icon="check" /></span>
                    <span className='booking-payment-modal__schedule-price'>{this.props.paid}</span>
                  </div>
                  <div className='booking-payment-modal__schedule-info'>
                    <span className="booking-payment-modal__paid-info">{I18n.t('defaults.dictionary.paid')}</span>
                  </div>
                </div>
              )}

              {this.props.schedules.map((schedule, index) => (
                <div className='modal-layout__row booking-payment-modal__schedule-line' key={"schedule-" + index}>
                  <div className='booking-payment-modal__schedule-prices'>
                    <input
                      id={`schedule-${index}`}
                      type="checkbox"
                      value={schedule.price}
                      defaultChecked={index === 0}
                      onChange={ (e) => this.handlePaymentCheckboxChange(e, index) }
                    />
                    <label htmlFor={`schedule-${index}`}>
                      <span className='booking-payment-modal__schedule-price'>{schedule.priceLabel}</span>
                      {schedule.priceCurrencyLabel && (
                        <span className='booking-payment-modal__schedule-price-currency'>{schedule.priceCurrencyLabel}</span>
                      )}
                    </label>
                  </div>
                  <div className='booking-payment-modal__schedule-info'>
                    {schedule.info}
                  </div>
                </div>
              ))}

              {this.props.pendingPricesSum && (
                <div className='modal-layout__row booking-payment-modal__schedule-line'>
                  <div className='booking-payment-modal__schedule-prices'>
                    <span className="booking-payment-modal__pending-icon"><IconSvg icon="booking-pending" /></span>
                    <span className='booking-payment-modal__pending-price'>{this.props.pendingPricesSum}</span>
                  </div>
                  <div className='booking-payment-modal__schedule-info'>
                    <span className="booking-payment-modal__pending-info">{I18n.t('fe.client.booking_payment_modal.pending_prices_info')}</span>
                  </div>
                </div>
              )}
            </div>

            {this.state.amountToPay && (
              <div className='modal-layout__row booking-payment-modal__total-and-pay'>
                <div className='booking-payment-modal__total-container'>
                    <div className='booking-payment-modal__section-header'>{I18n.t('defaults.dictionary.total_to_pay')}</div>
                    <div className='booking-payment-modal__amount-to-pay'>
                      <span className='booking-payment-modal__total-price'>
                        {
                          numberToCurrency(
                            this.state.amountToPay,
                            { precision: 0, unit: this.props.baseCurrency },
                          )
                        }
                      </span>
                    </div>
                </div>
                <div className='booking-payment-modal__pay-button-container'>
                  <button className='button button_expanded'>{I18n.t('defaults.actions.pay')}</button>
                </div>
              </div>
            )}
          </form>
        </div>
      </ModalLayout>
    );
  }
}
