import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from "prop-types";

import CheckboxesList from './checkboxes-list';


class MealTypeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeMealTypes = this.handleChangeMealTypes.bind(this);
  }

  handleChangeMealTypes(mealTypes) {
    this.props.onChangeFilter({ mealTypes });
  }

  render() {
    const { mealTypes, selectedMealTypes } = this.props;
    return (
      <CheckboxesList
        listName="meal_types"
        options={mealTypes.map(meal_type =>
          [I18n.t(`activerecord.attributes.room_price_policy.meal_types.${meal_type}`), meal_type]
        )}
        selected={selectedMealTypes}
        onChange={this.handleChangeMealTypes}
      />
    );
  }
}

MealTypeFilter.propTypes = {
  mealTypes: PropTypes.array.isRequired,
  selectedMealTypes: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

export default MealTypeFilter