import React from 'react';
import PropTypes from 'prop-types'
import Swiper from 'swiper/dist/js/swiper.js';
import ModalLayout from './modal-layout';
import ImagesGallery from './images-gallery';
import IconSvg from './icon-svg';
import { breakpointsForSwiper } from '../media-queries';

const slidesToShowByBreakpoint = {
  small: 4,
  medium: 5,
  //xmedium: 5,
  large: 10,
  xlarge: 10,
  xxlarge: 10,
};

export default class PropertyViewImagesSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSlide: null,
    };
  }

  componentDidMount() {
    new Swiper('.property-view-images-slider__swiper-container', Object.assign({
      freeMode: true,
      freeModeSticky: true,
      navigation: {
        nextEl: '.property-view-images-slider__arrow-button.next',
        prevEl: '.property-view-images-slider__arrow-button.prev',
      },
    }, breakpointsForSwiper(slidesToShowByBreakpoint)));
    $('.hero').click(e => { if ($(e.target).parentsUntil('.hero').length <= 2) this.handleClickItem(0); });
  }

  handleClickItem(selectedSlide) {
    this.setState({ selectedSlide }, () => {
      this.modalLayout.openModal();
    });
  }

  render() {
    return (
      <div className="property-view-images-slider">
        <div className="property-view-images-slider__arrow-container">
          <Arrow className="prev" />
        </div>
        <div className="swiper-container property-view-images-slider__swiper-container">
          <div className="swiper-wrapper">
            {this.props.images.map((image, imageIndex) =>
              <div
                key={image.thumb_url}
                className="swiper-slide property-view-images-slider__item"
                onClick={() => this.handleClickItem(imageIndex)}
              >
                <div
                  className="property-view-images-slider__thumb"
                  style={{ backgroundImage: `url("${image.thumb_url}")` }}
                  title={image.caption}
                />
              </div>
            )}
          </div>
        </div>
        <div className="property-view-images-slider__arrow-container">
          <Arrow className="next" />
        </div>
        <ModalLayout
          ref={c => this.modalLayout = c}
          withoutContainer
            closeIcon={true}
        >
          <ImagesGallery
            images={this.props.images}
            initialSlide={this.state.selectedSlide}
          />
        </ModalLayout>
      </div>
    );
  }
}

PropertyViewImagesSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      thumb_url: PropTypes.string.isRequired,
      caption: PropTypes.string,
    }),
  ).isRequired,
};

const Arrow = props =>
  <div
    {...props}
    className={`property-view-images-slider__arrow-button ${props.className}`}
  >
    <IconSvg
      className="arrow-icon"
      icon="arrow-right"
      size={1.5}
    />
  </div>;
