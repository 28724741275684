import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types'
import BookingPropertyGuestsModal from "./booking-property-guests-modal";

export default class BookingPropertyGuestsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false
    };
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick(e) {
    e.preventDefault();
    this.setState({
      modalOpened: true
    });
  }

  render() {
    return (
      [
        <button
          key='Button'
          className="button button_hollow"
          onClick={this.handleClick}>
          {I18n.t('fe.client.booking_property_guest.title')}
        </button>,
        <BookingPropertyGuestsModal
          key='Modal'
          guests={this.props.guests}
          opened={this.state.modalOpened}
          maxGuestsCount={this.props.maxGuestsCount}
          guestsPath={this.props.guestsPath}
          svgsprite={this.props.svgsprite}
        />
      ]
    )
  }
}

BookingPropertyGuestsButton.propTypes = {
  guests: PropTypes.array.isRequired,
  maxGuestsCount: PropTypes.number.isRequired,
  guestsPath: PropTypes.string.isRequired,
  svgsprite: PropTypes.string.isRequired
};
