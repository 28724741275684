import React from 'react';
import isNumber from 'lodash/isNumber';
import classNames from 'classnames';

class CountInput extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            counter: this.props.initialCounterValue
        }

        this.add = this.add.bind(this)
        this.minus = this.minus.bind(this)
        this.change = this.change.bind(this)

    }

    change(counter) {
        this.setState({counter: counter}, () => {
            if(this.counterInput) {
                const event = new Event('change', { bubbles: true })
                this.counterInput.dispatchEvent(event)
            }
        })   
    }

    add() {
        const { maxCounterValue } = this.props
        const counter = (this.props.counterValue || this.state.counter) + 1;
        if(isNumber(maxCounterValue) && counter > maxCounterValue) { return }

        this.change(counter)
        this.props.onAdd(counter)           
    }

    minus() {
        const { minCounterValue } = this.props
        const counter = (this.props.counterValue || this.state.counter) - 1;
        if(isNumber(minCounterValue) && counter < minCounterValue) { return }

        this.change(counter)        
        this.props.onMinus(counter)
    }

    render() {
        const value = this.props.counterValue || this.state.counter;
        return (
            <div className={classNames("count-input", this.props.cssClass)}>
                <div className="count-input__minus" onClick={this.minus}>
                    <span>–</span>
                </div>
                <span className="count-input__value">{value}</span>
                <div className="count-input__add" onClick={this.add}>
                    <span>+</span>
                </div>
                {this.props.inputName && <input type="hidden" ref={el => this.counterInput = el} value={value} name={this.props.inputName} />}
            </div>
            
        )
    }
}

CountInput.defaultProps = {
    cssClass: null,
    inputName: null,
    initialCounterValue: 0,
    counterValue: null,
    minCounterValue: null,
    maxCounterValue: null,
    onAdd: new Function,
    onMinus: new Function
}


export default CountInput
