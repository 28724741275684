export default function initJivoSite(locale) {
  let widgetId;
  if (locale == 'ru') {
    widgetId = 'REDPyiEYuL';
  } else {
    widgetId = 'svzfZb0gNY';
  }
  const s = document.createElement('script');
  const ss = document.getElementsByTagName('script')[0];

  s.type = 'text/javascript';
  s.async = true;
  s.src = `//code.jivosite.com/script/widget/${widgetId}`;
  ss.parentNode.insertBefore(s, ss);

  const chatOpeners = document.querySelectorAll('.js-chat-opener');

  chatOpeners.forEach((elem) => {
    elem.addEventListener('click', (e) => {
      jivo_api.open();
    });
  });

  if (typeof jivo_init === 'function') {
    jivo_init();
  }
}
