import React from 'react';
import PropTypes from 'prop-types'
import { PortalWithState } from 'react-portal';
import classNames from 'classnames';
import IconSvg from './icon-svg';
import { getCurrentWindowSize } from 'javascripts/client/media-queries';
import { lockLayout, unLockLayout, classWithModifiers } from 'javascripts/client/helpers';

export default class ModalLayout extends React.Component {

    static propTypes = {
        scrollContainerClass: PropTypes.string,
        withoutContainer: PropTypes.bool,
        closeIcon: PropTypes.bool,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        modifiers: PropTypes.array,
        header: PropTypes.string,
        closeOnOutsideClick: PropTypes.bool,
        hideHeader: PropTypes.bool        
    }


    constructor(props) {
        super(props)
    }

    openModal = () => {
        this.modalPortal.openPortal()
    }

    closeModal = () => {
        this.modalPortal.closePortal()
    }

    handleOpen = () => {
        lockLayout()        
        this.props.onOpen(ReactDOM.findDOMNode(this.modalPortal))
    }

    handleClose = () => {
        unLockLayout();
        this.props.onClose();
    }

    scrollTo = (...args) => {
        this.portableModalLayout.scrollTo(...args);
    }

    render() {
        const { children, ...otherProps } = this.props
        return (
            <PortalWithState
                ref={c => this.modalPortal = c}
                closeOnEsc
                onClose={this.handleClose}
                onOpen={this.handleOpen}
            >
                {({ openPortal, closePortal, isOpen, portal }) => (
                    <React.Fragment>
                        {portal(
                            <PortalableModalLayout
                                ref={c => this.portableModalLayout = c}
                                {...otherProps}
                                closePortal={closePortal}
                            >
                                {children}
                            </PortalableModalLayout>
                        )}
                    </React.Fragment>
                )}
            </PortalWithState>
        );
    }
}

ModalLayout.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  closeIcon: false,
  closeOnOutsideClick: true,
  hideHeader: false
};



class PortalableModalLayout extends React.Component {

    static propTypes = {
        scrollContainerClass: PropTypes.string,
        withoutContainer: PropTypes.bool,
        closeIcon: PropTypes.bool,
        modifiers: PropTypes.array,
        header: PropTypes.string,
        hideHeader: PropTypes.bool
    }


    scrollTo(target, duration = 400, settings = null) {
        $(this.scrollContainer).scrollTo(target, duration, settings);
    }


    handleOutsideClick = (e) => {
        if(this.containerInner && this.props.closeOnOutsideClick && !this.containerInner.contains(e.target)) {
            this.props.closePortal()
        }
    }


    render() {
        const { modifiers } = this.props

        return (
            <div className={classWithModifiers("modal-layout", modifiers)} onClick={this.handleOutsideClick}>
                <div
                    className={classNames('modal-layout__scroll-container', this.props.scrollContainerClass)}
                    ref={c => this.scrollContainer = c}
                >
                    {this.props.withoutContainer ? this.props.children : (
                        <div className="ga-container">
                            <div className="modal-layout__container">
                                <div className="modal-layout__container-inner" ref={c => this.containerInner = c}>
                                    <div className="modal-layout__content">
                                        <div className="modal-layout__content-inner" >
                                            {!this.props.hideHeader && (
                                                <div className="modal-layout__header-container">
                                                    <div className="modal-layout__header">
                                                        {this.props.header}
                                                    </div>
                                                    <div className="modal-close" onClick={this.props.closePortal}>
                                                        <IconSvg icon="close" />                                                
                                                    </div>
                                                </div>
                                            )}
                                            {this.props.children} 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {this.props.closeIcon && (                
                <div className='modal-layout__close-container'>
                    <div className="ga-container">
                        <div className="modal-layout__close-column">
                            <div
                                className="modal-layout__close-button"
                                onClick={this.props.closePortal}
                            >
                                <IconSvg
                                    className="modal-layout__close-icon"
                                    icon="close"
                                    size={1.5}
                                    fill="white"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                )}

            </div>
        );
    }
}


