export const DEFAULT_TRANSFER_PARAMS_STATE = {
    fromLocation: {},
    toLocation: {},
    date: null,
    dateReturn: null,
    pax: 0,
    oneWay: false
}


export const DEFAULT_ACCOMMODATION_PARAMS_STATE = {
    dest: {},
    dateIn: null,
    dateOut: null,
    guests: { adults: 2 }
}

export const DEFAULT_SEARCHBOX_PARAMS_STATE = {
    params: {
        accommodation: DEFAULT_ACCOMMODATION_PARAMS_STATE,
        transfer: DEFAULT_TRANSFER_PARAMS_STATE
    }
};

export const UPDATE_SEARCHBOX_PARAMS = 'UPDATE_SEARCHBOX_PARAMS';
