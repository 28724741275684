import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import filter from 'lodash/filter';
import find from 'lodash/find';
import sumBy from 'lodash/sumBy';
import { AppPropTypes, connectRedux } from '../redux';
import CountInput from 'javascripts/common/components/count-input/count-input';

class PropertyRoomSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChangeAmount = (amount) => {
    if (this.getMaxAmount() > 0) {
      this.props.dispatchAmount(this.props.policy_id, amount);
    }
  }

  getAmount(policyId) {
    return (find(this.props.room_cart, { id: policyId }) || { amount: 0 }).amount;
  }

  getMaxAmount() {
    const { room_variants, policy_id } = this.props;
    const roomVariant = find(room_variants, { policy_id: policy_id });
    const currentRoomVariants = filter(room_variants, { room_id: roomVariant.room_id });
    const amountSumInRoom = sumBy(currentRoomVariants, rv =>
        rv.policy_id === policy_id ? 0 : this.getAmount(rv.policy_id)
    );
    return roomVariant.max_amount - amountSumInRoom;
  }

  render() {
    const amount = this.getAmount(this.props.policy_id);
    const maxAmount = this.getMaxAmount();
    const className = classNames("button button_hollow cart-change-btn", {
      "button_disabled": maxAmount === 0
    });

    return (amount === 0) ? (
      <a className={ className }
        onClick={this.handleChangeAmount.bind(this, 1)}>
        {I18n.t('defaults.actions.choose')}
      </a>
    ) : (
      <CountInput
        counterValue={amount}
        minCounterValue={0}
        maxCounterValue={maxAmount}
        onAdd={this.handleChangeAmount}
        onMinus={this.handleChangeAmount}
      />
    );
  }
}

PropertyRoomSelect.propTypes = {
  policy_id: PropTypes.number,
  room_variants: PropTypes.arrayOf(AppPropTypes.roomVariant),
  room_cart: PropTypes.arrayOf(AppPropTypes.roomCartItem),
  dispatchAmount: PropTypes.func,
};

export default connectRedux(PropertyRoomSelect, ['room_variants', 'room_cart'], {
  dispatchAmount: (policy_id, amount) => {
    return { type: 'PATCH_room_cart', patch: { id: policy_id, amount } };
  },
});
