import React from 'react';
import { Provider } from 'react-redux';
import getStore from './store';

/*
 *  Export a function that returns a ReactComponent, depending on a store.
 *  This is used for the client rendering hook after the page html is rendered.
 *  React will see that the state is the same and not do anything.
 */
export default (ComposedComponent, displayName = null) => {
  const component = (ownProps) => {
    return (
      <Provider store={getStore()}>
        <ComposedComponent {...ownProps} />
      </Provider>
    );
  };
  if (displayName) {
    component.displayName = displayName;
  }
  return component;
};
