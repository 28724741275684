import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';
import without from 'lodash/without';
import union from 'lodash/union';

class CheckboxesList extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
  }

  handleChangeCheckbox({ target: { value, checked }}) {
    const { selected, onChange } = this.props;
    onChange(checked ? union(selected, [value]) : without(selected, value));
  }

  render() {
    const { listName, options, selected, listClassName, itemClassName, labelClassName } = this.props;
    return (
      <ul className={listClassName}>
        {options.map(([label, option]) => {
          const inputId = `${listName}_${option}`;
          return (
            <li
              key={option}
              className={itemClassName}
            >
              <input
                id={inputId}
                type="checkbox"
                value={option}
                checked={includes(selected, option)}
                onChange={this.handleChangeCheckbox}
              />
              <label
                htmlFor={inputId}
                className={labelClassName}
              >
                {label}
              </label>
            </li>
          );
        })}
      </ul>
    );
  }
}

CheckboxesList.defaultProps = {
  listClassName: 'checkboxes-list',
  itemClassName: 'checkboxes-list__item',
  labelClassName: 'checkboxes-list__label',
};

CheckboxesList.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  listName: PropTypes.string,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  labelClassName: PropTypes.string
};

export default CheckboxesList
