import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { wrapFilterInDropdown } from "./dropdown-filter";
import { applyFilter, blankFilterState, updateFilter, resetFilter } from 'javascripts/client/actions/properties/filterActions';
import { loadOptions } from 'javascripts/client/actions/properties/optionsActions';
import PropertyTypeFilter from './property-type-filter';
import PriceFilter from './price-filter';
import PropertiesAllFilters from './properties-all-filters';
import OrderSelector from './order-selector';
import { Portal } from 'react-portal';
import IconSvg from 'javascripts/client/components/icon-svg';
import { lockLayout, unLockLayout } from 'javascripts/client/helpers';


const sharedDropdownProps = {
  'data-dropdown': 'true',
  'data-auto-focus': 'false',
};

const PropertyTypeDropdown = wrapFilterInDropdown(PropertyTypeFilter, Object.assign({}, sharedDropdownProps, {
  className: 'dropdown-pane dropdown-pane_property-type-filter',
  id: 'js-property-type-filter',
  'data-alignment': 'left',
}));

const PriceDropdown = wrapFilterInDropdown(PriceFilter, Object.assign({}, sharedDropdownProps, {
  className: 'dropdown-pane dropdown-pane_price-filter',
  id: 'js-price-filter',
  'data-alignment': 'center',
}));

class PropertiesFilters extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAnyDropdown: false,
            showAllFilters: false,
        }

        this.handleShowDropdown = this.handleToggleDropdown.bind(this, true)
        this.handleHideDropdown = this.handleToggleDropdown.bind(this, false)
    }

    componentDidMount = () => {
        window.addEventListener('mousedown', this.handleMousedownWindow)
        window.addEventListener('touchstart', this.handleMousedownWindow)
        window.addEventListener('click', this.handleClickWindow)
        window.addEventListener('keydown', this.handleKeyDown)
        $(window).on('show.zf.dropdown', this.handleShowDropdown)
        $(window).on('hide.zf.dropdown', this.handleHideDropdown)
        this.props.loadOptions()
    }

    componentWillUnmount = () => {
        window.removeEventListener('mousedown', this.handleMousedownWindow)
        window.removeEventListener('touchstart', this.handleMousedownWindow)
        window.removeEventListener('click', this.handleClickWindow)
        window.removeEventListener('keydown', this.handleKeyDown)
        $(window).off('show.zf.dropdown', this.handleShowDropdown)
        $(window).off('hide.zf.dropdown', this.handleHideDropdown)
    }

    handleToggleDropdown = (showAnyDropdown, event) => {
        if ($(event.target).closest('.proplist-controls').length > 0) {
            this.lastMousedownTarget = null
            this.setState({ showAnyDropdown })
        }
    }

    handleMousedownWindow = (event) => {
        this.lastMousedownTarget = event.target
    }

    handleClickWindow = (event) => {
        if (event.target !== this.lastMousedownTarget) {
            return;
        }
        this.tryCloseDropdown(event)
    }

    handleKeyDown = (event) => {
        if(event.key === 'Escape' && !this.props.isFilterChanged) {
            this.tryCloseDropdown(event);
            this.handleCloseAllFilters();
        }
    }

    tryCloseDropdown = (event, force = false) => {
        const { isFilterChanged, applyFilter } = this.props
        if (this.state.showAnyDropdown) {
            const $dropdown = $('.dropdown-pane.is-open');
            if (force || !$(event.target).closest($dropdown).length) {
                $dropdown.foundation('close');                
                if (isFilterChanged) {
                    applyFilter()
                }
            }
        }
    }

    showAllFilters = () => {
        this.setState({ showAllFilters: true })
        lockLayout()
    }

    hideAllFilters = () => {
        this.setState({ showAllFilters: false })
        unLockLayout()
    }

    resetToAppliedFilters = () => {
        this.props.updateFilter(this.props.appliedFilter)
    }

    handleCloseAllFilters = () => {
        this.hideAllFilters()
        this.resetToAppliedFilters()
    }

    handleClickMoreFilters = (event) => {
        event.stopPropagation();
        this.tryCloseDropdown(event);
        this.showAllFilters()
    }

    handleOrderChange = (orderBy, orderDir) => {
        this.props.updateFilter({ orderBy, orderDir })
        this.props.applyFilter()
    }

    handleApplyFilter = (event) => {
        const { isFilterChanged, applyFilter } = this.props        
        if(this.state.showAnyDropdown) {
            this.tryCloseDropdown(event, true)
            // we apply filters in close dropdown, so return.
            return;
        }
        if (isFilterChanged) {
            applyFilter()
            this.hideAllFilters()
        }
    }

    render() {
        const {
            filter,
            applyFilter,
            updateFilter,
            options,
            isFilterChanged,
            isFilterResetable,
            resetFilter,
            userCurrency
        } = this.props;

    return (
      <div className="proplist-controls">
        <div className="proplist-controls__cell">
          <div className="proplist-controls__filter">
            <div
              className="button button_link proplist-controls__filter-link"
              data-toggle="js-property-type-filter"
            >
              {I18n.t('fe.client.properties_filters.property_type.title')}
              <IconSvg icon='chevron-down' />
            </div>
            <PropertyTypeDropdown
                propertyTypes={options.propertyTypes}
                selectedPropertyTypes={filter.propertyTypes}
                isFilterChanged={isFilterChanged}
                onChangeFilter={updateFilter}
                onApplyFilter={this.handleApplyFilter} />
          </div>

          <div className={"proplist-controls__filter proplist-controls__filter_show_" + (options.minPrice && options.minPrice)}>
            <div
              className="button button_link proplist-controls__filter-link"
              data-toggle="js-price-filter"
            >
              {I18n.t('fe.client.properties_filters.price.title')}
              <IconSvg icon='chevron-down' />                
            </div>
            <PriceDropdown
                minPrice={options.minPrice}
                maxPrice={options.maxPrice}
                startPrice={filter.startPrice}
                endPrice={filter.endPrice}
                isFilterChanged={isFilterChanged}
                onChangeFilter={updateFilter}
                onApplyFilter={this.handleApplyFilter} />
          </div>

          <div className="proplist-controls__filter proplist-controls__filter_all">
            <div
              className="button button_link proplist-controls__filter-link proplist-controls__filter-link_all"
              onClick={this.handleClickMoreFilters}
            >
              <span className="proplist-controls__filter-link_all-desktop">{I18n.t('fe.client.properties_filters.all_filters')}</span>
              <span className="proplist-controls__filter-link_all-mobile">{I18n.t('fe.client.properties_filters.filters')}</span>
              <IconSvg icon='chevron-down' />              
            </div>
          </div>

        </div>

        <div className="proplist-controls__cell proplist-controls__cell_order">
            <div className="proplist-controls__sort-label">
                <span>{I18n.t('fe.client.properties_filters.order.title')}</span>
            </div>
            <OrderSelector
                className="proplist-controls__order-selector"
                orderBy={ filter.orderBy }
                orderDir={ filter.orderDir }
                minPrice={ options.minPrice }
                maxPrice={ options.maxPrice }
                onChange={ this.handleOrderChange } />
        </div>

        {this.state.showAllFilters &&
        <Portal>
            <div className="proplist-controls__all-filters" ref={c => this.allFilters = c}>
              <PropertiesAllFilters
                userCurrency={userCurrency}
                dest={this.props.dest}
                filter={filter}
                options={options}
                isFilterChanged={isFilterChanged}
                isFilterResetable={isFilterResetable}
                onChangeFilter={updateFilter}
                onApplyFilter={this.handleApplyFilter}
                onResetFilter={resetFilter}
                onCloseFilter={this.handleCloseAllFilters}
              />
            </div>
        </Portal>
        }
        {(this.state.showAnyDropdown || this.state.showAllFilters) &&
        <div
          className="proplist-controls__lock-pane"
          style={{
            height: this.lockHeight(),
          }}
        />
        }
      </div>
    );
  }

  lockHeight() {
    const $nonLockHeader = $('.page-content-header');
    return $('body').height() - ($nonLockHeader.position().top + $nonLockHeader.outerHeight());
  }
}


function mapStateToProps({ user_currency, properties: { appliedFilter, filter, options } }) {
  const isFilterChanged = !isEqual(filter, appliedFilter);
  const isFilterResetable = !isEqual(filter, blankFilterState(options));
  return {
    isFilterChanged,
    isFilterResetable,
    appliedFilter,
    filter,
    options,
    userCurrency: user_currency
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateFilter,
    applyFilter,
    resetFilter,
    loadOptions
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesFilters)