import find from 'lodash/find';

function createGetProps($target = $(window), getExternalProps = null) {
  return function getProps() {
    const props = {
      scrollTop: $target.scrollTop() - ($target.height() - window.innerHeight),
      width: $target.width(),
      height: $target.height(),
    };
    return getExternalProps ? Object.assign(props, getExternalProps(props)) : props;
  };
}

export function subscribeAnimationFrame($target, prepareFrame, doWhile, getExternalProps = null) {
  const getProps = createGetProps($target, getExternalProps);
  let props = {};

  function handleFrame(timestamp) {
    const newProps = getProps();
    if (find(Object.keys(newProps), propKey => newProps[propKey] !== props[propKey])) {
      prepareFrame(newProps, props, timestamp);
      props = newProps;
    }
    if (doWhile(newProps, timestamp)) {
      window.requestAnimationFrame(handleFrame);
    }
  }

  window.requestAnimationFrame(handleFrame);
}
