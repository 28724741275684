import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import random from 'lodash/random'
import IconSvg from 'javascripts/client/components/icon-svg'


const theme = {
  container: 'dest-autosuggest__container',
  containerOpen: 'dest-autosuggest__container--open',
  input: 'dest-autosuggest__input input_with-icon-prefix input_with-icon-postfix ',
  suggestionsContainer: 'dest-autosuggest__suggestions-container',
  suggestionsList: 'dest-autosuggest__suggestions-list',
  suggestion: 'dest-autosuggest__suggestion',
  suggestionName: 'dest-autosuggest__suggestion-name',
  suggestionAfterName: 'dest-autosuggest__suggestion-after-name',
  suggestionHighlighted: 'dest-autosuggest__suggestion--highlighted',
  sectionContainer: 'dest-autosuggest__section-container',
  sectionTitle: 'dest-autosuggest__section-title'
}


function shouldRenderSuggestions(value, minValueLetter) {
    return value.trim().length >= minValueLetter;
}

const renderSuggestion = suggestion => (
    <div>
      <span className={theme.suggestionName}>{suggestion.name}, </span>
      <span className={theme.suggestionAfterName}>{suggestion.afterName}</span>
    </div>
)

const getSectionSuggestions = section => section.suggestions
const getSuggestionValue = suggestion => suggestion.name

const renderInputComponent = (inputProps, handleClearIconClick) => (
    <div className="input__container">
      <div className="input__icon-prefix input__icon-prefix_no-pointer-events">
        <IconSvg icon={inputProps.icon} className={inputProps.iconClass} />
      </div>

      <input {...inputProps} />


      {(inputProps.value && !inputProps.disabled) &&
      <div className="input__icon-clear input__icon-postfix" onClick={handleClearIconClick}>
        <svg viewBox="0 0 24 24" className="icon-svg">
          <path
              d="M10.586,12L4.93,6.343L6.342,4.93L12,10.585l5.657-5.655l1.414,1.413L13.42,12l5.657,5.657l-1.413,1.414L12,13.42l-5.657,5.65L4.93,17.658L10.585,12H10.586z"/>
        </svg>
      </div>
      }
    </div>
);


const renderFakeDropdown = () => (
  <div className="dest-autosuggest__container dest-autosuggest__container--open">
    <div className="dest-autosuggest__suggestions-container">
      <div className="dest-autosuggest__section-container">
        <ul className="dest-autosuggest__suggestions-list">
          {
            Array.from({ length: 5 }).map((v,i) => (
                <li className="dest-autosuggest__suggestion" key={i}>
                  <div>
                    <span className="dest-autosuggest__fake-name" style={{ width: `${random(40, 45)}%` }}>
                      fake
                    </span>
                    <span className="dest-autosuggest__fake-name" style={{ width: `${random(40, 45)}%` }}>
                      fake
                    </span>
                  </div>
                </li>
            ))
          }
        </ul>
      </div>
    </div>
  </div>
)


const renderValueInput = (value, inputName) => (
    <input type="hidden" value={value || ''} name={inputName} />
)

export default class DestAutosuggest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.initialValue,
            suggestions: [],
            isLoading: false
        }
    }

    handleInputChange = (e, {newValue}) => {
        this.setState({value: newValue})
        this.props.onInputChange(newValue)
    }

    handleClearIconClick = (e) => {
        this.setState({value: ''})
        this.props.onInputChange('')
        this.setFocus()
      }

    handleInputFocus = (e) => {
    }

    handleInputBlur = (e) => {
    }

    handleSuggestionsFetchRequested = ({value}) => {
        this.loadSuggestionsFromServer(value)
    }

    handleSuggestionsClearRequested = () => {
        this.setState({suggestions: []});
    }

    handleSuggestionSelected = (e, {suggestion, suggestionValue, sectionIndex, method}) => {
        if (method === 'enter') {
            e.preventDefault();
        }
        if (this.props.clearAfterSelect) {
            this.setState({value: ''});
            this.props.onInputChange('')
        }
        this.props.onSuggestionSelected(suggestion, suggestionValue)
    }

    loadSuggestionsFromServer(value) {
        const { url, typeTimeOut, searchIn } = this.props;

        if (this.loadTimeout) {
            clearTimeout(this.loadTimeout);
        }
        const self = this;

        let params = `q=${value}`
        if(searchIn) {
            searchIn.forEach((e) => {
                params += `&search_in[]=${e}`
            })
        }

        this.loadTimeout = setTimeout(() => {
            self.setState({ isLoading: true });
            fetch(`${url}?${params}`, {
                    credentials: 'same-origin'
                })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error();
                })
                .then(data => {
                    self.setState({ suggestions: data.suggestions, isLoading: false });
                })
                .catch(err => {
                    console.log(err);
                })
        }, typeTimeOut); 
    }


    setFocus() {
        this.refs.autosuggest.input.focus()
    }


    renderSectionTitle = (section) => {
        if (this.props.showSectionTitle) {
            return (
                <strong>{section.title}</strong>
            );
        }
    }


    render() {
        const { suggestions } = this.state

        // Choose self controlled or not. Priority value from props.
        const value = this.props.value || this.state.value


        const inputProps = {
            placeholder: this.props.placeholder,
            disabled: this.props.disabled,
            value: value || "",
            iconClass: this.props.iconClass,
            icon: this.props.icon,
            onChange: this.handleInputChange,
            onFocus: this.handleInputFocus,
            onBlur: this.handleInputBlur,
            onMouseUp: (e) => e.preventDefault(),
            id: this.props.id,
            ref: 'input',
            spellCheck: false
        }

        return (
            <React.Fragment>
                <Autosuggest
                    inputProps={inputProps}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    shouldRenderSuggestions={(value) => shouldRenderSuggestions(value, this.props.minValueLetter)}
                    multiSection={true}
                    renderSectionTitle={this.renderSectionTitle}
                    getSectionSuggestions={getSectionSuggestions}
                    focusInputOnSuggestionClick={false}
                    renderInputComponent={(inputProps) => renderInputComponent(inputProps, this.handleClearIconClick)}
                    theme={theme}
                    id={this.props.id}
                    ref="autosuggest" />
                { this.state.isLoading ? renderFakeDropdown() : null }
                { this.props.valueInputName ? renderValueInput(value, this.props.valueInputName) : null }
            </React.Fragment>
        );
    }
}


// TODO
DestAutosuggest.propTypes = {
    url: PropTypes.string.isRequired,
    onSuggestionSelected: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    typeTimeOut: PropTypes.number,
    minValueLetter: PropTypes.number,
    icon: PropTypes.string.isRequired
}

// TODO
DestAutosuggest.defaultProps = {
    url: "/autocomplete",
    minValueLetter: 2,
    showSectionTitle: true,
    typeTimeOut: 300,
    onInputChange: new Function,
    valueInputName: null,
    icon: 'location-filled'
}
