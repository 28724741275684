/* rails-erb-loader-dependencies ../config/routes */

var routes = {};

(function() {
  /*
File generated by js-routes 1.4.9
Based on Rails 5.2.0 routes of Goalpes::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// api_booking_transfer_voucher => /api/booking_transfer_vouchers/:id(.:format)
  // function(id, options)
  api_booking_transfer_voucher_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"booking_transfer_vouchers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// autocomplete => /autocomplete(.:format)
  // function(options)
  autocomplete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"autocomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// batch_update_partner_booking_booking_statuses => /partner/bookings/:booking_id/booking_statuses/batch_update(.:format)
  // function(booking_id, options)
  batch_update_partner_booking_booking_statuses_path: Utils.route([["booking_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_statuses",false],[2,[7,"/",false],[2,[6,"batch_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// blog_category => /blog/category/:id(.:format)
  // function(id, options)
  blog_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[6,"category",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// blog_post => /blog/:id(.:format)
  // function(id, options)
  blog_post_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// blog_posts => /blog(.:format)
  // function(options)
  blog_posts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// booking => /bookings/:id(.:format)
  // function(id, options)
  booking_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// booking_properties => /booking_properties(.:format)
  // function(options)
  booking_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// booking_property_guest => /booking_property_guests/:id(.:format)
  // function(id, options)
  booking_property_guest_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_property_guests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// booking_transfers => /booking_transfers(.:format)
  // function(options)
  booking_transfers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_transfers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bookings => /bookings(.:format)
  // function(options)
  bookings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bookings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// calc_prices_admin_booking_booking_properties => /admin/booking/bookings/:booking_id/booking_properties/calc_prices(.:format)
  // function(booking_id, options)
  calc_prices_admin_booking_booking_properties_path: Utils.route([["booking_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_properties",false],[2,[7,"/",false],[2,[6,"calc_prices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// calc_prices_admin_booking_booking_transfers => /admin/booking/bookings/:booking_id/booking_transfers/calc_prices(.:format)
  // function(booking_id, options)
  calc_prices_admin_booking_booking_transfers_path: Utils.route([["booking_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_transfers",false],[2,[7,"/",false],[2,[6,"calc_prices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// cancel_admin_admin_user_registration => /admin/admin_users/cancel(.:format)
  // function(options)
  cancel_admin_admin_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admin_users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_locale_currency => /change_locale_currency(.:format)
  // function(options)
  change_locale_currency_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"change_locale_currency",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// confirm_all_partner_booking_booking_statuses => /partner/bookings/:booking_id/booking_statuses/confirm_all(.:format)
  // function(booking_id, options)
  confirm_all_partner_booking_booking_statuses_path: Utils.route([["booking_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_statuses",false],[2,[7,"/",false],[2,[6,"confirm_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// count_properties => /properties/count(.:format)
  // function(options)
  count_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[6,"count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// credentials_bookings => /bookings/credentials(.:format)
  // function(options)
  credentials_bookings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"credentials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// decline_all_partner_booking_booking_statuses => /partner/bookings/:booking_id/booking_statuses/decline_all(.:format)
  // function(booking_id, options)
  decline_all_partner_booking_booking_statuses_path: Utils.route([["booking_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_statuses",false],[2,[7,"/",false],[2,[6,"decline_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// dest_select_properties => /properties/dest_select(.:format)
  // function(options)
  dest_select_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[6,"dest_select",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dest_select_transfer_index => /transfer/dest_select(.:format)
  // function(options)
  dest_select_transfer_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"transfer",false],[2,[7,"/",false],[2,[6,"dest_select",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_admin_admin_user_session => /admin/admin_users/sign_out(.:format)
  // function(options)
  destroy_admin_admin_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admin_users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_batch_admin_property_arrival_rules => /admin/accommodation/properties/:property_id/arrival_rules/destroy_batch(.:format)
  // function(property_id, options)
  destroy_batch_admin_property_arrival_rules_path: Utils.route([["property_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"arrival_rules",false],[2,[7,"/",false],[2,[6,"destroy_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dotpay_confirmations => /dotpay/confirmations(.:format)
  // function(options)
  dotpay_confirmations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dotpay",false],[2,[7,"/",false],[2,[6,"confirmations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dotpay_return => /dotpay/return(/:control)(.:format)
  // function(options)
  dotpay_return_path: Utils.route([["control",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"dotpay",false],[2,[7,"/",false],[2,[6,"return",false],[2,[1,[2,[7,"/",false],[3,"control",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// download_translations_template_admin_property => /admin/accommodation/properties/:id/download_translations_template(.:format)
  // function(id, options)
  download_translations_template_admin_property_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download_translations_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_partner_booking => /partner/bookings/:id/edit(.:format)
  // function(id, options)
  edit_partner_booking_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_profile_user => /profile/users/:id/edit(.:format)
  // function(id, options)
  edit_profile_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// facebook_signin => /facebook_signin(.:format)
  // function(options)
  facebook_signin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"facebook_signin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// filters_properties => /properties/filters(.:format)
  // function(options)
  filters_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[6,"filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// graphql => /graphql(.:format)
  // function(options)
  graphql_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"graphql",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// impersonate_admin_user => /admin/people/users/:id/impersonate(.:format)
  // function(id, options)
  impersonate_admin_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"people",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// index_human_admin_versions => /admin/logs/versions/index_human(.:format)
  // function(options)
  index_human_admin_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"logs",false],[2,[7,"/",false],[2,[6,"versions",false],[2,[7,"/",false],[2,[6,"index_human",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// index_nested_admin_transfer_routes => /admin/transfers/transfer_routes/index_nested(.:format)
  // function(options)
  index_nested_admin_transfer_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_routes",false],[2,[7,"/",false],[2,[6,"index_nested",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_as_notified_admin_booking => /admin/booking/bookings/:id/mark_as_notified(.:format)
  // function(id, options)
  mark_as_notified_admin_booking_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_notified",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_admin_booking_transfer_driver => /admin/booking_transfer_drivers/:id/move(.:format)
  // function(id, options)
  move_admin_booking_transfer_driver_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking_transfer_drivers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// move_higher_admin_blog_post_image => /admin/blog/blog_posts/:blog_post_id/images/:id/move_higher(.:format)
  // function(blog_post_id, id, options)
  move_higher_admin_blog_post_image_path: Utils.route([["blog_post_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[6,"blog_posts",false],[2,[7,"/",false],[2,[3,"blog_post_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_deal_card_image => /admin/widgets/deal_cards/:deal_card_id/images/:id/move_higher(.:format)
  // function(deal_card_id, id, options)
  move_higher_admin_deal_card_image_path: Utils.route([["deal_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"deal_cards",false],[2,[7,"/",false],[2,[3,"deal_card_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_place_image => /admin/geo/places/:place_id/images/:id/move_higher(.:format)
  // function(place_id, id, options)
  move_higher_admin_place_image_path: Utils.route([["place_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"places",false],[2,[7,"/",false],[2,[3,"place_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_property_group_property_booking_data_attachment => /admin/accommodation/property_groups/:property_group_id/booking_datas/:property_booking_data_id/attachments/:id/move_higher(.:format)
  // function(property_group_id, property_booking_data_id, id, options)
  move_higher_admin_property_group_property_booking_data_attachment_path: Utils.route([["property_group_id",true],["property_booking_data_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"property_groups",false],[2,[7,"/",false],[2,[3,"property_group_id",false],[2,[7,"/",false],[2,[6,"booking_datas",false],[2,[7,"/",false],[2,[3,"property_booking_data_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
// move_higher_admin_property_image => /admin/accommodation/properties/:property_id/images/:id/move_higher(.:format)
  // function(property_id, id, options)
  move_higher_admin_property_image_path: Utils.route([["property_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_property_property_booking_data_attachment => /admin/accommodation/properties/:property_id/booking_datas/:property_booking_data_id/attachments/:id/move_higher(.:format)
  // function(property_id, property_booking_data_id, id, options)
  move_higher_admin_property_property_booking_data_attachment_path: Utils.route([["property_id",true],["property_booking_data_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"booking_datas",false],[2,[7,"/",false],[2,[3,"property_booking_data_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
// move_higher_admin_seo_settings_image => /admin/transfers/seo_settings/:seo_settings_id/images/:id/move_higher(.:format)
  // function(seo_settings_id, id, options)
  move_higher_admin_seo_settings_image_path: Utils.route([["seo_settings_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"seo_settings",false],[2,[7,"/",false],[2,[3,"seo_settings_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_ski_area_image => /admin/geo/ski_areas/:ski_area_id/images/:id/move_higher(.:format)
  // function(ski_area_id, id, options)
  move_higher_admin_ski_area_image_path: Utils.route([["ski_area_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"ski_areas",false],[2,[7,"/",false],[2,[3,"ski_area_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_transfer_type_image => /admin/transfers/transfer_types/:transfer_type_id/images/:id/move_higher(.:format)
  // function(transfer_type_id, id, options)
  move_higher_admin_transfer_type_image_path: Utils.route([["transfer_type_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_types",false],[2,[7,"/",false],[2,[3,"transfer_type_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_higher_admin_vehicle_image => /admin/transfers/vehicles/:vehicle_id/images/:id/move_higher(.:format)
  // function(vehicle_id, id, options)
  move_higher_admin_vehicle_image_path: Utils.route([["vehicle_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"vehicles",false],[2,[7,"/",false],[2,[3,"vehicle_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_higher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_blog_post_image => /admin/blog/blog_posts/:blog_post_id/images/:id/move_lower(.:format)
  // function(blog_post_id, id, options)
  move_lower_admin_blog_post_image_path: Utils.route([["blog_post_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[6,"blog_posts",false],[2,[7,"/",false],[2,[3,"blog_post_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_deal_card_image => /admin/widgets/deal_cards/:deal_card_id/images/:id/move_lower(.:format)
  // function(deal_card_id, id, options)
  move_lower_admin_deal_card_image_path: Utils.route([["deal_card_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"widgets",false],[2,[7,"/",false],[2,[6,"deal_cards",false],[2,[7,"/",false],[2,[3,"deal_card_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_place_image => /admin/geo/places/:place_id/images/:id/move_lower(.:format)
  // function(place_id, id, options)
  move_lower_admin_place_image_path: Utils.route([["place_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"places",false],[2,[7,"/",false],[2,[3,"place_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_property_group_property_booking_data_attachment => /admin/accommodation/property_groups/:property_group_id/booking_datas/:property_booking_data_id/attachments/:id/move_lower(.:format)
  // function(property_group_id, property_booking_data_id, id, options)
  move_lower_admin_property_group_property_booking_data_attachment_path: Utils.route([["property_group_id",true],["property_booking_data_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"property_groups",false],[2,[7,"/",false],[2,[3,"property_group_id",false],[2,[7,"/",false],[2,[6,"booking_datas",false],[2,[7,"/",false],[2,[3,"property_booking_data_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
// move_lower_admin_property_image => /admin/accommodation/properties/:property_id/images/:id/move_lower(.:format)
  // function(property_id, id, options)
  move_lower_admin_property_image_path: Utils.route([["property_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_property_property_booking_data_attachment => /admin/accommodation/properties/:property_id/booking_datas/:property_booking_data_id/attachments/:id/move_lower(.:format)
  // function(property_id, property_booking_data_id, id, options)
  move_lower_admin_property_property_booking_data_attachment_path: Utils.route([["property_id",true],["property_booking_data_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"booking_datas",false],[2,[7,"/",false],[2,[3,"property_booking_data_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]]]),
// move_lower_admin_seo_settings_image => /admin/transfers/seo_settings/:seo_settings_id/images/:id/move_lower(.:format)
  // function(seo_settings_id, id, options)
  move_lower_admin_seo_settings_image_path: Utils.route([["seo_settings_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"seo_settings",false],[2,[7,"/",false],[2,[3,"seo_settings_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_ski_area_image => /admin/geo/ski_areas/:ski_area_id/images/:id/move_lower(.:format)
  // function(ski_area_id, id, options)
  move_lower_admin_ski_area_image_path: Utils.route([["ski_area_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"geo",false],[2,[7,"/",false],[2,[6,"ski_areas",false],[2,[7,"/",false],[2,[3,"ski_area_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_transfer_type_image => /admin/transfers/transfer_types/:transfer_type_id/images/:id/move_lower(.:format)
  // function(transfer_type_id, id, options)
  move_lower_admin_transfer_type_image_path: Utils.route([["transfer_type_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_types",false],[2,[7,"/",false],[2,[3,"transfer_type_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// move_lower_admin_vehicle_image => /admin/transfers/vehicles/:vehicle_id/images/:id/move_lower(.:format)
  // function(vehicle_id, id, options)
  move_lower_admin_vehicle_image_path: Utils.route([["vehicle_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"vehicles",false],[2,[7,"/",false],[2,[3,"vehicle_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_lower",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_booking_property => /booking_properties/new(.:format)
  // function(options)
  new_booking_property_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_properties",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_booking_transfer => /booking_transfers/new(.:format)
  // function(options)
  new_booking_transfer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_transfers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_partner_booking => /partner/bookings/new(.:format)
  // function(options)
  new_partner_booking_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// overview_map_marker => /overview_map/markers/:id(.:format)
  // function(id, options)
  overview_map_marker_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"overview_map",false],[2,[7,"/",false],[2,[6,"markers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// overview_map_markers => /overview_map/markers(.:format)
  // function(options)
  overview_map_markers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"overview_map",false],[2,[7,"/",false],[2,[6,"markers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// overview_map_properties => /overview_map/properties(.:format)
  // function(options)
  overview_map_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"overview_map",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// overview_map_property => /overview_map/properties/:id(.:format)
  // function(id, options)
  overview_map_property_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"overview_map",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// page => /*id
  // function(id, options)
  page_path: Utils.route([["id",true]], {}, [2,[7,"/",false],[5,[3,"id",false],false]]),
// partner_booking => /partner/bookings/:id(.:format)
  // function(id, options)
  partner_booking_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_booking_booking_statuses => /partner/bookings/:booking_id/booking_statuses(.:format)
  // function(booking_id, options)
  partner_booking_booking_statuses_path: Utils.route([["booking_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// partner_bookings => /partner/bookings(.:format)
  // function(options)
  partner_bookings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner",false],[2,[7,"/",false],[2,[6,"bookings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// place => /places/:id(.:format)
  // function(id, options)
  place_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"places",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// places => /places(.:format)
  // function(options)
  places_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"places",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// print_meeting_sign_admin_booking_booking_transfer => /admin/booking/bookings/:booking_id/booking_transfers/:id/print_meeting_sign(.:format)
  // function(booking_id, id, options)
  print_meeting_sign_admin_booking_booking_transfer_path: Utils.route([["booking_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"booking_id",false],[2,[7,"/",false],[2,[6,"booking_transfers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"print_meeting_sign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// profile_bookings => /profile/bookings(.:format)
  // function(options)
  profile_bookings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"bookings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// profile_root => /profile(.:format)
  // function(options)
  profile_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// profile_user => /profile/users/:id(.:format)
  // function(id, options)
  profile_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// properties => /properties(.:format)
  // function(options)
  properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// property => /properties/:id(.:format)
  // function(id, options)
  property_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// property_dest_landing => /properties/:dest(.:format)
  // function(dest, options)
  property_dest_landing_path: Utils.route([["dest",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"dest",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// property_list_properties => /properties/property_list(.:format)
  // function(options)
  property_list_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[6,"property_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recalculate_transfer_stats_admin_drivers => /admin/drivers/recalculate_transfer_stats(.:format)
  // function(options)
  recalculate_transfer_stats_admin_drivers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"drivers",false],[2,[7,"/",false],[2,[6,"recalculate_transfer_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// request_confirmation_admin_booking => /admin/booking/bookings/:id/request_confirmation(.:format)
  // function(id, options)
  request_confirmation_admin_booking_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking",false],[2,[7,"/",false],[2,[6,"bookings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"request_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// resend_confirmation_profile_user => /profile/users/:id/resend_confirmation(.:format)
  // function(id, options)
  resend_confirmation_profile_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// resolve_admin_transfer_issue => /admin/transfer_issues/:id/resolve(.:format)
  // function(id, options)
  resolve_admin_transfer_issue_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfer_issues",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resolve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// route_prices_admin_transfer_routes => /admin/transfers/transfer_routes/route_prices(.:format)
  // function(options)
  route_prices_admin_transfer_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_routes",false],[2,[7,"/",false],[2,[6,"route_prices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// routes_list_admin_transfer_routes => /admin/transfers/transfer_routes/routes_list(.:format)
  // function(options)
  routes_list_admin_transfer_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_routes",false],[2,[7,"/",false],[2,[6,"routes_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// save_batch_admin_property_arrival_rules => /admin/accommodation/properties/:property_id/arrival_rules/save_batch(.:format)
  // function(property_id, options)
  save_batch_admin_property_arrival_rules_path: Utils.route([["property_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"arrival_rules",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// save_batch_admin_property_property_prices => /admin/accommodation/properties/:property_id/property_prices/save_batch(.:format)
  // function(property_id, options)
  save_batch_admin_property_property_prices_path: Utils.route([["property_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"property_id",false],[2,[7,"/",false],[2,[6,"property_prices",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// save_batch_admin_room_image_rooms => /admin/accommodation/rooms/:room_id/image_rooms/save_batch(.:format)
  // function(room_id, options)
  save_batch_admin_room_image_rooms_path: Utils.route([["room_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"rooms",false],[2,[7,"/",false],[2,[3,"room_id",false],[2,[7,"/",false],[2,[6,"image_rooms",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// save_batch_admin_room_prices => /admin/accommodation/rooms/:room_id/prices/save_batch(.:format)
  // function(room_id, options)
  save_batch_admin_room_prices_path: Utils.route([["room_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"rooms",false],[2,[7,"/",false],[2,[3,"room_id",false],[2,[7,"/",false],[2,[6,"prices",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// save_batch_admin_transfer_date_price_corrections => /admin/transfers/transfer_date_price_corrections/save_batch(.:format)
  // function(options)
  save_batch_admin_transfer_date_price_corrections_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_date_price_corrections",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// save_batch_admin_transfer_prices => /admin/transfers/transfer_prices/save_batch(.:format)
  // function(options)
  save_batch_admin_transfer_prices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_prices",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// save_batch_admin_transfer_type_transfer_type_vehicles => /admin/transfers/transfer_types/:transfer_type_id/transfer_type_vehicles/save_batch(.:format)
  // function(transfer_type_id, options)
  save_batch_admin_transfer_type_transfer_type_vehicles_path: Utils.route([["transfer_type_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"transfers",false],[2,[7,"/",false],[2,[6,"transfer_types",false],[2,[7,"/",false],[2,[3,"transfer_type_id",false],[2,[7,"/",false],[2,[6,"transfer_type_vehicles",false],[2,[7,"/",false],[2,[6,"save_batch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// search_admin_booking_transfers => /admin/booking_transfers/search(.:format)
  // function(options)
  search_admin_booking_transfers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"booking_transfers",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// search_properties => /properties/s(.:format)
  // function(options)
  search_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[6,"s",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search_transfer_index => /transfer(/:route)(.:format)
  // function(options)
  search_transfer_index_path: Utils.route([["route",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"transfer",false],[2,[1,[2,[7,"/",false],[3,"route",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// ski_area => /ski_areas/:id(.:format)
  // function(id, options)
  ski_area_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ski_areas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ski_areas => /ski_areas(.:format)
  // function(options)
  ski_areas_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ski_areas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sort_admin_room_price_policy_index => /admin/accommodation/room_price_policies/sort(.:format)
  // function(options)
  sort_admin_room_price_policy_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"room_price_policies",false],[2,[7,"/",false],[2,[6,"sort",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sort_admin_rooms => /admin/accommodation/rooms/sort(.:format)
  // function(options)
  sort_admin_rooms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"rooms",false],[2,[7,"/",false],[2,[6,"sort",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// start_booking_properties => /booking_properties/start(.:format)
  // function(options)
  start_booking_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_properties",false],[2,[7,"/",false],[2,[6,"start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// start_booking_transfers => /booking_transfers/start(.:format)
  // function(options)
  start_booking_transfers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_transfers",false],[2,[7,"/",false],[2,[6,"start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_payment_checkout_index => /stripe_payment/checkout(.:format)
  // function(options)
  stripe_payment_checkout_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stripe_payment",false],[2,[7,"/",false],[2,[6,"checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_payment_confirmations => /stripe_payment/confirmations(.:format)
  // function(options)
  stripe_payment_confirmations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"stripe_payment",false],[2,[7,"/",false],[2,[6,"confirmations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// subscribe => /subscribe(.:format)
  // function(options)
  subscribe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// summary_for_driver_booking_transfer => /booking_transfers/:id/summary_for_driver(.:format)
  // function(id, options)
  summary_for_driver_booking_transfer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"booking_transfers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"summary_for_driver",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// test_payment => /test_payment(.:format)
  // function(options)
  test_payment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"test_payment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// transfer_index => /transfer(.:format)
  // function(options)
  transfer_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"transfer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// upload_translations_template_admin_property => /admin/accommodation/properties/:id/upload_translations_template(.:format)
  // function(id, options)
  upload_translations_template_admin_property_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accommodation",false],[2,[7,"/",false],[2,[6,"properties",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"upload_translations_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// write_us => /write_us(.:format)
  // function(options)
  write_us_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"write_us",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "ClientRoutes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

export default routes.ClientRoutes