import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types'
import filter from 'lodash/filter';
import find from 'lodash/find';
import fromPairs from 'lodash/fromPairs';
import sumBy from 'lodash/sumBy';
import moment from 'moment';

import { AppPropTypes, connectRedux } from '../redux';
import { subscribeAnimationFrame } from '../animations';
import { numberToCurrency } from 'javascripts/client/helpers';
import IconSvg from './icon-svg';
import { shortMonthThreeChars } from 'javascripts/common/helpers'

class PropertyInitBooking extends React.Component {

    static propTypes = {
        canStickyTop: PropTypes.bool,
        currency: PropTypes.string,
        property_type: PropTypes.string,
        booking: PropTypes.shape({
            id: PropTypes.string,
            guests: PropTypes.string,
            date_in: PropTypes.string,
            date_out: PropTypes.string,
        }),
        room_variants: PropTypes.arrayOf(AppPropTypes.roomVariant),
        room_cart: PropTypes.arrayOf(AppPropTypes.roomCartItem),
    };


  constructor(props) {
    super(props);
    this.state = {
      position: null,
      countRooms: sumBy(props.room_cart, 'amount'),
    }

  }

  componentDidMount() {
    this.$holdAnchor = $('.js-init-booking-hold');
    this.$container = $(this.topContainer).closest('.property-view__init-booking');
    this.$line = $(this.topContainer).closest('.property-view__init-booking-line');
    this.$holderLine = $(`<div class="property-view__init-booking-line property-view__init-booking-line_holder" />`);
    this.$holderLine.insertAfter(this.$line);
    subscribeAnimationFrame(
      $(window),
      this.prepareFrame.bind(this),
      this.doAnimation.bind(this),
      this.propsForAnimation.bind(this),
    );
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  componentWillReceiveProps(nextProps) {
    const countRooms = sumBy(nextProps.room_cart, 'amount');
    if (countRooms !== this.state.countRooms) {
      this.setState({ countRooms });
    }
  }

  prepareFrame(animationProps, prevAnimationProps) {
        const lineHeightChanged = animationProps.lineHeight !== prevAnimationProps.lineHeight;
        if (lineHeightChanged) {
            this.$holderLine.css({ height: animationProps.lineHeight });
        }
        const position = this.getContainerPosition(animationProps);
        if (animationProps.modalScrollContainer && (
            !prevAnimationProps.modalScrollContainer ||
            lineHeightChanged ||
            animationProps.sticky !== prevAnimationProps.sticky
            )) {
                $(animationProps.modalScrollContainer).css({
                paddingBottom: animationProps.sticky && position ? animationProps.lineHeight : '',
            });
        }
        if (position === this.position) {
            return;
        }
        if (this.position) {
            if (this.position === 'hold') {
                this.$line.css({ top: '' });
            }
            this.$line.removeClass(`property-view__init-booking-line_${this.position}`);
        }
        this.position = position;
        if (this.position) {
            this.$line.addClass(`property-view__init-booking-line_${this.position}`);
            if (this.position === 'hold') {
                this.$line.css({ top: this.topPosition(this.$holdAnchor) });
            }
        }
    }

  getContainerPosition({ scrollTop, height, sticky, modalScrollContainer, lineHeight }) {
    if (global.layoutLocked) {
      scrollTop = global.lockedScrollTop;
    }
    if (sticky) {
      const lineTop = this.topPosition(this.$container);
      if (modalScrollContainer) {
        return 'bottom';
      } else if (this.props.canStickyTop && scrollTop > lineTop) {
        return 'top';
      } else if (scrollTop + height - lineHeight < this.topPosition(this.$holdAnchor)) {
        return 'hold';
      } else if (scrollTop + height < lineTop + lineHeight) {
        return 'bottom';
      }
    }
    return null;
  }

  topPosition($node) {
    return $node.position().top + parseInt($node.css('marginTop') || 0);
  }

  doAnimation() {
    return !this.unmounted;
  }

  propsForAnimation() {
    const sticky = this.state.countRooms > 0;
    const modalScrollContainer = $('.js-room-modal-scroll-container')[0];
    return {
      sticky,
      modalScrollContainer,
      lineHeight: (sticky || modalScrollContainer) && this.$line.outerHeight(),
    };
  }

  totalPrice() {
    return sumBy(this.props.room_cart, ({ id, amount }) => (
      amount * find(this.props.room_variants, { policy_id: id }).total_price
    )).toFixed(2);
  }

  render() {
    const selectedPolicies = filter(this.props.room_cart, ({ amount }) => amount > 0);
    const roomsCount = sumBy(selectedPolicies, 'amount');
    const nights = Math.round(
      (Date.parse(this.props.booking.date_out) - Date.parse(this.props.booking.date_in)) / (60 * 60 * 24 * 1000)
    );
    const paramName = 'booking_properties';

    return (
      <div className="ga-container" ref={c => this.topContainer = c}>
        <div className="property-view__init-booking-row">
          <div className="property-view__init-booking-header">
            {`${I18n.t('fe.client.property_init_booking.your_choice')}:`}
          </div>
          <div className="property-view__init-booking-info">
            <div className="property-view__init-booking-rooms">
                {`${roomsCount} `}
                {I18n.t(`activerecord.attributes.property.property_types.${this.props.property_type}.room`, {
                    count: roomsCount
                })}
            </div>
            <span className="nowrap">
                {shortMonthThreeChars(moment(this.props.booking.date_in).format('DD MMM YYYY'))}
                <IconSvg icon="arrow-right" className="property-view__init-booking-info-icon" />
                {shortMonthThreeChars(moment(this.props.booking.date_out).format('DD MMM YYYY'))}
                &nbsp;({I18n.t('defaults.dictionary.nights', { count: nights })})
            </span>
          </div>
          <div className="property-view__init-booking-price">
            {numberToCurrency(this.totalPrice(), { precision: 0, unit: this.props.currency })}
            {this.totalPrice() > 0 && (
                <div className="property-view__init-booking-price-hint">
                    {I18n.t('fe.client.property_init_booking.price_hint')}
                </div>
            )}
          </div>
          <div className="property-view__init-booking-submit">
            {Object.keys(this.props.booking).map(fieldName =>
              <input
                key={fieldName}
                type="hidden"
                name={`${paramName}[${fieldName}]`}
                value={this.props.booking[fieldName]}
              />
            )}
            <input
              type="hidden"
              name={`${paramName}[policies]`}
              value={JSON.stringify(fromPairs(selectedPolicies.map(({ id, amount }) => [id, amount])))}
            />
            <button
              className="button property-view__init-booking-submit-button"
              type="submit"
              disabled={selectedPolicies.length === 0}
            >
              {I18n.t('fe.client.searchbox.property_price.book_action')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}




export default connectRedux(PropertyInitBooking, ['room_variants', 'room_cart']);
