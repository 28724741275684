import { combineReducers } from 'redux'
import {
  DEFAULT_SEARCHBOX_PARAMS_STATE,
  DEFAULT_TRANSFER_PARAMS_STATE,
  DEFAULT_ACCOMMODATION_PARAMS_STATE,
  UPDATE_SEARCHBOX_PARAMS
} from 'javascripts/client/constants/searchboxConstants';

/* Initial Accommodation state */
function parseAccommodationState(state) {
    const defaultParams = DEFAULT_ACCOMMODATION_PARAMS_STATE
    const stateParams = state || {}
    
    let guests = stateParams.guests

    if(typeof guests === "string") {
        try { guests = JSON.parse(guests) }
        catch(e) { guests = defaultParams.guests }
    }

    return  {
        dest: stateParams.dest || defaultParams.dest,
        dateIn: stateParams.dateIn ? new Date(stateParams.dateIn) : defaultParams.dateIn,
        dateOut: stateParams.dateOut ? new Date(stateParams.dateOut) : defaultParams.dateOut,
        guests: guests || defaultParams.guests
    }
}

/* Initial Transfers state */
function parseTransferState(state) {
    const defaultParams = DEFAULT_TRANSFER_PARAMS_STATE
    const stateParams = state || {}
    
    return {
        fromLocation: stateParams.fromLocation || defaultParams.fromLocation,
        toLocation: stateParams.toLocation || defaultParams.toLocation,
        date: stateParams.date ? new Date(stateParams.date) : defaultParams.date,
        dateReturn: stateParams.dateReturn ? new Date(stateParams.dateReturn) : defaultParams.dateReturn,
        pax: stateParams.pax || defaultParams.pax,
        oneWay: stateParams.oneWay || defaultParams.oneWay
    }
}



function searchbox(state = DEFAULT_SEARCHBOX_PARAMS_STATE, { type, payload }) {
    switch (type) {
        case UPDATE_SEARCHBOX_PARAMS:
            // payload - Object { formName: formParams }
            const params = Object.assign({}, state.params, payload)
            return Object.assign({}, state, { params })
        default:
            return {
                ...state,
                params: {
                    accommodation: parseAccommodationState(state.params.accommodation),
                    transfer: parseTransferState(state.params.transfer)
                }
            }
    }
}

export default searchbox
