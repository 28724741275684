import React from 'react';
import ModalLayout from './modal-layout';
import ImagesGallery from './images-gallery';
import tippy from 'tippy.js'
import queryString from 'query-string';
import omit from 'lodash/omit';

function openModalOnPageLoad() {
  const { room_modal: roomId } = queryString.parse(window.location.search);
  if (roomId) {
    const $openModalLink = $(`#room_${roomId}`).find('.js-open-room-modal');
    if ($openModalLink.length) {
      $openModalLink.trigger('click');
    }
  }
}

function replaceUrl(roomId) {
  const params = queryString.parse(window.location.search, {arrayFormat: 'bracket'});
  let newParams = null;
  if (roomId) {
    newParams = Object.assign({}, params, { room_modal: roomId });
  } else {
    newParams = omit(params, "room_modal");
  }
  window.history.replaceState(
    null,
    "",
    `${window.location.pathname}?${queryString.stringify(newParams, {arrayFormat: 'bracket'})}`
  );
}

export default class RoomModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoHtml: '',
      images: [],
      initialSlide: 0,
    };
    this.modalLayout = null;
  }

    componentDidMount() {
        $('.js-open-room-modal').click((e) => {
          this.$trigger = $(e.currentTarget);
          const $roomItem = this.$trigger.closest('.js-room-item');
          const $info = $roomItem.find('.js-room-info-container').clone();
          $info.find('.js-open-room-modal').removeClass('js-open-room-modal');
          $('.js-rooms-header-policies').clone().prependTo($info.find('.js-room-policies-container'));
          this.setState({ infoHtml: $info.html(), images: $roomItem.data('images'), initialSlide: 0 }, () => {
            this.modalLayout.openModal();
          });
          return false;
        });

        openModalOnPageLoad();
    }

    handleOpen = (node) => {
        // init tooltips
        const tooltips = node.querySelectorAll('[data-tippy]')
        tooltips.forEach((el) => {
            el.setAttribute('title', el.getAttribute('data-original-title'))
            tippy(el, { appendTo: node })
        })
        // mount react components
        ReactRailsUJS.mountComponents(node)
        // scroll to roomdescription
        const scrollTo = this.$trigger.data('scrollTo')
        if (scrollTo) {
            setTimeout(() => {
                this.modalLayout.scrollTo(scrollTo, 400, { offset: { top: -40 }});
            }, 100);
        }

        // replace url
        const roomItemId = this.$trigger.closest('.js-room-item').attr('id');
        if (roomItemId) {
          const [_, roomId] = roomItemId.split("_");          
          replaceUrl(roomId);
        }
    };
  
    handleClose = () => {
      replaceUrl(null);
    };

  render() {
    return (
      <ModalLayout
        ref={c => this.modalLayout = c}
        scrollContainerClass="js-room-modal-scroll-container"
        withoutContainer
        closeIcon={true}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <ImagesGallery
          className="images-gallery_white-bg"
          images={this.state.images}
          initialSlide={this.state.initialSlide}
          slidesPerViewByBreakpoint={{
            small: 4,
            medium: 4,
            //xmedium: 5,
            large: 8,
            xlarge: 6,
            xxlarge: 6,
          }}
        />
        <div className="ga-container">
          <div className="images-gallery__container">
            <div className="room-variant-in-modal">
              <div
                className="room-variant-in-modal__container"
                dangerouslySetInnerHTML={{ __html: this.state.infoHtml }}
              />
            </div>
          </div>
        </div>
      </ModalLayout>
    );
  }
}
