import React, { Component } from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types';
import classNames from "classnames";


export function wrapFilterInDropdown(FilterComponent, wrapperProps) {
    const WrappedComponent = class extends Component {
        static propTypes = {
            isFilterChanged: PropTypes.bool,
            onApplyFilter: PropTypes.func.isRequired
        }

        constructor(props) {
            super(props);
            this.handleApplyClick = this.handleApplyClick.bind(this);
        }

        handleApplyClick(event) {
            this.props.onApplyFilter(event);
        }

        render() {
            const { isFilterChanged } = this.props;
            const applyBtnClass = classNames('button button_link');
            return (
                <div {...wrapperProps}>
                    <FilterComponent {...this.props} />
                    <div className="dropdown-pane__actions-footer dropdown-pane__actions-footer_one-action dropdown-pane__actions-footer_spaced">
                        <button className={applyBtnClass} onClick={this.handleApplyClick}>
                            {I18n.t('defaults.actions.apply')}
                        </button>
                    </div>
                </div>
            );
        }
    }

    return WrappedComponent
}
