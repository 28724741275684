import {
  DEFAULT_FILTER_STATE,
  UPDATE_PROPERTIES_FILTER
} from '../../constants/propertiesConstants';


const defaultState = Object.assign({}, DEFAULT_FILTER_STATE, {startPrice: 0, endPrice: 0});

export default function(state = defaultState, { type, payload }) {
  switch (type) {
    case UPDATE_PROPERTIES_FILTER:
      return Object.assign({}, state, payload);
    default:
      return state
  }
}