import queryString from 'query-string';
import omit from 'lodash/omit';

import {
  LOAD_PROPERTIES_OPTIONS_REQUEST,
  LOAD_PROPERTIES_OPTIONS_SUCCESS
} from '../../constants/propertiesConstants';
import { updateFilter } from "./filterActions";
import { updateAppliedFilter } from "./appliedFilterActions";


function loadOptionsRequest() {
  return {
    type: LOAD_PROPERTIES_OPTIONS_REQUEST
  }
}

function loadOptionsSuccess(values) {
  return {
    type: LOAD_PROPERTIES_OPTIONS_SUCCESS,
    payload: values
  }
}

function queryParamsWithout(excludeParams) {
  const queryParams = queryString.parse(window.location.search, {arrayFormat: 'bracket'});
  return queryString.stringify(omit(queryParams, excludeParams), {arrayFormat: 'bracket'});
}

export function loadOptions() {
  return function(dispatch, getState) {
    dispatch(loadOptionsRequest());

    const url = `/properties/filters?${queryParamsWithout(['price_start', 'price_end'])}`;
    return $.getJSON(url, (response) => {
      const values = {
        propertyTypes: response.property_types,
        mealTypes: response.meal_types,
        stars: response.stars,
        facilities: response.facilities,
        minPrice: response.price_start,
        maxPrice: response.price_end
      };

      const { properties: { filter } } = getState();
      const startPrice = Math.max((filter.startPrice || 0), values.minPrice);
      const endPrice = Math.min((filter.endPrice || Infinity), values.maxPrice);

      dispatch(updateFilter({ startPrice, endPrice }));
      dispatch(updateAppliedFilter({ startPrice, endPrice }));
      dispatch(loadOptionsSuccess(values));
    });
  }
}
