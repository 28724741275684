import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import classNames from 'classnames';
import PropertyTypeFilter from './property-type-filter';
import PriceFilter from './price-filter';
import MealTypeFilter from './meal-type-filter';
import StarsFilter from './stars-filter';
import FacilitiesFilter from './facilities-filter';
import OrderSelector from './order-selector';
import IconSvg from './../icon-svg';

export default class PropertiesAllFilters extends React.Component {
    state = {
        filtersBottomFixed: false
    }

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.setFiltersBottomFixed, true)        
        this.setFiltersBottomFixed()        
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setFiltersBottomFixed, true)        
    }

    setFiltersBottomFixed = () => {
        let filtersBottomFixed = false
        if(this.filtersTop.clientHeight < this.filtersTop.scrollHeight) {
            filtersBottomFixed = true
        }
        this.setState({ filtersBottomFixed })
    }

    handleClickApply = () => {
        const { isFilterChanged, onApplyFilter, onCloseFilter } = this.props;
        if (isFilterChanged) {
            onApplyFilter();
        }
    }

    handleClickReset = (e) => {
        const { isFilterResetable, onResetFilter, onCloseFilter } = this.props;
        onResetFilter();
    }

    handleOrderChange = (orderBy, orderDir) => {
        this.props.onChangeFilter({ orderBy, orderDir })
    }

    render() {
        const { filter, onChangeFilter, options, isFilterChanged, isFilterResetable, userCurrency } = this.props;
        return (
            <React.Fragment>
                <div className="proplist-controls__all-filters-top" ref={(el) => this.filtersTop = el}>
                    <div className="ga-container">
                        <div className="proplist-controls__all-filters-header-line">
                            <div className="proplist-controls__all-filters-header-wrap">
                                <div className="proplist-controls__all-filters-title">
                                    {I18n.t('fe.client.properties_filters.all_filters_header')}
                                </div>
                                <div className="proplist-controls__all-filters-header">
                                    {this.props.dest} 
                                </div>
                            </div>
                            <button className="proplist-controls__all-filters-close modal-close" onClick={this.props.onCloseFilter}>
                                <IconSvg icon="close" />
                            </button>  
                        </div>
                        <div className="proplist-controls__all-filters-line">
                            <div className="proplist-controls__all-filters-types-wrap">
                                <div className="proplist-controls__all-filters-title">
                                    {I18n.t('fe.client.properties_filters.property_type.title')}
                                </div>
                                <PropertyTypeFilter
                                    propertyTypes={options.propertyTypes}
                                    selectedPropertyTypes={filter.propertyTypes}
                                    onChangeFilter={onChangeFilter} />
                            </div>

                            {options.mealTypes.length > 0 &&
                                <div className="proplist-controls__all-filters-meal-wrap">
                                    <div className="proplist-controls__all-filters-title">
                                      {I18n.t('fe.client.properties_filters.meal_type.title')}
                                    </div>
                                    <MealTypeFilter
                                        mealTypes={options.mealTypes}
                                        selectedMealTypes={filter.mealTypes}
                                        onChangeFilter={onChangeFilter} />
                                </div>
                            }

                            <div className="proplist-controls__all-filters-stars-wrap">
                                <div className="proplist-controls__all-filters-title">
                                  {I18n.t('fe.client.properties_filters.stars.title')}
                                </div>
                                <div className="proplist-controls__all-filters-stars">
                                    <StarsFilter stars={options.stars}
                                            selectedStars={filter.stars}
                                            onChangeFilter={onChangeFilter} />
                                </div>
                            </div>

                            <div className="proplist-controls__all-filters-price-wrap">
                                <div className="proplist-controls__all-filters-title">
                                  {I18n.t('fe.client.properties_filters.price.title')}, {userCurrency}
                                </div>
                                <div className="proplist-controls__all-filters-price">
                                    <PriceFilter
                                        minPrice={options.minPrice}
                                        maxPrice={options.maxPrice}
                                        startPrice={filter.startPrice}
                                        endPrice={filter.endPrice}
                                        onChangeFilter={onChangeFilter} />
                                </div>
                            </div>
                        </div>

                        {options.facilities.length > 0 &&
                        <div className="proplist-controls__all-filters-line">
                            <div className="proplist-controls__all-filters-facilities-wrap">
                                <div className="proplist-controls__all-filters-title">
                                  {I18n.t('fe.client.properties_filters.facilities.title')}
                                </div>
                                <FacilitiesFilter
                                    facilities={options.facilities}
                                    selectedFacilities={filter.facilities}
                                    onChangeFilter={onChangeFilter}/>
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <div className={"proplist-controls__all-filters-bottom proplist-controls__all-filters-bottom_fixed_" + this.state.filtersBottomFixed}>
                    <div className="ga-container">
                        <div className="proplist-controls__all-filters-bottom-inner">
                            <div className="proplist-controls__all-filters-order-wrap">
                                <div className="proplist-controls__all-filters-title proplist-controls__all-filters-title_order">
                                    {I18n.t('fe.client.properties_filters.order.title')}
                                </div>
                                <OrderSelector
                                    className="proplist-controls__order-selector"
                                    orderBy={ filter.orderBy }
                                    orderDir={ filter.orderDir }
                                    minPrice={ options.minPrice }
                                    maxPrice={ options.maxPrice }                
                                    onChange={ this.handleOrderChange } 
                                />
                            </div>

                            {isFilterResetable &&
                            <div className="proplist-controls__all-filters-clear-wrap">
                                <div 
                                    className="button button_hollow proplist-controls__all-filters-clear-button"
                                    onClick={this.handleClickReset}
                                >
                                        {I18n.t('fe.client.properties_filters.reset')}
                                </div>
                            </div>
                            }
                            
                            <div className="proplist-controls__all-filters-apply-wrap">
                                {isFilterChanged ?
                                    <div
                                        className={classNames('button', { button_disabled: !isFilterChanged })}
                                        onClick={this.handleClickApply}
                                    >
                                        {I18n.t('defaults.actions.apply')}
                                    </div>
                                :
                                    <div
                                        className={classNames('button button_secondary')}
                                        onClick={this.props.onCloseFilter}
                                    >
                                        {I18n.t('defaults.actions.close')}
                                    </div>                                    
                                }
                            </div>
                        </div>
                    </div>
                </div>            
            </React.Fragment>
        );
    }
}
