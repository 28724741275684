import {
  DEFAULT_SEARCHBOX_PARAMS_STATE,
  UPDATE_SEARCHBOX_PARAMS
} from 'javascripts/client/constants/searchboxConstants';


export function updateSearchboxParams(values) {
  return {
    type: UPDATE_SEARCHBOX_PARAMS,
    payload: values
  }
}
