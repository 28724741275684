import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from "recompose";
import {withScriptjs, withGoogleMap, GoogleMap} from "react-google-maps";

import { googleMapApiKey } from "../../config/environment.js.erb";
import OverviewMapMarker from './overview-map-marker';
import { mapStyles, markerShape, coordinatesShape } from '../../constants/overviewMapConstants';


const DEFAULT_CENTER = {lat: 45.299051, lng: 6.585445};
const DEFAULT_ZOOM = 15;

class OverviewMap extends Component {

  getDefaultCenter = () => {
    const { markers, initialCenter } = this.props;
    let center = DEFAULT_CENTER;

    if (initialCenter) {
      center = initialCenter;
    } else if (markers[0]) {
      center = markers[0]
    }

    return center;
  };

  getZoom = () => {
    const { initialZoom } = this.props;
    return initialZoom || DEFAULT_ZOOM;
  };

  render() {
    const { markers, markerInfos, activeMarker, onActivateMarker, onDeactivateMarker } = this.props;
    const options = {
      styles: mapStyles,
      zoomControl: true,
      streetView: true,
      scrollwheel: false,
      clickableIcons: false
    };

    return (
      <GoogleMap options={options}
                 defaultZoom={this.getZoom()}
                 center={this.getDefaultCenter()}>
        {
          markers.map((marker) => <OverviewMapMarker key={`${marker.type}-${marker.id}`}
                                                     marker={marker}
                                                     info={markerInfos[marker.full_id]}
                                                     isActive={marker.full_id === (activeMarker || {}).full_id}
                                                     onActivate={onActivateMarker}
                                                     onDeactivate={onDeactivateMarker} />
          )
        }
      </GoogleMap>
    );
  }

}

OverviewMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape(markerShape)).isRequired,
  markerInfos: PropTypes.object.isRequired,
  activeMarker: PropTypes.shape(markerShape),
  initialCenter: PropTypes.shape(coordinatesShape),
  initialZoom: PropTypes.number,
  onActivateMarker: PropTypes.func.isRequired,
  onDeactivateMarker: PropTypes.func.isRequired,
};

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.35&key=${googleMapApiKey}`,
    loadingElement: <div style={{height: '100%'}}/>,
    containerElement: <div className="overview-map__container"/>,
    mapElement: <div style={{height: '100%'}}/>,
  }),
  withScriptjs,
  withGoogleMap
)(OverviewMap);