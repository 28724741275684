import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import freakReducers from './reducers';
import regularReducers from '../reducers';

let store = null;


function getInitialState() {
    const reduxStoreNode = document.getElementById('redux_store');
    return reduxStoreNode && Object.assign(
        { rehydratedStore: true },
        JSON.parse(reduxStoreNode.getAttribute('data-redux-store')),
    ) || {};
}

// Export function for delayed store initialization, because body can include #redux_store
function getStore() {
  if (store) {
    return store;
  }

  const initialState = getInitialState();
  const { environment: { name: environmentName } } = initialState;

  const allReducers = Object.assign({}, freakReducers, regularReducers);
  const combinedReducers = combineReducers(allReducers);

  const enhancers = [];

  if (process.env.NODE_ENV === 'development' &&
    window.devToolsExtension) {
    enhancers.push(window.devToolsExtension())
  }

  const middleware = [ thunkMiddleware ];
  if (environmentName !== 'production') {
    middleware.push(logger);
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  store = createStore(
    combinedReducers,
    initialState,
    composedEnhancers
  );

  return store;
}


// Use it in torbolinks:load event.
export function clearStore() {
    store = null
}

export default getStore;
