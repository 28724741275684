import {
  LOAD_PROPERTIES_COUNT_REQUEST,
  LOAD_PROPERTIES_COUNT_SUCCESS
} from "../../constants/propertiesConstants";


function loadCountRequest() {
  return {
    type: LOAD_PROPERTIES_COUNT_REQUEST
  }
}

function loadCountSuccess(count) {
  return {
    type: LOAD_PROPERTIES_COUNT_SUCCESS,
    payload: count
  }
}

export function loadPropertiesCount(queryPath = '') {
  return function(dispatch) {
    dispatch(loadCountRequest());

    let url = `/properties/count${window.location.search}`;
    if (typeof queryPath === 'string' && queryPath !== '') {
      url = queryPath;
    }
    
    fetch(url)
    .then(response => {
        if (response.ok) {
            return response.json()
        }
        throw new Error();
    })
    .then(response => {
        return dispatch(loadCountSuccess(response.count))
    })
    .catch(err => {
        console.log(err);
    })

  }
}
