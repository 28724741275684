import {
  CLOSE_OVERVIEW_MAP_MODAL,
  OPEN_OVERVIEW_MAP_MODAL
} from "../../constants/overviewMapConstants";

const DEFAULT_STATE = { opened: false };

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case OPEN_OVERVIEW_MAP_MODAL:
      return Object.assign({}, state, { opened: true });
    case CLOSE_OVERVIEW_MAP_MODAL:
      return Object.assign({}, state, { opened: false });
    default:
      return state;
  }
}