import moment from 'moment'
import React, { Component } from 'react'
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types'
import ModalLayout from './modal-layout';
import IconSvg from './icon-svg';
import BookingPropertyGuestsForm from "./booking-property-guests-form";
import { authHeaders } from '../helpers';
import classNames from 'classnames'


export default class BookingPropertyGuestsModal extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.guests.length > 0 ) {
      this.state = {
        guests: this.props.guests.map((guest) => ({
          id: guest.id,
          surname: guest.surname || '',
          name: guest.name || '',
          birthday: moment(guest.birthday).format('DD.MM.YYYY') || '',
          passport: guest.passport || '',
          passport_valid_till: moment(guest.passport_valid_till).format('DD.MM.YYYY') || ''
        }) ),
      };
    } else {
      this.state = {
        guests: [this.newGuest()],
      };
    }

    this.state.submitButtonEnabled = true;
    this.modalLayout = null;
  }

  isPropertyFull = () => {
    return this.state.guests.length >= this.props.maxGuestsCount
  }

  submitGuests = () => {
    const data = new FormData();
    if (this.state.submitButtonEnabled) {
      this.setState({submitButtonEnabled: false});
      this.state.guests.forEach(function (guest, index) {
        data.append(`guests[${index}][surname]`, guest.surname);
        data.append(`guests[${index}][name]`, guest.name);
        data.append(`guests[${index}][birthday]`, guest.birthday);
        data.append(`guests[${index}][passport]`, guest.passport);
        data.append(`guests[${index}][passport_valid_till]`, guest.passport_valid_till);
      });
      fetch(this.props.guestsPath, {
        body: data,
        method: 'PATCH',
        headers: authHeaders(),
        credentials: 'same-origin',
      })
        .then(response => {
          if (response.ok) {
            this.setState({ submitButtonEnabled: true });
            this.modalLayout.closeModal();
          }
          else { this.setState({errors: 'Error: '+response.statusText}); }
        })
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.opened) {
      this.modalLayout.openModal();
    }
    return null;
  }

  closeModal = () => {
    this.modalLayout.closeModal();
  }

  newGuest = () => {
    return {
      id: new Date().getTime(),
      surname: '',
      name: '',
      birthday: '',
      passport: '',
      passport_valid_till: ''
    }
  }

  addGuest = () => {
    if (!this.isPropertyFull()) {
      this.setState({
        guests: this.state.guests.concat(this.newGuest())
      })
    }
  }

  removeGuest = (index) => {
    let guests = this.state.guests;
    guests.splice(index, 1);
    this.setState({guests: guests});
  }

  getFormData = (guestIndex, guest) => {
    const guests = this.state.guests;
    guests[guestIndex] = guest;
    this.setState({guests: guests});
  }

  render() {
    const { guests } = this.state;
    return (
        <ModalLayout
            ref={c => this.modalLayout = c}
            scrollContainerClass="js-booking-property-guests-container"
            onOpen={this.handleOpen}
            header={I18n.t('fe.client.booking_property_guest.title')}
            closeIcon={false}
        >
            <div className='booking-guests-modal'>
                <form className='booking-guests-modal__form'>
                    {guests.map((guest, index) => (
                        <BookingPropertyGuestsForm
                            key={guest.id}
                            guest={guest}
                            guestIndex={index}
                            getFormData = {this.getFormData}
                            removeGuest={this.removeGuest}
                            svgsprite={this.props.svgsprite}
                        />
                    ))}

                    <div className='booking-guests-modal__form-line'>
                        <div className='booking-guests-modal__field booking-guests-modal__field_add-guest'>
                            {!this.isPropertyFull() && (
                                <div
                                    className="button button_hollow button_expanded"
                                    onClick={this.addGuest}>
                                        {I18n.t('fe.client.booking_property_guest.actions.add_guest')}
                                </div>
                            )}
                        </div>
                        <div className='booking-guests-modal__field'>
                            <div 
                                className={ classNames('button button_expanded', { 'button_disabled': !this.state.submitButtonEnabled}) }
                                onClick={this.submitGuests}>{I18n.t('fe.client.booking_property_guest.actions.save_guests')}
                            </div>
                        </div>
                        <div className='booking-guests-modal__errors'>
                            {this.state.errors}
                        </div>
                    </div>
                </form>
            </div>
        </ModalLayout>
    )
  }
}

BookingPropertyGuestsModal.propTypes = {
  guests: PropTypes.array,
  maxGuestsCount: PropTypes.number,
  guestsPath: PropTypes.string.isRequired,
  svgsprite: PropTypes.string.isRequired
};
