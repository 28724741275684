import React, { Component } from 'react';
import I18n from 'v2/client/javascripts/i18n'
import IconSvg from './icon-svg';
import ModalLayout from './modal-layout';


class BookingPaymentConfirmationModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.modalLayout.openModal();
  }

  handleClick = () => {
    this.modalLayout.closeModal();
  }

  render() {
    let icon, title, line, line2, modifier
    if(this.props.status == 'OK') {
        modifier = 'success'
        icon = 'check-circle'
        title = I18n.t('fe.client.booking_payment_confirmation_modal.success.title')
        line = I18n.t('fe.client.booking_payment_confirmation_modal.success.line', { name: this.props.name })
    } else {
        modifier = 'alert'
        icon = 'warning-circle'
        title = I18n.t('fe.client.booking_payment_confirmation_modal.fail.title')
        line = I18n.t('fe.client.booking_payment_confirmation_modal.fail.line', { name: this.props.name })
        line2 = I18n.t('fe.client.booking_payment_confirmation_modal.fail.line2')
    }

    return (
        <ModalLayout ref={(m) => this.modalLayout = m} hideHeader={true}>
            <div className="text-center">
                <div className={ `modal-layout__header-icon modal-layout__header_${modifier}` }><IconSvg icon={icon} /></div>
                <h1 className={ `modal-layout__header modal-layout__header_${modifier}` }>{title}</h1>
                <p className="modal-layout__text-block">
                    <strong>{line}</strong>
                </p>
                {line2 && (
                    <p className="modal-layout__text-block">
                        {line2}
                    </p>
                )}
                <div className='modal-layout__actions'>
                    <button className='button' onClick={this.handleClick}>
                        {I18n.t('fe.client.booking_created_modal.ok')}
                    </button>
                </div>
            </div>
        </ModalLayout>
    )
  }
}

export default BookingPaymentConfirmationModal
