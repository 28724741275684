export const DEFAULT_FILTER_STATE = {
  propertyTypes: [],
  mealTypes: [],
  stars: [],
  facilities: [],
  orderBy: 'rank',
  orderDir: 'asc'
};

export const UPDATE_PROPERTIES_FILTER = 'UPDATE_PROPERTIES_FILTER';
export const UPDATE_PROPERTIES_APPLIED_FILTER = 'UPDATE_PROPERTIES_APPLIED_FILTER';

export const LOAD_PROPERTIES_OPTIONS_REQUEST = 'LOAD_PROPERTIES_OPTIONS_REQUEST';
export const LOAD_PROPERTIES_OPTIONS_SUCCESS = 'LOAD_PROPERTIES_OPTIONS_SUCCESS';

export const LOAD_PROPERTIES_COUNT_REQUEST = 'LOAD_PROPERTIES_COUNT_REQUEST';
export const LOAD_PROPERTIES_COUNT_SUCCESS = 'LOAD_PROPERTIES_COUNT_SUCCESS';
