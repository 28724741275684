import { combineReducers } from 'redux'

import appliedFilter from './appliedFilterReducer'
import filter from './filterReducer'
import options from './optionsReducer'
import count from './countReducer'


export default combineReducers({
  appliedFilter,
  filter,
  options,
  count
})