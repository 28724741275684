import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* JAVASCRIPT */
import 'javascripts/client';

/* CSS */
import 'stylesheets/client/client-app.scss';

/* IMAGES */
const images = require.context('images', true);
const imagePath = (name) => images(name, true);

/* SVG ICONS */
const importAll = (r) => { r.keys().map(r); };
importAll(require.context('./../icons/', false, /\.svg$/));

// App v2 !!!
import 'v2/client';
