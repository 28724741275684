import queryString from "query-string";

import {
  ACTIVATE_OVERVIEW_MAP_MARKER, APPEND_OVERVIEW_MAP_MARKERS_SUCCESS,
  DEACTIVATE_OVERVIEW_MAP_MARKER,
  LOAD_OVERVIEW_MAP_MARKER_INFO_REQUEST,
  LOAD_OVERVIEW_MAP_MARKER_INFO_SUCCESS,
  LOAD_OVERVIEW_MAP_MARKERS_REQUEST,
  LOAD_OVERVIEW_MAP_MARKERS_SUCCESS, RESET_OVERVIEW_MAP_MARKERS
} from "../../constants/overviewMapConstants";
import RailsRoutes from "v2/client/javascripts/rails-routes.js.erb";


function loadMarkersRequest() {
  return {
    type: LOAD_OVERVIEW_MAP_MARKERS_REQUEST
  }
}

function loadMarkersSuccess(markers) {
  return {
    type: LOAD_OVERVIEW_MAP_MARKERS_SUCCESS,
    payload: markers
  }
}

function appendMarkersSuccess(markers) {
  return {
    type: APPEND_OVERVIEW_MAP_MARKERS_SUCCESS,
    payload: markers
  }
}

function loadMarkerInfoRequest() {
  return {
    type: LOAD_OVERVIEW_MAP_MARKER_INFO_REQUEST
  }
}

function loadMarkerInfoSuccess(marker) {
  return {
    type: LOAD_OVERVIEW_MAP_MARKER_INFO_SUCCESS,
    payload: marker
  }
}

export function resetMarkers() {
  return {
    type: RESET_OVERVIEW_MAP_MARKERS
  }
}

export function loadMarkers() {
  return function (dispatch) {
    dispatch(loadMarkersRequest());

    fetch(RailsRoutes.overview_map_markers_path({format: "json"}))
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error();
      })
      .then(response => {
        return dispatch(loadMarkersSuccess(response.markers));
      })
      .catch(err => {
        console.log(err);
      })
  }
}

export function loadMarkerInfo({id, type}) {
  return function (dispatch) {
    dispatch(loadMarkerInfoRequest());

    fetch(RailsRoutes.overview_map_marker_path({ id: id }, { type: type, format: "json" }))
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error();
      })
      .then(response => {
        return dispatch(loadMarkerInfoSuccess(response.marker));
      })
      .catch(err => {
        console.log(err);
      })
  }
}

export function loadProperties(page = 1) {
  return function (dispatch) {
    dispatch(loadMarkersRequest());

    const queryParams = queryString.parse(window.location.search, {arrayFormat: 'bracket'});
    fetch(RailsRoutes.overview_map_properties_path(Object.assign({}, queryParams, { format: "json", page: page })))
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error();
      })
      .then(response => {
        dispatch(appendMarkersSuccess(response.markers));
        if (response.meta.total_pages > page) {
          dispatch(loadProperties(page + 1));
        }
      })
      .catch(err => {
        console.log(err);
      })
  }
}

export function loadPropertyInfo({id}) {
  return function (dispatch) {
    dispatch(loadMarkerInfoRequest());

    const queryParams = queryString.parse(window.location.search, {arrayFormat: 'bracket'});
    fetch(RailsRoutes.overview_map_property_path({ id: id }, Object.assign({}, queryParams, { format: "json" })))
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error();
      })
      .then(response => {
        return dispatch(loadMarkerInfoSuccess(response.marker));
      })
      .catch(err => {
        console.log(err);
      })
  }
}

export function activateMarker(marker) {
  return {
    type: ACTIVATE_OVERVIEW_MAP_MARKER,
    payload: marker
  }
}

export function deactivateMarker() {
  return {
    type: DEACTIVATE_OVERVIEW_MAP_MARKER
  }
}