import React from 'react'
import classNames from 'classnames'
import I18n from 'v2/client/javascripts/i18n'
import DestAutosuggest from 'javascripts/common/components/dest-autosuggest/dest-autosuggest'
import DateRangeInputs from 'javascripts/common/components/date-range-inputs/date-range-inputs'
import IconSvg from 'javascripts/client/components/icon-svg'
import {classWithModifiers} from 'javascripts/client/helpers';


class SearchboxTransferForm extends React.Component {

  static defaultProps = {
    autocompleteUrl: "/autocomplete",
    maxPax: 60,
    valueDateFormat: 'YYYY-MM-DD',
    viewDateFormat: 'DD MMM YYYY',
    maxCharsShortMonth: 3,
    placesSplitSymbol: '__',
  }

  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };
  }

  handleSubmit = (event) => {
    if (!this.isFormValid()) {
      event.preventDefault();
    }
  };

  isFormValid = () => {
    const {params: {date, fromLocation, toLocation, pax}} = this.props;
    const errors = [];

    if (!fromLocation.name || fromLocation.name.length === 0) {
      errors.push('fromLocation')
    }
    if (!toLocation.name || toLocation.name.length === 0) {
      errors.push('toLocation')
    }
    if (!date) {
      errors.push('date')
    }
    if (pax === 0) {
      errors.push('pax')
    }

    this.setState({errors});
    return errors.length === 0;
  };

  removeError = (errorName) => {
    this.setState({ errors: this.state.errors.filter((f) => f !== errorName) });
  };

  handleFromLocationChange = (fromLocation) => {
    this.setParams({fromLocation})
    this.refs.toLocation.setFocus()
  }

  handleToLocationChange = (toLocation) => {
    this.setParams({toLocation})
    if (!this.props.params.date) {
      this.refs.dateRangeInputs.setFocus()
    }
  }

  handleFromInputChange = (value) => {
    this.removeError('fromLocation');
    this.setParams({fromLocation: {slug: value, name: value}})
  }

  handleToInputChange = (value) => {
    this.removeError('toLocation');
    this.setParams({toLocation: {slug: value, name: value}})
  }

  handleDatesChange = (range) => {
    this.removeError('date');
    this.setParams({date: range.from, dateReturn: range.to})
  }

  handlePaxChange = (e) => {
    this.removeError('pax');
    this.setParams({pax: e.target.value})
  }

  handleOneWayChange = (e) => {
    this.setParams({dateReturn: null, oneWay: !this.props.params.oneWay})
  }

  setParams = (paramsPatch) => {
    // merge paramsPatch with full form params
    this.props.onParamsChange(Object.assign({}, this.props.params, paramsPatch));
  }

  getRouteUrl = () => {
    const from = this.props.params.fromLocation.slug || '',
      to = this.props.params.toLocation.slug || '',
      {searchUrl, placesSplitSymbol} = this.props

    if (from !== '' || to !== '') {
      return `${searchUrl}/${from}${placesSplitSymbol}${to}#transfers`
    }
    return searchUrl
  }

  renderSubmitInner = () => {
    const {modifiers} = this.props

    if (modifiers.includes("short-version")) {
      return <span>{I18n.t('fe.client.searchbox.transfer_form.submit.text_short_version')}</span>
    }
    if (modifiers.includes("on-hero")) {
      return <span>{I18n.t('fe.client.searchbox.transfer_form.submit.text_on_hero')}</span>
    }
    return (
      <span className="trf-search-form__submit-inner">
                <span>{I18n.t('fe.client.searchbox.transfer_form.submit.text')}</span>
                <IconSvg icon="double-arrow-right" className="icon-svg_1-5x"/>
            </span>
    )
  }


  noReturnTransferComponent = () => {
    const {oneWay} = this.props.params
    return (
      <div className={"search-form__ow-container search-form__ow-container_ow_" + oneWay}>
        <input
          id="search-form__ow-label"
          name="oneWay"
          type="checkbox"
          checked={oneWay}
          onChange={this.handleOneWayChange}/>
        <label htmlFor="search-form__ow-label" className="search-form__ow-label">
          {I18n.t('fe.client.searchbox.transfer_form.fields.date_return.no_return_transfer_label')}
        </label>
      </div>
    )
  }


  render() {
    const routeUrl = this.getRouteUrl()
    const {modifiers} = this.props
    const formClassNames = classNames("searchbox__form", classWithModifiers("trf-search-form", modifiers));
    const {errors} = this.state;
    const fromLocationWrapperClass = classNames(
      "searchbox__form-item trf-search-form__from", {"has-error": errors.includes("fromLocation")}
    );
    const toLocationWrapperClass = classNames(
      "searchbox__form-item trf-search-form__to", {"has-error": errors.includes("toLocation")}
    );
    const dateWrapperClass = classNames(
      "searchbox__form-item searchbox__form-item-separator trf-search-form__date-range", {"has-error": errors.includes("date")}
    );
    const paxSelectClass = classNames(
      "select_with-icon-prefix select_pointer-cursor js-select_placeholder",
      {
        "is-invalid-input": errors.includes('pax'),
        "placeholder": this.props.params.pax === 0
      }
    );

    return (
      <form action={routeUrl} method="GET" noValidate="novalidate" onSubmit={this.handleSubmit}>
        <div className={formClassNames}>
          <div className={fromLocationWrapperClass}>
            <DestAutosuggest
              url={this.props.autocompleteUrl}
              placeholder={I18n.t('fe.client.searchbox.transfer_form.fields.from_location.placeholder')}
              onSuggestionSelected={this.handleFromLocationChange}
              onInputChange={this.handleFromInputChange}
              value={this.props.params.fromLocation.name}
              searchIn={['place']}
              showSectionTitle={false} 
              showTwoInputs={true}
              icon={'location-filled'}
              iconClass={"icon-svg_1-5x"}
              ref="fromLocation"/>
          </div>
          
          <div className={toLocationWrapperClass}>
            <DestAutosuggest
              url={this.props.autocompleteUrl}
              placeholder={I18n.t('fe.client.searchbox.transfer_form.fields.to_location.placeholder')}
              onSuggestionSelected={this.handleToLocationChange}
              onInputChange={this.handleToInputChange}
              value={this.props.params.toLocation.name}
              searchIn={['place']}
              showSectionTitle={false}
              showTwoInputs={true}
              icon={'location-filled'}
              iconClass={"icon-svg_1-5x"}
              ref="toLocation"/>
          </div>

          <div className={dateWrapperClass}>
            <DateRangeInputs
              range={{from: this.props.params.date, to: this.props.params.dateReturn}}
              nameFrom="date"
              nameTo="date_return"
              placeholderFrom={I18n.t('fe.client.searchbox.transfer_form.fields.date.placeholder')}
              placeholderTo={I18n.t('fe.client.searchbox.transfer_form.fields.date_return.placeholder')}
              inputClassFrom=""
              inputClassTo=""
              locale={I18n.locale}
              viewDateFormat={this.props.viewDateFormat}
              maxCharsShortMonth={this.props.maxCharsShortMonth}
              valueDateFormat={this.props.valueDateFormat}
              ref="dateRangeInputs"
              onChange={this.handleDatesChange}
              beforeComponentForTo={this.noReturnTransferComponent}
            />
          </div>

          <div className="searchbox__form-item trf-search-form__passengers">
            <div className="select__container">
              <div className="select__icon-prefix select__icon-prefix_no-pointer-events">
                <IconSvg icon="users" className="icon-svg_1-5x"/>
              </div>
              <select
                className={paxSelectClass}
                name="pax" ref="pax" id="searchBoxPropertiesFormPax" onChange={this.handlePaxChange}
                value={this.props.params.pax}>
                <option value="0" disabled>{I18n.t('fe.client.searchbox.transfer_form.fields.pax.placeholder')}</option>
                {[...Array(this.props.maxPax)].map((_, i) =>
                  <option value={i + 1} key={i + 1}>{i + 1}</option>
                )}
              </select>
            </div>
          </div>

          <div className="searchbox__form-item searchbox__form-item_submit trf-search-form__submit">
            <button className="searchbox__form-item-button button button_expanded" type="submit">
              {this.renderSubmitInner()}
            </button>
          </div>

        </div>
      </form>
    )
  }

}


export default SearchboxTransferForm
