import {
  DEFAULT_FILTER_STATE,
  UPDATE_PROPERTIES_APPLIED_FILTER
} from "../../constants/propertiesConstants";


export default function(state = DEFAULT_FILTER_STATE, { type, payload }) {
  switch (type) {
    case UPDATE_PROPERTIES_APPLIED_FILTER:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}