import React, {Component} from 'react';
import I18n from 'v2/client/javascripts/i18n'
import BookingPaymentModal from "./booking-payment-modal";

export default class BookingPaymentButton extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount = () => {
    if (this.props.opened) {
      this.paymentModal.openModal();
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    this.paymentModal.openModal();
  }

  render() {
    return (
      <React.Fragment>
        <button className="button button_expanded" onClick={this.handleClick}>
          {I18n.t('defaults.actions.pay')}
        </button>
        <BookingPaymentModal
          ref={(el) => this.paymentModal = el}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}
