// TODO: replace with SvgIcon component from common. 
// Icons need to be styled by outside classes where they are used
// not by svg-icon general class.

import React from 'react';
import PropTypes from 'prop-types'

class IconSvg extends React.Component {
    static propTypes = {
        icon: PropTypes.string.isRequired,
        size: PropTypes.oneOf([1, 1.5, 2, 4]),
        fill: PropTypes.oneOf(['white', 'primary']),
        absolute: PropTypes.bool,
        svgsprite: PropTypes.string,
    }

    render() {
        let className = ['icon-svg'];
        if (this.props.className) className.push(this.props.className);
        if (this.props.size) className.push(`icon-svg_${String(this.props.size).replace('.', '-')}x`);
        if (this.props.fill) className.push(`icon-svg_fill-${this.props.fill}`);
        if (this.props.absolute) className.push('icon-svg_absolute');
        className = className.join(' ');

        return (
            <svg className={className}>
                <use xlinkHref={`#icon__${this.props.icon}`} />
            </svg>
        )
    }
}

export default IconSvg