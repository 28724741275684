import React from 'react';
import PropTypes from 'prop-types';
import CheckboxesList from './checkboxes-list';
import IconSvg from '../icon-svg';
import connectRedux from '../../redux/connectRedux';


class FacilitiesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeFacilities = this.handleChangeFacilities.bind(this);
  }

  handleChangeFacilities(facilities) {
    this.props.onChangeFilter({ facilities });
  }

  render() {
    const { facilities, selectedFacilities } = this.props;
    return (
      <CheckboxesList
        listClassName="proplist-controls__facilities-filter"
        itemClassName="proplist-controls__facilities-filter-item"
        labelClassName="proplist-controls__facilities-filter-label"
        listName="facilities_filter"
        options={facilities.map(facility => {
          const label = (facility.icon ?
            (<span><IconSvg icon={facility.icon} svgsprite={this.props.svgsprite} /> {facility.name}</span>)
            :
            facility.name
          );
          return [label, facility.id.toString()];
        }

        )}
        selected={selectedFacilities}
        onChange={this.handleChangeFacilities}
      />
    );
  }
}

FacilitiesFilter.propTypes = {
  facilities: PropTypes.array.isRequired,
  selectedFacilities: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

export default connectRedux(FacilitiesFilter, 'svgsprite');
