import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import IconSvg from './icon-svg';
import classNames from 'classnames';

export default class BookingPropertyGuestsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { deleteButtonVisible: false };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.toggleDeleteButtonVisible = this.toggleDeleteButtonVisible.bind(this);
  };

  handleInputChange(e) {
    let formData = Object.assign({}, this.props.guest, { [e.target.name]: e.target.value });
    this.props.getFormData(this.props.guestIndex, formData);
  }

  handleRemove(event) {
    event.preventDefault();
    this.props.removeGuest(this.props.guestIndex);
  }

  toggleDeleteButtonVisible(event) {
    event.preventDefault();
    this.setState({
      deleteButtonVisible: !this.state.deleteButtonVisible
    });
  }

  render() {
    const {guest} = this.props;
    return (
      <div className="booking-guests-modal__guest-fields">
        {this.props.guestIndex > 0 && (
            <div className='booking-guests-modal__form-line booking-guests-modal__guest-header'>
                <div className='booking-guests-modal__guest-title'>
                    {I18n.t('fe.client.booking_property_guest.form.title')+(this.props.guestIndex+1)}
                </div>
                <div className='booking-guests-modal__guest-delete-button'>
                    {this.state.deleteButtonVisible ? (
                        <div className='booking-guests-modal__confirm-delete'>
                            <button className='button button_hollow' onClick={this.handleRemove}>
                                {I18n.t('fe.client.booking_property_guest.actions.true')}
                            </button>
                            <button className='button button_hollow' onClick={this.toggleDeleteButtonVisible}>
                                {I18n.t('fe.client.booking_property_guest.actions.false')}
                            </button>
                        </div>
                    ) : (
                        <button className='button button_secondary button_hollow button_expanded' onClick={this.toggleDeleteButtonVisible} >
                            {I18n.t('fe.client.booking_property_guest.actions.delete_guest')}
                        </button>
                    )}
                </div>
            </div>
        )}

        <div className='booking-guests-modal__form-line'>
          <div className='booking-guests-modal__field'>
            <label className='booking-guests-modal__label'>
              {I18n.t('activerecord.attributes.booking_property_guest.surname')}
              <input
                type='text'
                name='surname'
                onChange={this.handleInputChange}
                value={guest.surname} />
            </label>
          </div>
          <div className='booking-guests-modal__field'>
            <label className='booking-guests-modal__label'>
              {I18n.t('activerecord.attributes.booking_property_guest.name')}
              <input
                type='text'
                name='name'
                onChange={this.handleInputChange}
                value={guest.name} />
            </label>
          </div>
        </div>

        <div className='booking-guests-modal__form-line'>
          <div className='booking-guests-modal__field'>
            <label className='booking-guests-modal__label'>
              {I18n.t('activerecord.attributes.booking_property_guest.birthday')}
              <div className='input__container'>
                <div className='input__icon-postfix'>
                  <IconSvg className="booking-guests-modal__calendar-icon" icon="calendar" svgsprite={this.props.svgsprite} size={1.5} />
                </div>
                <MaskedInput
                    mask={[/\d/, /\d/,'.',/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                    placeholder={I18n.t('fe.client.booking_property_guest.form.date_placeholder')}
                    type='text'
                    name='birthday'
                    onChange={this.handleInputChange}
                    value={guest.birthday} />
              </div>
            </label>
          </div>
          <div className='booking-guests-modal__field'></div>
        </div>
        <div className='booking-guests-modal__form-line'>
          <div className='booking-guests-modal__field'>
            <label className='booking-guests-modal__label'>
              {I18n.t('activerecord.attributes.booking_property_guest.passport')}
              <input
                type='text'
                name='passport'
                onChange={this.handleInputChange}
                value={guest.passport} />
            </label>
          </div>
          <div className='booking-guests-modal__field'>
            <label className='booking-guests-modal__label'>
              {I18n.t('activerecord.attributes.booking_property_guest.passport_valid_till')}
                <div className='input__container'>
                  <div className='input__icon-postfix'>
                    <IconSvg className="booking-guests-modal__calendar-icon" icon="calendar" svgsprite={this.props.svgsprite} size={1.5} />
                  </div>
                  <MaskedInput
                    mask={[/\d/, /\d/,'.',/\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                    placeholder={I18n.t('fe.client.booking_property_guest.form.date_placeholder')}
                    type='text'
                    name='passport_valid_till'
                    onChange={this.handleInputChange}
                    value={guest.passport_valid_till} />
                </div>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

BookingPropertyGuestsForm.propTypes = {
  guest: PropTypes.shape({
    surname: PropTypes.string,
    name: PropTypes.string,
    birthday: PropTypes.string,
    passport: PropTypes.string,
    passport_valid_till: PropTypes.string
  }),
  guestIndex: PropTypes.number.isRequired,
  svgsprite: PropTypes.string.isRequired
};
