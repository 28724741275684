import moment from 'moment'

import {
  DEFAULT_FILTER_STATE,
  UPDATE_PROPERTIES_FILTER
} from '../../constants/propertiesConstants';
import { updateAppliedFilter } from "./appliedFilterActions";


const DATE_PARAM_FORMAT = 'YYYY-MM-DD';

function visitUrl(url) {
  const currentUrl = window.location.pathname + window.location.search;
  if (url === currentUrl) {
    return;
  }
  if (global.Turbolinks) {
    global.Turbolinks.visit(url);
  } else {
    window.location = url;
  }
}

export function updateFilter(values) {
  return {
    type: UPDATE_PROPERTIES_FILTER,
    payload: values
  }
}

export function applyFilter() {
  return function(dispatch, getState) {
    const searchParams = getState().searchbox.params.accommodation;
    const { dest } = searchParams;
    const filterParams = getState().properties.filter;

    const params = {
      dest: dest.slug,
      dest_type: dest.type,
      dest_value: dest.name,
      date_in: moment(searchParams.dateIn).format(DATE_PARAM_FORMAT),
      date_out: moment(searchParams.dateOut).format(DATE_PARAM_FORMAT),
      guests: JSON.stringify(searchParams.guests),
      property_types: filterParams.propertyTypes,
      meal_types: filterParams.mealTypes,
      stars: filterParams.stars,
      facilities: filterParams.facilities,
      price_start: filterParams.startPrice,
      price_end: filterParams.endPrice,
      currency: getState().user_currency,
      order_by: filterParams.orderBy,
      order_dir: filterParams.orderDir
    };
    const url = `/properties/s?${$.param(params)}`;
    visitUrl(url);
    dispatch(updateAppliedFilter(filterParams));
  }
}

export function blankFilterState(filterOptions) {
  return Object.assign(
      {}, DEFAULT_FILTER_STATE,
      {
        startPrice: filterOptions.minPrice,
        endPrice: filterOptions.maxPrice
      }
  )
}

export function resetFilter() {
  return function(dispatch, getState) {
    const { properties: { options } } = getState();
    dispatch(updateFilter(blankFilterState(options)));
    //dispatch(applyFilter());
  }
}
