import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import ModalLayout from './modal-layout';
import Searchbox from './searchbox'
//import tippy from 'tippy.js'
//import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class SearchboxModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalLayout = null;
    }

    componentDidMount() {
        const triggers = document.querySelectorAll(".js-modal-searchbox-trigger");
        [].forEach.call(triggers, (el) => el.addEventListener('click', this.openModal))        
    }

    openModal = (e) => {
        this.modalLayout.openModal()
    }

    handleOpen = (node) => {
    }

    render() {
        return (
            <ModalLayout
                ref={c => this.modalLayout = c}
                onOpen={this.handleOpen}>
                <div className='searchbox-in-modal__text'>{I18n.t(`fe.client.searchbox_in_modal.${this.props.activeTab}.text`)}</div>
                <Searchbox
                    smallExpanded={true}
                    active_tab={this.props.activeTab}
                    modifiers={["in-modal", "short-version"]}
                />
            </ModalLayout>
        );
    }
}


export default SearchboxModal
