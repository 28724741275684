export function lockLayout() {
    const scrollTop = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    document.body.style.top = `${-scrollTop}px`;
    document.body.style.right = '0px';
    document.body.style.bottom = '0px';
    document.body.style.left = '0px';
}

export function unLockLayout() {
    const lockedScrollTop = -1 * parseInt(document.body.style.top)
    document.body.style.position = '';
    document.body.style.overflow = '';
    document.body.style.top = '';
    document.body.style.right = '';
    document.body.style.bottom = '';
    document.body.style.left = '';
    window.scrollTo(0, lockedScrollTop);
}



export function shortMonthThreeChars(date) {
    let dateArr = date.split(' ')
    dateArr[1] = dateArr[1].substring(0,3)
    return dateArr.join(' ')
}
