import React, { Component } from 'react';
import I18n from 'v2/client/javascripts/i18n'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadPropertiesCount } from '../../actions/properties/countActions'


class PropertiesCount extends Component {

  componentDidMount() {
    this.props.loadPropertiesCount(this.props.url)
  }

  render() {
    return <span>{I18n.t('activerecord.models.property', { count: this.props.count })}</span>
  }

}

function mapStateToProps({ properties: { count } }) {
  return { count }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadPropertiesCount }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesCount)
