import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types';

import CheckboxesList from './checkboxes-list';


class PropertyTypeFilter extends React.Component {

  constructor(props) {
    super(props);
    this.handleChangePropertyTypes = this.handleChangePropertyTypes.bind(this);
  }

  handleChangePropertyTypes(propertyTypes) {
    this.props.onChangeFilter({ propertyTypes })
  }

  render() {
    const { propertyTypes, selectedPropertyTypes } = this.props;
    return (
      <CheckboxesList
        listName={`property_types_${Math.floor(Math.random() * 1000000)}`}
        options={propertyTypes.map(property_type =>
          [I18n.t(`activerecord.attributes.property.property_types.${property_type}.one`), property_type]
        )}
        selected={selectedPropertyTypes}
        onChange={this.handleChangePropertyTypes}
      />
    );
  }

}

PropertyTypeFilter.propTypes = {
  propertyTypes: PropTypes.array.isRequired,
  selectedPropertyTypes: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

export default PropertyTypeFilter
