import PropTypes from "prop-types";


export const LOAD_OVERVIEW_MAP_MARKERS_REQUEST = 'LOAD_OVERVIEW_MAP_MARKERS_REQUEST';
export const LOAD_OVERVIEW_MAP_MARKERS_SUCCESS = 'LOAD_OVERVIEW_MAP_MARKERS_SUCCESS';
export const APPEND_OVERVIEW_MAP_MARKERS_SUCCESS = 'APPEND_OVERVIEW_MAP_MARKERS_SUCCESS';
export const RESET_OVERVIEW_MAP_MARKERS = 'RESET_OVERVIEW_MAP_MARKERS';

export const LOAD_OVERVIEW_MAP_MARKER_INFO_REQUEST = 'LOAD_OVERVIEW_MAP_MARKER_INFO_REQUEST';
export const LOAD_OVERVIEW_MAP_MARKER_INFO_SUCCESS = 'LOAD_OVERVIEW_MAP_MARKER_INFO_SUCCESS';

export const ACTIVATE_OVERVIEW_MAP_MARKER = 'ACTIVATE_OVERVIEW_MAP_MARKER';
export const DEACTIVATE_OVERVIEW_MAP_MARKER = 'DEACTIVATE_OVERVIEW_MAP_MARKER';

export const OPEN_OVERVIEW_MAP_MODAL = 'OPEN_OVERVIEW_MAP_MODAL';
export const CLOSE_OVERVIEW_MAP_MODAL = 'CLOSE_OVERVIEW_MAP_MODAL';

export const markerShape = {
  id: PropTypes.number,
  type: PropTypes.string,
  full_id: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const markerInfoShape = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string
};

export const coordinatesShape = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const mapStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
];