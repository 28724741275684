import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'




function rangeToDates(range) {
	const { from, to } = range
	let dates = []
	if(from && to) {
		for (let date = moment(from); date.isSameOrBefore(to); date.add(1, 'd')) {
			dates.push(new Date(date.toDate()))
		}
	}
	return dates
}

function dateIndexInArray(date, arr) {
	if(arr) {
		return arr.map(Number).indexOf(+date)
	} else {
		return -1
	}
}

function isDateInArray(date, arr) {
	return dateIndexInArray(date, arr) > -1 ? true : false	
}


class SelectionModeChooser extends React.Component {
	constructor(props) {
		super(props)

		this.handleSelectionModeChange = this.handleSelectionModeChange.bind(this)
	}

	handleSelectionModeChange(e){
		const mode = this.refs.selectionMode.value
		this.props.onSelectionModeChange(mode)	
	}

	render() {
		return (
			<div className='dates-picker__selection-mode-chooser' role='heading'>
				<select ref="selectionMode" onChange={this.handleSelectionModeChange} value={this.props.selectionMode}>
					<option value="single">Single date</option>
					<option value="multiple">Multiple dates</option>
					<option value="range">Range selection</option>
				</select>
			</div>
		)
	}
}



class DatesPicker extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			currentMonthDate: this.props.initialMonthDate,
			isSelectingDateFrom: true,
			selectionMode: this.props.initialSelectionMode
		}
		
		this.handleDayClick = this.handleDayClick.bind(this)
		this.handleMonthChange = this.handleMonthChange.bind(this)					
		this.isDateSelected = this.isDateSelected.bind(this)					
	}


	handleDayClick(date, modifiers, e) {
		const { disabled, selected } = modifiers

		// don't handle day click if disabled
		if (disabled) { return }

		const { isSelectingDateFrom, selectionMode } = this.state,
					{ selectedDates, selectedRange, canDeselectDate } = this.props

		let newDates, newRange

		switch(selectionMode)	{
			case "single": {
				newDates = (selected && canDeselectDate) ? [] : [date]
				newRange = { from: date, to: date }
				break
			}
			case "multiple": {
				newDates = selectedDates
				if(selected) {
					const dateIndex = dateIndexInArray(date, selectedDates)

					newDates.splice(dateIndex, 1)
				} else {
					newDates.push(date)
				}
				newRange = { from: null, to: null }
				break
			}
			case "range": {
				//if we are in range mode and haven't got selectedRange prop then we take dateFrom and dateTo args from selectedDates
				const dateFrom = (selectedRange.from == undefined) ? selectedDates[0] : selectedRange.from,
							rangeRole = this.props.rangeRole

				if(isSelectingDateFrom || rangeRole == "from" || moment(date).isBefore(dateFrom)) {
					newRange = { from: date, to: date }
					this.setState({isSelectingDateFrom: false})						
				} else if(moment(date).isSame(dateFrom) && canDeselectDate) {
					newRange = { from: null, to: null }
					this.setState({isSelectingDateFrom: true})
				} else {
					newRange = { from: dateFrom, to: date }
					this.setState({isSelectingDateFrom: true})
				}					
				newDates = rangeToDates(newRange)
				break
			}
		}
		
		this.props.onDatesChange(newDates, newRange)
		this.props.onDayClick(e, date, modifiers)
	}


	handleMonthChange(newMonthDate) {
		const newYear = newMonthDate.getFullYear(),
					currentYear = this.state.currentMonthDate.getFullYear()

		this.setState({currentMonthDate: newMonthDate})
		if(currentYear != newYear) { this.props.onYearChange(newYear) }
	}


	isDateSelected(date) {
		const { selectedRange, selectedDates } = this.props
		return isDateInArray(date, selectedDates) || (moment(date).isAfter(selectedRange.from, 'd') && moment(date).isBefore(selectedRange.to, 'd'))
	}


	render() {
		let modifiers = {}

		if(this.state.selectionMode == "range") {
			const rangeModifiers = {
				"from": (date) => moment(date).isSame(this.props.selectedRange.from, 'd'),
				"to": (date) => moment(date).isSame(this.props.selectedRange.to, 'd') 
			}
			modifiers = Object.assign({}, modifiers, rangeModifiers)
		}

		return (
			<div className="dates-picker">
				{
					this.props.withSelectionModes &&
					<SelectionModeChooser
						selectionMode={this.state.selectionMode}
						onSelectionModeChange={(mode) => this.setState({selectionMode: mode})} />
				}

				<DayPicker	
					selectedDays={this.isDateSelected}
					onDayClick={this.handleDayClick}
					onMonthChange={this.handleMonthChange}					
					disabledDays={this.props.disabledDates}
					month={this.props.initialMonthDate}
					modifiers={Object.assign({}, modifiers, this.props.modifiers)}
					renderDay={this.props.renderDay}
					locale={this.props.locale}
					tabIndex={this.props.tabIndex}
					localeUtils={MomentLocaleUtils} />
			</div>
		)
	}
}

// TODO
DatesPicker.propTypes = {
	initialSelectionMode: PropTypes.oneOf(["single", "multiple", "range"]),
	withSelectionModes: PropTypes.bool,
	rangeRole: PropTypes.oneOf(["from", "to"]),
}


// TODO
DatesPicker.defaultProps = {
	selectedDates: [],
	selectedRange: { from: null, to: null },
	rangeRole: undefined,
	initialSelectionMode: "multiple",
	withSelectionModes: false,
	initialMonthDate: new Date,
	canDeselectDate: true,
	locale: "en",
	modifiers: {},
	onDayClick: new Function,
	onDatesChange: new Function,
	onYearChange: new Function,
	onDayKeyDown: new Function
}


export default DatesPicker
