import { LOAD_PROPERTIES_COUNT_SUCCESS } from "../../constants/propertiesConstants";

const defaultState = 0;

export default function(state = defaultState, { type, payload }) {
  switch (type) {
    case LOAD_PROPERTIES_COUNT_SUCCESS:
      return payload;
    default:
      return state;
  }
}