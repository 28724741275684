import PropTypes from 'prop-types'

export default {
  roomVariant: PropTypes.shape({
    room_id: PropTypes.number,
    policy_id: PropTypes.number,
    max_amount: PropTypes.number,
    total_price: PropTypes.number,
  }),
  roomCartItem: PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
  }),
};
