import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from "prop-types";

import CheckboxesList from './checkboxes-list';


class StarsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeStars = this.handleChangeStars.bind(this);
  }

  handleChangeStars(stars) {
    this.props.onChangeFilter({ stars });
  }

  render() {
    const { stars, selectedStars } = this.props;
    return (
      <CheckboxesList
        listName="stars_filter"
        options={stars.map(star => [(star === 0 ? I18n.t('fe.client.properties_filters.stars.no_stars') : star + '*'), star.toString()])}
        selected={selectedStars}
        onChange={this.handleChangeStars}
      />
    );
  }
}

StarsFilter.propTypes = {
  stars: PropTypes.array.isRequired,
  selectedStars: PropTypes.array.isRequired,
  onChangeFilter: PropTypes.func.isRequired
};

export default StarsFilter
