// TODO: make one component (we have also ...form-on-hero)
// who was so clever to do like that for only css classes changes???

import moment from 'moment'
import PropTypes from 'prop-types'
import I18n from 'v2/client/javascripts/i18n'
import React from 'react'
import DestAutosuggest from 'javascripts/common/components/dest-autosuggest/dest-autosuggest'
import DateRangeInputs from 'javascripts/common/components/date-range-inputs/date-range-inputs'
import GuestsInput from 'javascripts/common/components/guests-input/guests-input'
import classNames from 'classnames'
import {lockLayout, unLockLayout, classWithModifiers} from 'javascripts/client/helpers';


const MAX_STAY_DAYS = 31;

class SearchboxPropertiesForm extends React.Component {
  static propTypes = {
    searchUrl: PropTypes.string.isRequired,
    autocompleteUrl: PropTypes.string,
    maxGuests: PropTypes.number,
    initialGuests: PropTypes.object,
    valueDateFormat: PropTypes.string,
    viewDateFormat: PropTypes.string,
    onParamsChange: PropTypes.func
  }

  static defaultProps = {
    autosuggestUrl: "/autocomplete",
    destAutosugestId: "SearchboxAutosuggest",
    maxGuests: 30,
    valueDateFormat: 'YYYY-MM-DD',
    viewDateFormat: 'DD MMM YYYY',
    maxCharsShortMonth: 2,
    modifiers: []
  }


  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };
  }

  handleSubmit = (event) => {
    if (!this.isFormValid()) {
      event.preventDefault();
    }
  };

  isFormValid = () => {
    const {params: {dateIn, dateOut}} = this.props;
    const errors = [];

    if (!dateIn || !dateOut || moment(dateOut).diff(moment(dateIn), 'd') > MAX_STAY_DAYS) {
      errors.push('date_range')
    }

    this.setState({errors});
    return errors.length === 0;
  };

  removeError = (errorName) => {
    this.setState({errors: this.state.errors.filter((f) => f !== errorName)});
  };


  handleDestChange = (dest) => {
    this.setParams({dest});
    // Open period calendar if one of the date is missed.
    if (!this.props.params.dateIn || !this.props.params.dateOut) {
      this.refs.dateRangeInputs.setFocus()
    }
  }

  handleInputChange = (value) => {
    if (value !== this.props.params.dest.name) {
      this.setParams({dest: {slug: value, name: value}});
    }
  }

  handleDatesChange = (range) => {
    this.removeError('date_range');
    this.setParams({dateIn: range.from, dateOut: range.to});
  }

  handleGuestsChange = (guests) => {
    this.setParams({guests});
  }

  setParams = (paramsPatch) => {
    // merge paramsPatch with full form params
    this.props.onParamsChange(Object.assign({}, this.props.params, paramsPatch));
  }

  isFormFilled = () => {
    const {dateIn, dateOut, dest, dest_value} = this.props.params
    return dateIn && dateOut && ((dest.slug && dest.type) || dest_value)
  }

  renderSubmitInner = () => {
    const {modifiers} = this.props

    if (modifiers.includes("on-hero")) {
      return (
        <span>{I18n.t('fe.client.searchbox.properties_form.submit.text_on_hero')}</span>
      )
    } else {
      return (
        <span>{I18n.t('fe.client.searchbox.properties_form.submit.text')}</span>
      )
    }
  }


  render() {
    const submitClass = this.isFormFilled() ? "button" : "button"//"button button_secondary"
    const {modifiers} = this.props
    const formClassNames = classNames("searchbox__form", classWithModifiers("prop-search-form", modifiers))
    const dateRangeWrapperClass = classNames(
      "prop-search-form__date-range",
      {
        "has-error": this.state.errors.includes('date_range')
      }
    );

    return ( 
      <form className="js-searchbox-property-form" action={`${this.props.searchUrl}#js-properties`} method="GET"
            onSubmit={this.handleSubmit}>
        <div className={formClassNames}>
          <div className="prop-search-form__destination">
            <DestAutosuggest
              url={this.props.autosuggestUrl}
              placeholder={I18n.t('fe.client.searchbox.properties_form.fields.destination.placeholder')}
              onSuggestionSelected={this.handleDestChange}
              onInputChange={this.handleInputChange}
              value={this.props.params.dest.name}
              icon={'location'}
              iconClass={'icon-svg_1-5x'}
              valueInputName="dest_value"
              id={this.props.destAutosugestId}/>
            <input type="hidden" value={this.props.params.dest.slug || ''} name="dest"/>
            <input type="hidden" value={this.props.params.dest.type || ''} name="dest_type"/>
            <input type="hidden" value={this.props.params.dest.propertyTypes || ''} name="property_types"/>
          </div>


          <div className={dateRangeWrapperClass}>
            <DateRangeInputs
              range={{from: this.props.params.dateIn, to: this.props.params.dateOut}}
              nameFrom="date_in"
              nameTo="date_out"
              placeholderFrom={I18n.t('fe.client.searchbox.properties_form.fields.date_in.placeholder')}
              placeholderTo={I18n.t('fe.client.searchbox.properties_form.fields.date_out.placeholder')}
              inputClassFrom=""
              inputClassTo=""
              locale={I18n.locale}
              viewDateFormat={this.props.viewDateFormat}
              maxCharsShortMonth={this.props.maxCharsShortMonth}
              valueDateFormat={this.props.valueDateFormat}
              ref="dateRangeInputs"
              onChange={this.handleDatesChange}
            />
          </div>


          <div className="prop-search-form__guests">
            <GuestsInput
              onGuestsSelected={this.handleGuestsChange}
              initialGuests={this.props.params.guests}
              placeholder={I18n.t('fe.client.searchbox.properties_form.fields.guests.placeholder')}
              name="guests"
            />
          </div>


          <div className="prop-search-form__submit">
            <div>
              <button className={submitClass} type="submit">
                {this.renderSubmitInner()}
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  }
}


export default SearchboxPropertiesForm