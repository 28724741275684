import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';

/**
 *
 * Creates component connected to redux with smart declaration of state props and dispatch rules.
 *
 * @param {React.Component} ComposedComponent - Component class to redux connect.
 * @param {String|Array|Object.<String, String|Function} stateKeys - State key name or array of names or hash for map to props from redux state.
 * @param {Object} [dispatchRules = null] - Map of dispatch rules.
 * @returns {React.Component} Returns component connected to redux.
 * @example
 *
 * export default connectRedux(PropertyRoomSelect, ['room_variants', 'room_cart'], {
 *   dispatchAmount: (policy_id, amount) => {
 *     return { type: 'PATCH_room_cart', patch: { id: policy_id, amount } };
 *   },
 * });
 */

export default (ComposedComponent, stateKeys, dispatchRules = null) =>
  connect(
    stateKeys && ((state, ownProps) => (Object.assign({},
      ownProps,
      isPlainObject(stateKeys) ?
        mapValues(stateKeys, (stateRule, key) => isString(stateRule) ? (
          canGetFromStore(state[stateRule], ownProps[key]) ? state[stateRule] : ownProps[key]
        ) : stateRule(state, ownProps))
        :
        pickBy(state, (value, key) => includes(stateKeys, key) && canGetFromStore(value, ownProps[key])),
    ))),
    dispatchRules && ((dispatch) => (
      mapValues(dispatchRules, action => (
        (...params) => dispatch(action(...params))
      ))
    )),
  )(ComposedComponent)

function canGetFromStore(reduxValue, ownValue) {
  if (ownValue) {
    if (isEmpty(reduxValue)) {
      return false;
    } else if (isPlainObject(ownValue) && ownValue.id) {
      return ownValue.id === reduxValue.id;
    }
  }
  return true;
}
