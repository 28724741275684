import { getCurrentWindowSize } from 'javascripts/client/media-queries';
import I18n from 'v2/client/javascripts/i18n';

export function numberWithPrecision(number, { delimiter, separator, precision, strip_insignificant_zeros }) {
  if (number === null || isNaN(number)) {
    return '';
  }
  if (typeof number === 'string') {
    number = parseFloat(number);
  }
  number = number.toFixed(~~precision); // eslint-disable-line no-bitwise
  const [i, f] = number.split('.');
  const decimals = isNaN(f) || strip_insignificant_zeros && parseInt(f, 10) === 0 ? '' : separator + f;
  return `${i.replace(/(\d)(?=(?:\d{3})+$)/g, `$1${delimiter}`)}${decimals}`;
}

export function numberToCurrency(number, options = {}) {
  const mergedOptions = Object.assign({}, I18n.t('number.currency.format'), options);
  const n = numberWithPrecision(number, mergedOptions);
  let value = mergedOptions.format ? mergedOptions.format.replace('%n', n).replace('%u', mergedOptions.unit) : n;
  return value.trim()
}

export function authHeaders() {
  const headers = new Headers();
  headers.append("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'));
  headers.append('X-Requested-With', 'XMLHttpRequest');
  return headers
}



export function lockLayout() {
    const scrollTop = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.overflow = 'hidden';
    document.body.style.top = `${-scrollTop}px`;
    document.body.style.right = '0px';
    document.body.style.bottom = '0px';
    document.body.style.left = '0px';
}

export function unLockLayout() {
    const lockedScrollTop = -1 * parseInt(document.body.style.top)
    document.body.style.position = '';
    document.body.style.overflow = '';
    document.body.style.top = '';
    document.body.style.right = '';
    document.body.style.bottom = '';
    document.body.style.left = '';
    window.scrollTo(0, lockedScrollTop);
}


export function classWithModifiers(className, modifiers) {
    let modifiersCss = [className]
    if(modifiers) {
        modifiers.forEach((m, i, arr) =>  {
            modifiersCss.push(`${className}_${m}`)
        })
    }
    return modifiersCss.join(" ")
}
