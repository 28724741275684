import { LOAD_PROPERTIES_OPTIONS_SUCCESS } from '../../constants/propertiesConstants';


const defaultState = {
  propertyTypes: [],
  mealTypes: [],
  stars: [],
  facilities: [],
  minPrice: 0,
  maxPrice: 0
};

export default function(state = defaultState, { type, payload }) {
  switch (type) {
    case LOAD_PROPERTIES_OPTIONS_SUCCESS:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
}