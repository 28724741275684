import React, { Component } from 'react'
import I18n from 'v2/client/javascripts/i18n'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { openModal } from "../../actions/overview-map/modalActions";


class OverviewMapShowLink extends Component {

  handleClick = (event) => {
    event.preventDefault();
    this.props.openModal();
  };

  render() {
    return (
      <a href="#" onClick={this.handleClick}>{this.props.label}</a>
    )
  }

}

OverviewMapShowLink.propTypes = {
  label: PropTypes.string,
  openModal: PropTypes.func.isRequired,
};

OverviewMapShowLink.defaultProps = {
  label: I18n.t("fe.client.overview_map.show_on_map")
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModal
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(OverviewMapShowLink)