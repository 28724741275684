import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';
import './style.css';
import I18n from 'v2/client/javascripts/i18n';
import ru from 'react-phone-input-2/lang/ru.json';
import pl from './pl.json';

const LOCALIZATION = {
  en: {},
  ru,
  pl,
};

class PhoneInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: props.initialValue || '',
    };
  }

  handlePhoneChange = (phone) => this.setState({ phone })

  render() {
    const {
      inputName,
      inputId,
      inputRequired,
      countryCodeEditable = false,
      enableSearch = false,
    } = this.props;

    return (
      <ReactPhoneInput
        value={this.state.phone}
        onChange={this.handlePhoneChange}
        countryCodeEditable={countryCodeEditable}
        country="gb"
        preferredCountries={['gb', 'us', 'fr']}
        preserveOrder={['preferredCountries']}
        localization={LOCALIZATION[I18n.locale] || {}}
        enableSearch={enableSearch}
        disableSearchIcon
        inputProps={{
          name: inputName,
          required: inputRequired,
          id: inputId,
          // 'data-validator': 'phoneNumber', // Foundation Abide
        }}
      />
    );
  }
}

PhoneInput.propTypes = {
  initialValue: PropTypes.string,
  inputName: PropTypes.string,
  inputId: PropTypes.string,
  inputRequired: PropTypes.bool,
};

export default PhoneInput;
