import { combineReducers } from 'redux'

import markers from './markersReducer'
import markerInfos from './markerInfosReducer'
import activeMarker from './activeMarkerReducer'
import modal from './modalReducer'

export default combineReducers({
  markers,
  markerInfos,
  activeMarker,
  modal,
});