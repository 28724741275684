import moment from 'moment'
import React from 'react';
import I18n from 'v2/client/javascripts/i18n'
import { connect } from 'react-redux';
import DateRangeInputs from 'javascripts/common/components/date-range-inputs/date-range-inputs';
import GuestsInput from 'javascripts/common/components/guests-input/guests-input';

const parseInitialParams = (params, defaultParams) => {
  params.dateIn = params.dateIn ? new Date(params.dateIn) : defaultParams.dateIn;
  params.dateOut = params.dateOut ? new Date(params.dateOut) : defaultParams.dateOut;
  params.guests = params.guests ? params.guests : defaultParams.guests;
  return params
}



class PropertylistItemPriceForm extends React.Component {

  static defaultProps = {
    className: 'proplist-item',
    actionUrl: "/properties/s",
    maxGuests: 30,
    valueDateFormat: 'YYYY-MM-DD',
    viewDateFormat: 'DD MMM YYYY',
    maxCharsShortMonth: 3,
    initialParams: {
      guests: { adults: 2 },
      dateIn: null,
      dateOut: null
    }
  }

  constructor(props) {
    super(props)
    const initialParams = parseInitialParams(this.props.initialParams, this.constructor.defaultProps.initialParams)
    this.state = { params: initialParams }
  }


  handleDatesChange = (range) => {
    this.setParam("dateIn", range.from)
    this.setParam("dateOut", range.to)
  }

  handleGuestsChange = (guests) => {
    this.setParam("guests", guests)
  }

  setParam = (key, value) => {
    let params = this.state.params
    params[key] = value
    this.setState({params: params})
  }

  propertyTypesFields = this.props.filter.propertyTypes.map((propertyType) =>
    <input type="hidden" name="property_types" value={propertyType} />
  );

  render() {
    return (
      <form  className={`${this.props.className}__form`} action={this.props.actionUrl} method="get">
        <input type="hidden" name="dest" value={this.props.propertySlug} />
        <input type="hidden" name="dest_type" value="property" />
        {this.propertyTypesFields}

        <div className={`${this.props.className}__form-date-range`}>
          <div className={`${this.props.className}__form-label`}>
            {I18n.t('fe.client.searchbox.properties_form.fields.date_in.label')}
          </div>
          <DateRangeInputs
            range={{from: this.state.params.dateIn, to: this.state.params.dateOut}}
            nameFrom="date_in"
            nameTo="date_out"
            placeholderFrom={I18n.t('fe.client.searchbox.properties_form.fields.date_in.placeholder')}
            placeholderTo={I18n.t('fe.client.searchbox.properties_form.fields.date_out.placeholder')}
            inputClassFrom=""
            inputClassTo=""
            locale={I18n.locale}
            viewDateFormat={this.props.viewDateFormat}
            valueDateFormat={this.props.valueDateFormat}
            ref="dateRangeInputs"
            onChange={this.handleDatesChange}
            maxCharsShortMonth={this.props.maxCharsShortMonth}
          />
        </div>

				<div className={`${this.props.className}__form-guests`}>
					<div className={`${this.props.className}__form-label`}>
            {I18n.t('fe.client.searchbox.properties_form.fields.guests.label')}
					</div>
					<GuestsInput
						onGuestsSelected={this.handleGuestsChange}
						initialGuests={this.state.params.guests}
                        name="guests"
					/>
				</div>

				<div className={`${this.props.className}__form-submit`}>
					<div className={`${this.props.className}__form-label ${this.props.className}__form-label_submit`}>
						&nbsp;
					</div>
					<button className="button" type="submit">
						<span>{I18n.t('fe.client.searchbox.properties_form.submit.text')}</span>
					</button>
				</div>

			</form>
        )
    }
}


function mapStateToProps({ properties: { filter } }) {
  return {
    filter
  }
}

export default connect(mapStateToProps)(PropertylistItemPriceForm)
