import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import OverviewMap from "./overview-map";

import {
  loadMarkers,
  loadMarkerInfo,
  loadProperties,
  loadPropertyInfo,
  activateMarker,
  deactivateMarker,
  resetMarkers
} from "../../actions/overview-map/markersActions";
import {coordinatesShape, markerShape} from "../../constants/overviewMapConstants";


class OverviewMapPanel extends Component {

  componentDidMount() {
    const { isSearch, loadMarkers, loadProperties, initialActiveMarker, resetMarkers } = this.props;

    resetMarkers();

    if (isSearch) {
      loadProperties();
    } else {
      loadMarkers();
    }

    if (initialActiveMarker) {
      this.activateAndLoadMarker(initialActiveMarker);
    }
  }

  handleActivateMarker = (marker) => {
    this.activateAndLoadMarker(marker);
  };

  activateAndLoadMarker = (marker) => {
    const { markerInfos, activateMarker, loadPropertyInfo, loadMarkerInfo, isSearch } = this.props;

    activateMarker(marker);

    if (!markerInfos[marker.full_id]) {
      if (isSearch && marker.type === "PropertyResult") {
        loadPropertyInfo(marker);
      } else {
        loadMarkerInfo(marker);
      }
    }
  };

  render() {
    const { markers, markerInfos, activeMarker, deactivateMarker, initialCenter, initialZoom } = this.props;

    return <OverviewMap markers={markers}
                        markerInfos={markerInfos}
                        activeMarker={activeMarker}
                        initialCenter={initialCenter}
                        initialZoom={initialZoom}
                        onActivateMarker={this.handleActivateMarker}
                        onDeactivateMarker={deactivateMarker} />
  }

}

OverviewMapPanel.propTypes = {
  isSearch: PropTypes.bool,
  initialCenter: PropTypes.shape(coordinatesShape),
  initialZoom: PropTypes.number,
  initialActiveMarker: PropTypes.shape(markerShape),
  markers: PropTypes.arrayOf(PropTypes.shape(markerShape)).isRequired,
  markerInfos: PropTypes.object.isRequired,
  activeMarker: PropTypes.shape(markerShape),
  loadMarkers: PropTypes.func.isRequired,
  loadProperties: PropTypes.func.isRequired,
  resetMarkers: PropTypes.func.isRequired,
  loadPropertyInfo: PropTypes.func.isRequired,
  loadMarkerInfo: PropTypes.func.isRequired,
  activateMarker: PropTypes.func.isRequired,
  deactivateMarker: PropTypes.func.isRequired,
};

function mapStateToProps({ overviewMap: { markers, markerInfos, activeMarker } }) {
  return { markers, markerInfos, activeMarker };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadMarkers,
    loadMarkerInfo,
    loadPropertyInfo,
    loadProperties,
    activateMarker,
    deactivateMarker,
    resetMarkers
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMapPanel)