import React from "react";
import I18n from 'v2/client/javascripts/i18n'

class BookingPayInput extends React.Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this)

        this.state = {
            min: props.min,
            max: props.max,
            value: props.min,
            min_error: false,
            max_error: false
        };
    }

    handleInputChange(field) {
        const {min, max} = this.state

        var val = field.target.value
        this.setState({value: val})

        if (val < min) {
            this.setState({min_error: true, max_error: false})
        } else if (val > max) {
            this.setState({min_error: false, max_error: true})
        } else {
            this.setState({min_error: false, max_error: false})
        }
    }

    render() {
        return (
            <div className="medium-4 column">
                {this.state.min_error && <div
                    className="page-flash callout alert">{I18n.t('fe.client.booking_pay.errors.min')} {this.state.min} {this.props.currency}</div>}
                {this.state.max_error && <div
                    className="page-flash callout alert">{I18n.t('fe.client.booking_pay.errors.max')} {this.state.max} {this.props.currency}</div>}

                <br/>
                <div className="medium-6 column">
                    <input name="amount" min={this.state.min} max={this.state.max} id="amount" value={this.state.value}
                           style={{width: 170 + 'px'}} onChange={this.handleInputChange}
                           type="number"/>
                </div>
                <div className="medium-6 column">
                    <input name="commit" disabled={this.state.min_error || this.state.max_error}
                           value={I18n.t('fe.client.booking_pay.pay_action')} className="button success"
                           type="submit"/>
                </div>
            </div>
        );
    }
}

export default BookingPayInput
