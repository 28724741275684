class GaEventTracking {

    constructor() {
        this.trackers = {}
    }

    set(trackerName, tracker) {
        if(trackerName && tracker) {
            this.trackers[trackerName] = tracker
        }
    }

    track(params) {
        this.waitAndTrack("yaCounter", params)
        this.waitAndTrack("fbq", params)
        this.waitAndTrack("gtag", params)
    }

    waitAndTrack(trackerName, params) {
        let seconds = 20
        const interval = setInterval(() => {
            if(this.trackers[trackerName]) {
                this[trackerName+"Track"](params)
                clearInterval(interval)
            }
            seconds--
            if(seconds === 0) { clearInterval(interval) }
        }, 1000)
    }

    gtagTrack(params) {
        const gtag = this.trackers.gtag
        gtag('event', params['event'])
    }

    yaCounterTrack(params) {
        const yaCounter = this.trackers.yaCounter
        yaCounter.reachGoal(params['event'])
    }

    fbqTrack(params) {
        const fbq = this.trackers.fbq
        const e = params['event']
        const property_params = {
            content_name: params['property_name'],
            content_type: 'product',
            product_catalog_id: '325430411584699',
            content_ids: params['property_id']
        }

        fbq('trackCustom', e)
        switch(e) {
            case 'PROPERTY_VIEW':
                fbq('track', 'ViewContent', property_params)
                break;
            case 'PROPERTY_CHECKOUT':
                fbq('track', 'AddToCart', property_params)
                break;
        }
    }
}

export default GaEventTracking
