import {
  CLOSE_OVERVIEW_MAP_MODAL,
  OPEN_OVERVIEW_MAP_MODAL
} from "../../constants/overviewMapConstants";

export function openModal() {
  return {
    type: OPEN_OVERVIEW_MAP_MODAL
  }
}

export function closeModal() {
  return {
    type: CLOSE_OVERVIEW_MAP_MODAL
  }
}