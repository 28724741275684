import properties from './properties'
import searchbox from './searchbox'
import overviewMap from './overview-map'
import environment from './environmentReducer'


const reducers = {
  properties,
  searchbox,
  overviewMap,
  environment
};

export default reducers
