import React, { Component } from 'react';
import I18n from 'v2/client/javascripts/i18n'
import flatten from 'lodash/flatten';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconSvg from './../icon-svg';


const renderOptions = (canOrderByPrice) => {
  let orderArr = [
    ['rank', 'asc'],
    ['stars', 'asc'],
    ['stars', 'desc']
  ]
  if(canOrderByPrice) {
    orderArr.push(
     ['price', 'asc'],
     ['price', 'desc']            
    )
  }
    
  return orderArr.map(([orderBy, orderDir]) => {
    const value = `${orderBy}_${orderDir}`;
    const text = I18n.t(`fe.client.properties_filters.order.directions.${value}`);
    return <option value={value} key={value}>{text}</option>
  })
};

class OrderSelector extends Component {
    static propTypes = {
        orderBy: PropTypes.string,
        orderDir: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        minPrice: PropTypes.number,
        maxPrice: PropTypes.number
    }

    state = {
        wrapWidth: '',
        labelTextWidth: ''
    }

    constructor(props) {
        super(props)
    }

    componentDidMount = () => {
        this.setWidths()
        //window.addEventListener('resize', this.setWidths);            
    }

    componentWillUnmount = () => {
        //window.RemoveEventListener('resize', this.setWidths);            
    }    

    componentDidUpdate(prevProps, prevState) {
        this.setWidths()
    }

    getMaxWrapWidth = () => {
        this.selectWrap.style.flexGrow = 1
        const maxWrapWidth = this.selectWrap.clientWidth
        this.selectWrap.style.flexGrow = 0
        return maxWrapWidth
    }

    setWidths = () => {
        const maxWrapWidth = this.getMaxWrapWidth()
        const fullLabelWidth = this.selectLabel.clientWidth

        const wrapWidth = (fullLabelWidth > maxWrapWidth) ? `${maxWrapWidth}px` : `${fullLabelWidth}px`
        const labelTextWidth = (fullLabelWidth <= maxWrapWidth) ? 'auto' : `${maxWrapWidth - 34}px`

        if(this.state.wrapWidth !== wrapWidth) {
            this.setState({ wrapWidth, labelTextWidth })
        }
    } 

    handleChange = (event) => {
        const [orderBy, orderDir] = event.target.value.split("_");
        this.props.onChange(orderBy, orderDir);
    }


    openSelect = () => {
        this.select.focus()
    }


    render() {
        const { orderBy, orderDir, className, minPrice, maxPrice } = this.props;
        const value = `${orderBy}_${orderDir}`;
        const currentLabel = I18n.t(`fe.client.properties_filters.order.directions.${value}`);
        const canOrderByPrice = maxPrice > 0

        const   labelClassName = "proplist-controls__order-selector-label",
                labelTextClassName = "proplist-controls__order-selector-label-text",
                labelTriggerClassName = "proplist-controls__order-selector-label-trigger",
                wrapClassName = "proplist-controls__order-selector-wrap",
                selectClassName = classNames("select select_blank", className);

        const { wrapWidth, labelTextWidth } = this.state
       
        return (
            <div className={wrapClassName} ref={(el) => this.selectWrap = el} style={{width: wrapWidth}}>
                <div ref={(el) => this.selectLabel = el} className={labelClassName}>
                    <span style={{width: labelTextWidth }} className={labelTextClassName}>{currentLabel}</span>
                    <span className={labelTriggerClassName}>{I18n.t('fe.client.properties_filters.order.trigger_title')}</span>
                    <span><IconSvg icon="chevron-down" /></span>
                </div>
                <select
                    style={{width: this.state.selectWidth}}
                    className={selectClassName} 
                    value={value} 
                    onChange={this.handleChange}
                    ref={(el) => this.select = el}
                >
                    {renderOptions(canOrderByPrice)}
                </select>
            </div>
        )
    }
}


export default OrderSelector
