import {
  APPEND_OVERVIEW_MAP_MARKERS_SUCCESS,
  LOAD_OVERVIEW_MAP_MARKERS_SUCCESS,
  RESET_OVERVIEW_MAP_MARKERS
} from '../../constants/overviewMapConstants';

const DEFAULT_STATE = [];

export default function(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case RESET_OVERVIEW_MAP_MARKERS:
      return DEFAULT_STATE;
    case LOAD_OVERVIEW_MAP_MARKERS_SUCCESS:
      return [...payload];
    case APPEND_OVERVIEW_MAP_MARKERS_SUCCESS:
      return [...state, ...payload];
    default:
      return state;
  }
}