import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ModalLayout from '../modal-layout';
import OverviewMapPanel from "./overview-map-panel";
import { closeModal } from "../../actions/overview-map/modalActions";
import {coordinatesShape, markerShape} from "../../constants/overviewMapConstants";


class OverviewMapModal extends Component {

  componentDidUpdate(prevProps) {
    const { opened } = this.props;
    if (opened !== prevProps.opened) {
      if (opened) {
        this.modal.openModal();
      } else {
        this.modal.closeModal();
      }
    }
  }

  handleClose = () => {
    this.props.closeModal();
  };

  render() {
    const { isSearch, initialCenter, initialZoom, initialActiveMarker } = this.props;

    return (
      <ModalLayout ref={(m) => this.modal = m}
                   withoutContainer={true}
                   closeIcon={true}
                   onClose={this.handleClose}>
        <OverviewMapPanel isSearch={isSearch}
                          initialCenter={initialCenter}
                          initialZoom={initialZoom}
                          initialActiveMarker={initialActiveMarker} />
      </ModalLayout>
    )
  }

}

OverviewMapModal.propTypes = {
  isSearch: PropTypes.bool,
  opened: PropTypes.bool,
  initialCenter: PropTypes.shape(coordinatesShape),
  initialZoom: PropTypes.number,
  initialActiveMarker: PropTypes.shape(markerShape),
  closeModal: PropTypes.func,
};

OverviewMapModal.defaultProps = {
  isSearch: false
};

function mapStateToProps({ overviewMap: { modal: { opened } } }) {
  return { opened }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    closeModal
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewMapModal)